
import React, {Component, Fragment, useEffect, useState} from 'react';
import {Button, Col, Container, Nav, Row} from "react-bootstrap";
import {Link, NavLink} from "react-router-dom";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
import cogoToast from "cogo-toast";
import {useDispatch,useSelector} from "react-redux";

export default function Login(){


    let audio=new Audio("/Button.mp3");
    const  playSound= ()=>{
        audio.play()
    }
    let smsSound=new Audio("/sms.mp3");
    const  PlaySms= ()=>{
        smsSound.play()
    }




    let dispatch=useDispatch();
    let state=useSelector((state)=> state);


    const[user,setUser]=useState('');
    const[redirect,setRedirect]=useState('');

    const [BtnChange,setBtnChange]=useState("Login")
    const [BtnColor,setBtnColor]=useState("success")

    const[Access,SetAccess]=useState("admin")


   const getLogin= async ()=>{
       playSound();
        let UserName= document.getElementById("UserName").value;
        let Password = document.getElementById("Password").value;


        setUser(UserName)



        let jsonObject=
            {
                "sql":"Select name,access,designation,posting,commissionerate,division,circle from Account Where User_Name='"+UserName+"' and Password='"+Password+"'"
            };



        if(UserName==""){cogoToast.error("Please inser User Name")}else{
            if(Password==""){cogoToast.error("Please inser Password")}else{
                setBtnColor("secondary")
                setBtnChange("Wait...")
                 RestClient.PostRequest(AppUrl.getData,jsonObject).then( result=>{
                    if(result==""){cogoToast.error("Login failed");
                        setBtnChange("Login");
                        setBtnColor("success");
                    }else{


                        if(result[0]["access"]=="admin" || result[0]["access"]=="Division" || result[0]["access"]=="Circle" || result[0]["access"]=="Section" || result[0]["access"]=="AP" || result[0]["access"]=="ADC" || result[0]["access"]=="Other" || result[0]["access"]=="GL" || result[0]["access"]=="Law" || result[0]["access"]=="ET" || result[0]["access"]=="EFD"   ){
                            cogoToast.success("Login Succed");
                              PlaySms();
                            dispatch({type:"name",payload:result[0]["name"]});
                            dispatch({type:"access",payload:result[0]["access"]});
                            dispatch({type:"posting",payload:result[0]["posting"]});
                            dispatch({type:"designation",payload:result[0]["designation"]});
                            dispatch({type:"accessCom",payload:result[0]["commissionerate"]});
                            dispatch({type:"accessDiv",payload:result[0]["division"]});
                            dispatch({type:"accessCir",payload:result[0]["circle"]});


                            setRedirect('/home')
                            setBtnChange("Login")
                            setBtnColor("success")

                            let getCommi=" ";
                            let getDivision=" ";
                            let getCircle=" ";
                            if(result[0]["commissionerate"]!="NBR"){ getCommi=" And Name='"+result[0]["commissionerate"]+"' ";};
                            if(result[0]["division"]!="All Division"){ getDivision=" And Name='"+result[0]["division"]+"' ";};
                            if(result[0]["circle"]!="All Circle"){ getCircle=" And Name='"+result[0]["circle"]+"' ";};

                            RestClient.PostRequest(AppUrl.getData,{"sql":"select Name from Account  Order By Name ASC"}).then(async result=>{await dispatch({type:"officers",payload:result}); });
                            RestClient.PostRequest(AppUrl.getData,{"sql":"select * from Commissionerate where 1=1 "+getCommi+"  Order By Name ASC"}).then(async result=>{await dispatch({type:"commi",payload:result}); });
                            RestClient.PostRequest(AppUrl.getData,{"sql":"select * from Division where 1=1 "+getDivision+" Order By Name ASC"}).then(async result=>{await dispatch({type:"division",payload:result}); });
                            RestClient.PostRequest(AppUrl.getData,{"sql":"select * from Circle where 1=1 "+getCircle+"  Order By Name ASC"}).then(async result=>{await dispatch({type:"circle",payload:result}); });
                            RestClient.PostRequest(AppUrl.getData,{"sql":"select Name from Category   Order By Name ASC"}).then(async result=>{await dispatch({type:"category",payload:result}); });

                          //  RestClient.PostRequest(AppUrl.getData,{"sql":"select Name from BIN Order By Name ASC"}).then(async result=>{await dispatch({type:"bin",payload:result}); });
                            if(result[0]["access"]=="admin" || result[0]["access"]=="Law"){
                            RestClient.PostRequest(AppUrl.getData,{"sql":"select Name,Circle,Court,Mamla_No,Mamla_Type,Amount,Status from  Mamla where Status !='Solved' "}).then(async result=>{await dispatch({type:"all_mamla",payload:result}); });
                            RestClient.PostRequest(AppUrl.getData,{"sql":"select Name,Circle,Mamla_No,Amount,Hearing_Date,Status from  Mamla_Internal"}).then(async result=>{await dispatch({type:"internal_mamla",payload:result}); });
                            RestClient.PostRequest(AppUrl.getData,{"sql":"SELECT Mamla_No,Name, DATEDIFF(Hearing_Date, CURDATE()) AS Left_days, Hearing_Date from Mamla_Internal WHERE DATEDIFF(Hearing_Date, CURDATE()) <>0"}).then(async result=>{await dispatch({type:"mamla_date",payload:result}); });
                            }
                           // RestClient.PostRequest(AppUrl.getData,{"sql":"SELECT Name,Opening,Payable,Rebate,Deposit,Closing,Entry_Date FROM CBalance ORDER BY Id DESC LIMIT 2"}).then(async result=>{await dispatch({type:"c_balance",payload:result}); });




                        }else{
                            cogoToast.error("Login Failed")
                            setBtnChange("Login")
                            setBtnColor("success")
                        }
                    }




                }
                    ).catch(error=>{
                    cogoToast.error(error);
                    setBtnChange("Login")
                    setBtnColor("success")
                });
            }
        }





    }
    const checkLogin=()=>{



        if(user==""){
            cogoToast.error("Please Login First")
            playSound();
        }else{
            playSound();
        }
    }
    return(
        <Fragment>


            <div className="purchaseDiv text-center">
                <Nav.Link className="navItem "><NavLink to="/"><Button variant="warning" className="float-end">Close</Button></NavLink></Nav.Link>

                <h3>Login Form</h3>
                <hr/>
                <Container >


                    <Row>
                        <Col lg={4}>

                        </Col>

                        <Col lg={4}>
                            <Row>
                                <label className="customfontsize">User name</label>
                                <input id="UserName" type="text" className="w-100 customfontsize ml-1"/>
                            </Row>
                            <Row>
                                <label className="customfontsize">Password</label>
                                <input id="Password" type="password" className="w-100  customfontsize"/>
                            </Row>


                            <Row >
                                <Col lg={6} md={6} sm={12}>

                                    <Button onClick={getLogin} variant={BtnColor} className="w-100 mt-4">{BtnChange}</Button>

                                </Col>
                                <Col  lg={6} md={6} sm={12}>
                                    <NavLink to={redirect} state={{ Access: "Manager" }}>
                                        <Button onClick={checkLogin}  variant="danger" className="w-100 mt-4" >Go to Home</Button>
                                    </NavLink>



                                </Col>
                            </Row>

                        </Col>

                    </Row>
                </Container>
            </div>



        </Fragment>
    )
}
