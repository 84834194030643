import {Fragment} from "react";
import TopNavigation from "../components/TopNavigation/TopNavigation";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import BudgetEntry from "../components/GL/BudgetEntry";
import DebtReport from "../components/Debt/DebtReport";
import DebtEntry from "../components/Debt/DebtEntry";

export default function GlPage(){



    return(
        <Fragment>
            <TopNavigation/>
            <div className="purchaseDiv text-center mergin_top">

                <Tabs
                    defaultActiveKey="debt"
                    transition={false}
                    id="noanim-tab-example"
                    className="mb-3"
                >
                    <Tab eventKey="debt" title="DebtReport"><DebtReport/></Tab>
                    <Tab eventKey="entry" title="Debt Entry"><DebtEntry/></Tab>


                </Tabs>
            </div>
        </Fragment>
    )
}
