import {Fragment} from "react";
import TopNavigation from "../components/TopNavigation/TopNavigation";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import CaseEntry from "../components/Law/CaseEntry";
import DashBoard from "../components/Law/DashBoard";
import CaseDetailesEntry from "../components/Law/CaseDetailesEntry";
import CaseReport from "../components/Law/CaseReport";
import InternalCaseEntry from "../components/Law/InternalCaseEntry";
import CaseReportInternal from "../components/Law/CaseReportInternal";
import CaseEntryInternal from "../components/Law/CaseEntryInternal";
import CasePendingTask from "../components/Law/CasePendingTask";


export default function LawPage(){



    return(
        <Fragment>
            <TopNavigation/>
            <div className="purchaseDiv text-center mergin_top">

                <Tabs
                    defaultActiveKey="home"
                    transition={false}
                    id="noanim-tab-example"
                    className="mb-3 "
                >
                    <Tab eventKey="home" title="Dashboard" className="text-danger"><DashBoard/></Tab>
                    <Tab eventKey="CaseEntry" title="Mamla Entry"><CaseEntry/></Tab>
                    <Tab eventKey="Detail" title="Mamla Update"><CaseDetailesEntry/></Tab>
                    <Tab eventKey="Report" title="Report"><CaseReport/></Tab>
                    <Tab eventKey="ICE" title="Internal Mamla Entry"><CaseEntryInternal/></Tab>
                    <Tab eventKey="InternalReport" title="Internal Report"><CaseReportInternal/></Tab>
                    <Tab eventKey="InternalReportCase" title="Internal Case"><InternalCaseEntry/></Tab>
                    <Tab eventKey="PendingTask" title="Pending Task"><CasePendingTask/></Tab>


                </Tabs>
            </div>
        </Fragment>
    )
}
