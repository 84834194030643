import {Fragment, useEffect, useState} from "react";
import {Button, Card, Col, Container, Modal, Row} from "react-bootstrap";
import cogoToast from "cogo-toast";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
import {useSelector} from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import DatePicker from "react-datepicker";
import AppUtility from "../../RestAPI/AppUtility";

export default function CoEfficientEntry(){
    let state=useSelector((state)=> state);
    const[bin,setBin]=useState();
    const[binName,setBinName]=useState();
    const[CoDivision,setCoDivision]=useState();
    const[CoCircle,setCoCircle]=useState();
    const[Unit,setUnit]=useState([]);
    const[CoEconomicActivity,setCoEconomicActivity]=useState();
    const[CoCategory,setCoCategory]=useState();


const[Data,setData]=useState([]);
const [Column, setColumn]=useState([{}]);
const[Show,setShow]=useState();
const[ShowProduct,setShowProduct]=useState();
const[BtnLoad,setBtnLoad]=useState("primary");
const[BtnSave,setBtnSave]=useState("primary");
    const [DataCoEffeciant, setDataCoEffeciant]=useState([]);
    const [ColumnCoEfficient, setColumnCoEfficient]=useState([{}]);
    const[ProductID,setProductID]=useState();

    const[Category,setCategory]=useState();
    const[Hscode,setHscode]=useState();
    const[Description,setDescripton]=useState();
    const [DateSubmit, setDateSubmit] = useState(new Date());


   const handleClose=()=>{

       setShow(false)
       setBinName()
       setBin()
    }
    const handleOpen=()=>{
        setShow(true)
    }

    const handleCloseProduct=()=>{
        setShowProduct(false)
        setBinName()
        setBin()
    }
    const handleOpenProduct=()=>{
        setShowProduct(true)
    }

    useEffect(()=>{
        getUnit();
    },[])
    const getUnit=()=>{

        let jsonObject={"sql":"SELECT Id,Name  from measurment_unit  "}



        RestClient.PostRequest(AppUrl.getData, jsonObject).then(async result => {

            setUnit(result)

        }).catch(error => {

        });
    }
    const selectRow={
        mode:'radio',
        onSelect:(row,isSelect,rowIndex)=>{
            setProductID(row["Id"])
            setCoCategory(row["Category"])
            setDescripton(row["Description"])
            setHscode(row["Hscode"])
            handleOpen()
        }
    }


    const saveCoEfficient=()=>{
       setBtnSave("secondary")


             const SaleCategory=document.getElementById("cmbSaleCategory");
            const SaleCategoryValue=SaleCategory.options[SaleCategory.selectedIndex].text;

            const Description=document.getElementById("txtDescription").value;
            const SupplyQty=document.getElementById("txtSupplyQty").value;
            const SupplyUnit=document.getElementById("cmbSUnit");
            const SupplyUnitValue=SupplyUnit.options[SupplyUnit.selectedIndex].text;
            const RmValue=document.getElementById("txtRmValue").value;
            const AdditionValue=document.getElementById("txtAddValue").value;
            const Comments=document.getElementById("txtComments").value;

            const AddParcentage=((AdditionValue*100)/RmValue).toFixed(2);
            const TotalPrice=(parseFloat(RmValue)+parseFloat(AdditionValue)).toFixed(2);

            if(binName==""){cogoToast.error("Please select BIN Name")}else{
                if(Category==""){cogoToast.error("Please insert Category")}else{
                    if(Hscode==""){cogoToast.error("Please insert Hscode")}else{
                        if(Description==""){cogoToast.error("Please insert Description")}else{
                            if(SupplyUnit.selectedIndex==0){cogoToast.error("Please insert Supply Unit")}else{
                                if(RmValue==""){cogoToast.error("Please insert RM Value")}else{
                                    if(AdditionValue==""){cogoToast.error("Please insert Addition value")}else{

                                        if(SaleCategory.selectedIndex==0){cogoToast.error("Please select Sale Category")}else{
                                            if(Comments==""){cogoToast.error("Please insert Comments")}else{

                                                let jsonObjectPrevious={"sql":"INSERT INTO `Co_Efficient` (`ID`,`BIN`,`Name`,`Division`,`Circle`,`Economic_Activity`,`Category`,  `Hscode`, `Description`,`Supply_Qty`, `Supply_Unit`, `Rm_Price`, `Addition_Price`, `Total_Price`, `Addition_Parcentage`,`Status`,`Sale_Category`, `Comments`, `Entry_Date`) " +
                                                        "VALUES (NULL, '"+bin+"', '"+binName+"', '"+CoDivision+"', '"+CoCircle+"', '"+CoEconomicActivity+"', '"+CoCategory+"',  '"+Hscode+"', '"+Description+"',"+SupplyQty+", '"+SupplyUnitValue+"', '"+RmValue+"', '"+AdditionValue+"', '"+TotalPrice+"',"+AddParcentage+",'Active','"+SaleCategoryValue+"', '"+Comments+"','"+AppUtility.getDate(DateSubmit)+"')"}
                                                console.log(jsonObjectPrevious)
                                                RestClient.PostRequest(AppUrl.saveData,jsonObjectPrevious).then(async result=>{
                                                    if(result==""){cogoToast.error("Data not found");setBtnSave("primary")}else {
                                                        cogoToast.success("Data saved");setBtnSave("primary")
                                                        setBinName()
                                                        setBin()
                                                    }
                                                }).catch(error=>{setBtnSave("primary")});
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }


    }
    const saveCoProduct=()=>{


       const CoCategory=document.getElementById("cmbCeCategory");
       const CategoryValue=CoCategory.options[CoCategory.selectedIndex].text;
       const CoHscode=document.getElementById("txtCeHscode").value;
       const CoDescription=document.getElementById("txtCeDescription").value;

        let jsonObject={"sql":"INSERT INTO `Co_Efficient_Product` (`Id`, `Hscode`, `Description`, `Category`,`Bin_Name`,`Bin`)" +
                " VALUES (NULL, '"+CoHscode+"', '"+CoDescription+"', '"+CategoryValue+"','"+binName+"','"+bin+"')"}

            if(bin==null){cogoToast.error("Please select BIN")}else{
            if(CoCategory.selectedIndex==0){cogoToast.error("Please select Category")}else{
                if(CoHscode==""){cogoToast.error("Please insert Hscode")}else{
                    if(CoDescription==""){cogoToast.error("Please insert Description")}else{
                        RestClient.PostRequest(AppUrl.saveData,jsonObject).then(async result=>{
                            if(result==""){cogoToast.error("Data not found");}else {
                                cogoToast.success("Data saved");
                                setBinName()
                                setBin()
                            }
                        }).catch(error=>{});
                    }
                }}
            }






    }
    const getBinName=()=>{
       const txtgetBin=document.getElementById("txtgetBin").value;

        let jsonObject={"sql":"SELECT Name, BIN,Division,Circle,Economic_Activity FROM `BIN` where BIN Like '%"+txtgetBin+"%'"}

    if(txtgetBin==""){
        cogoToast.error("Please insert BIN")
    }else{
        RestClient.PostRequest(AppUrl.getData, jsonObject).then(async result => {
            cogoToast.success()
            setBinName(result[0]["Name"])
            setBin(result[0]["BIN"])
            setCoDivision(result[0]["Division"])
            setCoCircle(result[0]["Circle"])
            setCoEconomicActivity(result[0]["Economic_Activity"])


        }).catch(error => {
        });
    }

    }
    const getData=()=>{

       const HscodeSearch=document.getElementById("txtHscodeSearch").value;
       const CeSearchCategory=document.getElementById("cmbCeSearchCategory");
       const CeSearchCategoryValue=CeSearchCategory.options[CeSearchCategory.selectedIndex].text;
       const CeSearchBINValue=document.getElementById("txtBinSearch").value;


        let CoEffCategory="";
        let CoEffBIN="";
        let CoHscode="";
        if(CeSearchCategory.selectedIndex !=0){CoEffCategory=" And Category='"+CeSearchCategoryValue+"'"}

        if(HscodeSearch !=""){CoHscode=" And Hscode Like'%"+HscodeSearch+"%' "}
        if(CeSearchBINValue !=""){CoEffBIN=" And BIN Like'%"+CeSearchBINValue+"%' "}

       let jsonObject={"sql":"SELECT * FROM `Co_Efficient_Product` where 1=1 "+CoEffCategory+" "+CoEffBIN+" "+CoHscode+" LIMIT 100 "}

        setColumn([{}]);setData([])
        setBtnLoad("secondary")
        RestClient.PostRequest(AppUrl.getData, jsonObject).then(async result => {

                await setColumn(
                    Object.keys(result[0]).map((k) => {
                        return {dataField: k, text: k, sort: true}
                    })
                )
                await setData(result);
                setBtnLoad("primary")




        }).catch(error => {   setBtnLoad("primary")
        });
    }
    const get4_3=()=>{


            let jsonObject={"sql":"SELECT ID,Description As Description_Of_Goods,Supply_Qty AS Qty,Supply_Unit As Unit,Rm_Price,Addition_Price,Addition_Parcentage AS 'Add_%',Total_Price,Entry_Date As 'Effect_Date',DATEDIFF(CURDATE(),Entry_Date ) AS Total_Days,Status,Sale_Category,Comments As Comments_for_goods " +
                    "FROM `Co_Efficient` Where Id=(SELECT max(Id) FROM Co_Efficient) "}
            setColumnCoEfficient([{}]);setDataCoEffeciant([])
            RestClient.PostRequest(AppUrl.getData,jsonObject).then(async result=>{
                if(result==""){cogoToast.error("Data not found")}else {
                    await setColumnCoEfficient(
                        Object.keys(result[0]).map((k) => {
                            return {dataField: k, text: k, sort: true}
                        })
                    )
                    await setDataCoEffeciant(result);

                }
            }).catch(error=>{});

    }
    return (
        <Fragment>
            <Container className="animated zoomIn  ">


                <Row className="mt-2">
                    <Col sm={12} xs={12} md={12} lg={12}>
                        <Card>
                            <Card.Body>
                                <Container fluid={true}>
                                    <Row>
                                        <Col><button onClick={handleOpenProduct} className="float-start circular-btn">Product Entry <i className="fa fa-plus"/></button></Col>
                                        <Col><label>Hscode</label><input id="txtHscodeSearch" className="w-50" type="text"/></Col>
                                        <Col> <select  className="form-control form-control-sm form-select" id="cmbCeSearchCategory">
                                            <option value="0">All Sector</option>
                                            {
                                                state.category.map((ctr,index)=>{
                                                    return (
                                                        <option key={index} value={ctr.Id}>{ctr.Name}</option>
                                                    )
                                                })

                                            }
                                        </select>
                                        </Col>
                                        <Col>
                                            <input id="txtBinSearch" className="w-75" type="text" placeholder="Please enter BIN"/>
                                        </Col>
                                        <Col><Button variant={BtnLoad} onClick={getData}>Load Product</Button></Col>
                                           </Row>
                                </Container>
                                <hr className="bg-secondary"/>
                                <Container fluid={true}>
                                    <Row>
                                        <Col sm={12} xs={12} md={12} lg={12}>
                                            <div className="purchaseMediumDiv">
                                            <BootstrapTable  keyField='Id' data={Data} columns={Column}  selectRow={selectRow} > </BootstrapTable>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>


            </Container>

            <Modal animation={false} className="animated zoomIn" show={Show} onHide={handleClose}>
                <Modal.Header>
                    <div>

                        <input className="" type="text" id="txtgetBin" placeholder="Please insert BIN No"/>

                        <button className=" w-50" onClick={getBinName} >
                            Search BIN
                        </button>

                        <label className="form-label">
                            {binName}     -
                        </label>
                        <label className="form-label">
                            {bin}
                        </label>


                    </div>

                </Modal.Header>
                <Modal.Body>


                    <label className="form-label text-danger">Description</label>
                    <input className="form-control form-control-sm" disabled={true} Value={Description} type="text" id="txtDescription"/>
                    <hr/>
                    <label className="form-label text-danger">Supply Qty</label>
                    <input className="w-25" type="text" id="txtSupplyQty"/>
                    <select  className="w-25 " id="cmbSUnit">
                        <option>Select</option>
                        {
                            Unit.map((ctr,index)=>{
                                return (

                                    <option key={index} value={ctr.Id}>{ctr.Name}</option>
                                )
                            })
                        }
                    </select>
                    <hr/>
                    <label className="form-label text-danger">RM Value</label>
                    <input className="w-25" type="text" id="txtRmValue"/>
                    <label className="form-label text-danger">Addition Value</label>
                    <input className="w-25" type="text" id="txtAddValue"/>
                    <hr/>
                       <label className="form-label text-danger">Sale Category</label>
                    <select  className="form-control form-control-sm form-select" id="cmbSaleCategory">
                        <option >Select Category</option>
                        <option >Normal Sale</option>
                        <option >Special Sale</option>
                    </select>
                    <label className="form-label text-danger">Submit Date</label>
                    <DatePicker selected={DateSubmit} onChange={(date) => setDateSubmit(date)} dateFormat='dd-MM-yyyy' seletable maxDate={new Date()} isClearable showYearDropdown scrollableMonthYearDropdown  />
                    <label className="form-label">Comments</label>
                    <textarea className="form-control form-control-sm" id="txtComments" ></textarea>

                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn btn-sm btn-site" variant="danger" onClick={handleClose}>
                        Close
                    </Button>
                    <button className="" variant={BtnSave} onClick={saveCoEfficient}>
                        Save
                    </button>
                </Modal.Footer>
            </Modal>


            <Modal animation={false} className="animated zoomIn" show={ShowProduct} onHide={handleCloseProduct}>
                <Modal.Header>
                    <div>

                        <input className="form-control w-100 form-control-sm" type="text" id="txtgetBin" placeholder="Please insert BIN No"/>

                        <button className=" w-100" onClick={getBinName} >
                            Search BIN
                        </button>

                          <label className="form-label">
                                    {binName}     -
                          </label>
                        <label className="form-label">
                            {bin}
                        </label>


                    </div>

                </Modal.Header>
                <Modal.Body>
                    <label className="form-label ">Product Category</label>
                    <select  className="form-control form-control-sm form-select" id="cmbCeCategory">
                        <option value="0">All Sector</option>
                        {
                            state.category.map((ctr,index)=>{
                                return (
                                    <option key={index} value={ctr.Id}>{ctr.Name}</option>
                                )
                            })

                        }
                    </select>
                    <label className="form-label">Product Hscode</label>
                    <input className="form-control form-control-sm" type="text" id="txtCeHscode"/>
                    <label className="form-label ">Product Description</label>
                    <input className="form-control form-control-sm" type="text" id="txtCeDescription"/>


                </Modal.Body>
                <Modal.Footer>
                    <Button className="" variant="danger" onClick={handleCloseProduct}>
                        Close
                    </Button>
                    <Button  className="" variant="primary" onClick={saveCoProduct}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>

        </Fragment>
    );
}
