import {Fragment} from "react";

import Login from "../components/Login/Login";
import Efd from "../components/EFD/Efd";
import TopNavigation from "../components/TopNavigation/TopNavigation";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Report from "../components/Report/Report";
import CoEfficientEntry from "../components/UnitProfile/CoEfficientEntry";
import CoEfficientReport from "../components/UnitProfile/CoEfficientReport";
import BricksReport from "../components/Bricks/BricksReport";
import VdsReport from "../components/VDS/VdsReport";
import FloorSpaceReport from "../components/FloorSpace/FloorSpaceReport";
import Refund from "../components/Refund/Refund";
import AuditReport from "../components/Audit/AuditReport";
import Juwellery from "../components/Juwellery/Juwellery";
import UnRegisteredBin from "../components/UnRegisteredBin/UnRegisteredBin";
import EfdInfogenex from "../components/EFD/EfdInfogenex";

export default function EfdPage(){


    return(
        <Fragment>
            <TopNavigation/>

            <div className="purchaseDiv text-center mergin_top">
                <Tabs
                    defaultActiveKey="home"
                    transition={false}
                    id="noanim-tab-"
                    className="mb-3"
                >

                    <Tab eventKey="efd" title="EFD"><Efd/></Tab>
                    <Tab eventKey="info" title="Infogenex"><EfdInfogenex/></Tab>


                </Tabs>


            </div>

        </Fragment>
    );
}
