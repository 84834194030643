import {Fragment, useState} from "react";
import {Button, Col, Modal, Row} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
import cogoToast from "cogo-toast";
import LoadingText from "../Loading/LoadingText";
import {useSelector} from "react-redux";
import DatePicker from "react-datepicker";
import appUtility from "../../RestAPI/AppUtility";


export default function BricksReport(){
    let state=useSelector((state)=> state);
    const[BricksDivision,setBricksDivision]=useState([]);
    const[BricksCircle,setBricksCircle]=useState([]);
    const [DataCoEffeciant, setDataCoEffeciant]=useState([]);
    const [ColumnCoEfficient, setColumnCoEfficient]=useState([{}]);
    const [TotalAmount, setTotalAmount]=useState(0)
    const [DataLength, setDatalength]=useState(0)
    const [BrickBin, setBrickBin]=useState()
    const [BrickBinName, setBrickBinName]=useState()
    const [BrickMobile, setBrickMobile]=useState()
    const[DataStatus, setDataStatus]=useState(false)
    const[PaymentData, setPaymentData]=useState([])
    const [DepositDate, setDepositDate] = useState(new Date());
    const [showBrick, setShowBrick] = useState(false);
    const handleCloseBrick = () => setShowBrick(false);
    const handleShowBrick = () => setShowBrick(true);

    const [showPaymentHistory, setShowPaymentHistory] = useState(false);
    const handleClosePaymentHistory = () => setShowPaymentHistory(false);
    const handleShowPaymentHistory = () => setShowPaymentHistory(true);
    const [showSMS, setShowSMS] = useState(false);
    const handleCloseSMS = () => setShowSMS(false);
    const handleShowSMS = () => setShowSMS(true);
    const selectRow={
        mode:'radio',
        onSelect:(row,isSelect,rowIndex)=>{
            setBrickBin(row['BIN']);
            setBrickBinName(row['Name']);
            setTotalAmount(row['Amount'])
            setBrickMobile(row['Mobile'])
            handleShowBrick()
        }
    }
    const getBIN=()=>{
        setDataStatus(true)

        const BrickCommi=document.getElementById("cmbBrickBinCommi");
        const BrickCommiValue=BrickCommi.options[BrickCommi.selectedIndex].text;

        const BricksDivision=document.getElementById("cmbBricksDivision");
        const BricksDivisionValue=BricksDivision.options[BricksDivision.selectedIndex].text;


        const BricksCircle=document.getElementById("cmbBricksCircle");
        const BricksCircleValue=BricksCircle.options[BricksCircle.selectedIndex].text;
        const PaymentStatus=document.getElementById("cmbPaymentStatus");
       let getBricksCommi="";
       let getBricksDivision="";
       let getBricksCircle="";

        if(BrickCommi.selectedIndex!=0){getBricksCommi=" And bin.Commissionerate Like '%"+BrickCommiValue+"%' "}
        if(BricksDivision.selectedIndex!=0){getBricksDivision=" And bin.Division Like '%"+BricksDivisionValue+"%' "}
       if(BricksCircle.selectedIndex!=0){getBricksCircle=" And bin.Circle Like '%"+BricksCircleValue+"%' "}

       let getSql={"sql":"select bin.BIN,bin.Name,bin.Mobile, bin.Division,bin.Circle, brick_revenue_view.Amount from bin left join brick_revenue_view on  brick_revenue_view.BIN = bin.BIN where Category='Bricks' "+getBricksCommi+" "+getBricksDivision+" "+getBricksCircle+" "}

       if(PaymentStatus.selectedIndex==1){getSql={"sql":"select bin.BIN,bin.Name,bin.Mobile, bin.Division,bin.Circle, brick_revenue_view.Amount from bin inner join brick_revenue_view  on brick_revenue_view.BIN=bin.BIN where bin.Category='Bricks' "+getBricksCommi+" "+getBricksDivision+" "+getBricksCircle+""}}
       if(PaymentStatus.selectedIndex==2){getSql={"sql":"select bin.BIN,bin.Name,bin.Mobile, bin.Division,bin.Circle   from bin   where not bin.BIN  in (select BIN from brick_revenue_view ) And Category='Bricks' "+getBricksCommi+" "+getBricksDivision+"  "+getBricksCircle+" "}}


       setColumnCoEfficient([{}]);setDataCoEffeciant([])
        RestClient.PostRequest(AppUrl.getData,getSql).then(async result=>{
           setDatalength(result.length)
            if(result==""){cogoToast.error("Data not found"); setDataStatus(false)}else {
                await setColumnCoEfficient(
                    Object.keys(result[0]).map((k) => {
                        return {dataField: k, text: k, sort: true}
                    })
                )
                await setDataCoEffeciant(result);
                setDataStatus(false)
            }
        }).catch(error=>{setDataStatus(false)});
    }
    const getPaymentHistory=()=>{

        let getSql={"sql":"select Amount, Deposit_Date from brick_revenue where BIN='"+BrickBin+"' "}


        RestClient.PostRequest(AppUrl.getData,getSql).then(async result=>{

            if(result==""){cogoToast.error("No Payment Data found");}else {
                setPaymentData(result)
                        handleShowPaymentHistory();
                        handleCloseBrick();
            }
        }).catch(error=>{});
    }
    const saveBrickRevenue=()=>{
        const BrickRevenue=document.getElementById("txtBrickRevenueAmount").value;

        if(BrickRevenue==""){cogoToast.error("Please insert amount")}else{


        RestClient.PostRequest(AppUrl.saveData, {"sql": "Insert into brick_revenue(Id,BIN,Amount,Deposit_Date)values(NULL,'"+BrickBin+"','"+BrickRevenue+"','"+appUtility.getDate(DepositDate)+"') "}).then(result => {
            if (result == 1) {
                cogoToast.success("Data saved")


            } else {
                cogoToast.error("Data not saved")
            }
        }).catch(error => {
            cogoToast.error(error)
        });
    }}
    return(
        <Fragment>
            <div >
                <Row className="mt-2">

                    <Col lg={3}>
                        <div className="">
                            <Row className="mt-2">
                                <Col lg={3} xs={5}><label className="text-danger fw-bold">NBR</label></Col>
                                <Col lg={9}  xs={7}>
                                    <select className="float-start w-100 customfontsize" id="cmbBrickBinCommi"
                                            onChange={()=>{
                                                setBricksDivision([]);
                                                setBricksCircle([]);
                                                const e = document.getElementById("cmbBrickBinCommi");
                                                const value = e.options[e.selectedIndex].value;
                                                const dt=state.division.filter(x => x.Commissionerate_Id==value);
                                                setBricksDivision(dt)


                                            }}
                                    >




                                        <option >NBR</option>



                                        {


                                            state.commi.map((ctr,index)=>{
                                                return (
                                                    <option key={index} value={ctr.Id}>{ctr.Name}</option>
                                                )
                                            })

                                        }
                                    </select>
                                </Col>
                            </Row>
                             <Row className="mt-2">
                            <Col lg={3} xs={5}><label className="text-danger fw-bold">Division</label></Col>
                            <Col lg={9} xs={7}>
                                <select className="float-start w-100 customfontsize" id="cmbBricksDivision"
                                        onChange={()=>{
                                            setBricksCircle([]);
                                            const e = document.getElementById("cmbBricksDivision");
                                            const value = e.options[e.selectedIndex].value;
                                            const dt=state.circle.filter(x => x.Division_Id==value);
                                            setBricksCircle(dt)

                                        }}
                                >
                                    <option >All Division</option>
                                    {


                                        BricksDivision.map((ctr,index)=>{
                                            return (
                                                <option key={index} value={ctr.Id}>{ctr.Name}</option>
                                            )
                                        })

                                    }
                                </select>
                            </Col>

                             </Row>
                            <Row className="mt-2">
                                <Col lg={3} xs={5}><label className="text-danger fw-bold">Circle</label></Col>
                                <Col lg={9} xs={7}>
                                    <select className="float-start w-100 customfontsize" id="cmbBricksCircle"  >
                                        <option >All Circle</option>
                                        {


                                            BricksCircle.map((ctr,index)=>{
                                                return (
                                                    <option key={index} value={ctr.Id}>{ctr.Name}</option>
                                                )
                                            })

                                        }
                                    </select>
                                </Col>

                            </Row>
                            <Row className="mt-2">
                                <Col lg={3} xs={5}><label className="text-danger fw-bold">Payment</label></Col>
                                <Col lg={9} xs={7}>
                                    <select className="float-start w-100 customfontsize" id="cmbPaymentStatus"  >
                                        <option >All BIN</option>
                                        <option >Paid Only</option>
                                        <option >No Paid</option>

                                    </select>
                                </Col>

                            </Row>
                            <Row className="mt-2">
                                <Col lg={3} xs={5}><label className="text-danger fw-bold">Amount</label></Col>
                                <Col lg={9} xs={7}>
                                    <select className="float-start w-100 customfontsize" id="cmbPaymentAmountStatus"  >
                                        <option >No Amount</option>
                                        <option >Greater Than</option>
                                        <option >Less Than</option>

                                    </select>
                                </Col>

                            </Row>
                            <Row className="mt-2">
                                <Col lg={3} xs={5}><label className="text-danger fw-bold">Value</label></Col>
                                <Col lg={9} xs={7}>
                                    <input type="text" className="w-100"/>
                                </Col>

                            </Row>

                            <Row className="mt-2">
                                <Col lg={3} >

                                </Col>
                                <Col lg={9}>
                                    <button className="w-100" onClick={getBIN}>Search</button>
                                </Col>


                            </Row>


                        </div>
                    </Col>

                    <Col lg={9}>
                        <div className="overflow-scroll animated zoomIn purchaseSmallDiv">
                            {
                                DataStatus?<LoadingText/>
                                    : <BootstrapTable selectRow={selectRow}   keyField='BIN' data={DataCoEffeciant} columns={ColumnCoEfficient}></BootstrapTable>

                            }
                             </div>
                        <label className="text-danger customfontsize">Data found: {DataLength}</label>
                    </Col>

                </Row>

            </div>
            <Modal show={showBrick} onHide={handleCloseBrick}>
                <Modal.Header closeButton>
                    <Modal.Title >
                        <label className="text-danger">Brick Revenue Deposit Form </label>
                    </Modal.Title>


                </Modal.Header>
                <Modal.Body>
                    <label className="text-danger">Name :></label><label>{BrickBinName}</label>
                    <hr/>
                    <label className="text-danger"> BIN :> </label><label>{BrickBin}</label>
                    <hr/>

                        <label>Amount:</label>
                        <input type="text" className="w-50" id="txtBrickRevenueAmount"/>
                        <label>Tk</label>
                    <hr/>
                    <label className="text-danger">Application Date:</label>
                    <DatePicker selected={DepositDate} onChange={(date) => setDepositDate(date)} dateFormat='dd-MM-yyyy' seletable  isClearable showYearDropdown scrollableMonthYearDropdown  />
                    <hr/>
                    <Button variant="primary" onClick={getPaymentHistory} >
                        Payment History?
                    </Button>
                    <hr/>
                    <Button className="float-right" variant="primary" onClick={()=>{
                        handleShowSMS();handleCloseBrick();
                    }
                    } >
                    Send Sms
                </Button>


                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={saveBrickRevenue}  >
                        Save
                    </Button>
                    <Button variant="danger" onClick={handleCloseBrick}>
                        Close
                    </Button>


                </Modal.Footer>
            </Modal>

            <Modal show={showPaymentHistory} onHide={handleClosePaymentHistory}>
                <Modal.Header closeButton>
                    <Modal.Title >
                        <label className="text-danger">Payment History </label>
                    </Modal.Title>

                </Modal.Header>
                <Modal.Body>
                    <label className="text-danger">Name :></label>
                    <label>{BrickBinName}</label>
                    <hr/>
                    {


                        PaymentData.map((ctr,index)=>{
                            return (
                               <div>
                                  <label>Amount :-</label> <label className="text-danger">{ctr.Amount} </label><label>TK -</label>
                                   <label>Date :</label><label className="text-danger">{ctr.Deposit_Date}</label>
                               </div>

                            )
                        })

                    }
                    <hr/>
                    <label>Total Amount =</label>
                    <label className="text-danger">{TotalAmount}</label>
                    <label>TK</label>

                </Modal.Body>
                <Modal.Footer>

                    <Button variant="danger" onClick={handleClosePaymentHistory}>
                        Close
                    </Button>


                </Modal.Footer>
            </Modal>

            <Modal show={showSMS} onHide={handleCloseSMS}>
                <Modal.Header closeButton>
                    <Modal.Title> <label className="text-primary">New SMS send</label> </Modal.Title>

                </Modal.Header>
                <Modal.Body>
                    <label>{BrickBinName}</label>
                    <hr/>
                    <label className="text-danger">Mobile No :</label>
                    <input type="text" defaultValue={BrickMobile} id="txtSmsNumber"/>
                    <hr/>

                    <textarea id="txtSms" placeholder="Please write your sms here. Maximum 120 letters" className="w-100 h-50"></textarea>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="float-end" variant="success" onClick={()=>{
                        const sms=document.getElementById("txtSms").value;
                        const smsNumber=document.getElementById("txtSmsNumber").value;
                        if(smsNumber==""){cogoToast.error("Please insert send Number")}else{
                            if(sms==""){cogoToast.error("Please write sms in the box")}else{
                                appUtility.sendSMS(sms,smsNumber);
                            }
                        }

                    }
                    } >
                        Send sms
                    </Button>

                    <Button variant="danger" onClick={handleCloseSMS}>
                        Close
                    </Button>



                </Modal.Footer>
            </Modal>
        </Fragment>

    )
}
