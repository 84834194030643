import {Fragment, useState} from "react";
import {Button, Col, Modal, Row} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
import cogoToast from "cogo-toast";
import LoadingText from "../Loading/LoadingText";
import {useSelector} from "react-redux";


export default function VdsReport(){
    let state=useSelector((state)=> state);
    const [VdsModelCircle, setVdsModelCircle]=useState([]);
    const [VdsCircle, setVdsCircle]=useState([]);
    const [DataCoEffeciant, setDataCoEffeciant]=useState([]);
    const [ColumnCoEfficient, setColumnCoEfficient]=useState([{}]);
    const [DataLength, setDatalength]=useState(0)
    const [show, setShow] = useState(false);
    const [Vdsshow, setVdsShow] = useState(false);
    const [Vds_Id, setVds_Id] = useState();
    const [Vds_Name, setVds_Name] = useState();
    const[DataStatus, setDataStatus]=useState(false)
    const handleCloseVds = () => setVdsShow(false);
    const handleShowVds = () => setVdsShow(true);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const selectRow={
        mode:'radio',
        onSelect:(row,isSelect,rowIndex)=>{
            setVds_Id(row['Id']);
            setVds_Name(row['Name']);

            handleShowVds();



        }
    }
    const getVdsBIN=()=>{
        setDataStatus(true)
        const BinCategory=document.getElementById("cmbVdsBinCategory");
        const CategoryValue=BinCategory.options[BinCategory.selectedIndex].text;
        const Division=document.getElementById("cmbVdsBinDivision");
        const DivisionValue=Division.options[Division.selectedIndex].text;
        const Circle=document.getElementById("cmbVdsBinCircle");
        const CircleValue=Circle.options[Circle.selectedIndex].text;

        let getDivision=" ";
        let getCircle=" ";
        let getCategory=" ";
        if(Division.selectedIndex!=0){getDivision=" And Division='"+DivisionValue+"' "}
        if(Circle.selectedIndex!=0){getCircle=" And Circle= '"+CircleValue+"' "}
        if(BinCategory.selectedIndex!=0){getCategory=" And Category= '"+CategoryValue+"' "}


        const Column1=document.getElementById("cmbRevenueColumn1");
        const Column1Value=Column1.options[Column1.selectedIndex].text;
        const Column2=document.getElementById("cmbRevenueColumn2");
        const Column2Value=Column2.options[Column2.selectedIndex].text;
        const Column3=document.getElementById("cmbRevenueColumn3");
        const Column3Value=Column3.options[Column3.selectedIndex].text;
        const Column4=document.getElementById("cmbRevenueColumn4");
        const Column4Value=Column4.options[Column4.selectedIndex].text;

        let getColumn1=" ";
        let getColumn2=" ";
        let getColumn3=" ";
        let getColumn4=" ";
        if(Column1.selectedIndex!=0){getColumn1=" , vds_24_25."+Column1Value+" "}
        if(Column2.selectedIndex!=0){getColumn2=" , vds_24_25."+Column2Value+" "}
        if(Column3.selectedIndex!=0){getColumn3=" , vds_24_25."+Column3Value+" "}
        if(Column4.selectedIndex!=0){getColumn4=" , vds_24_25."+Column4Value+" "}






        let jsonObject={"sql":" Select Vds_Bin.Id,Vds_Bin.Name,Vds_Bin.Bin,Vds_Bin.Mobile,Vds_Bin.Division,Vds_Bin.Circle "+getColumn1+" "+getColumn2+" "+getColumn3+" "+getColumn4+" from Vds_Bin, vds_24_25 where Vds_Bin.Id=vds_24_25.Vds_Id  "+getDivision+" "+getCircle+"  "+getCategory+"  "}


        setColumnCoEfficient([{}]);setDataCoEffeciant([])
        RestClient.PostRequest(AppUrl.getData,jsonObject).then(async result=>{
           setDatalength(result.length)
            if(result==""){cogoToast.error("Data not found");setDataStatus(false)}else {
                await setColumnCoEfficient(
                    Object.keys(result[0]).map((k) => {
                        return {dataField: k, text: k, sort: true}
                    })
                )
                await setDataCoEffeciant(result);
                setDataStatus(false)
            }
        }).catch(error=>{});
    }
const UpdateVdsRevenue=()=>{
    const vdsYear=document.getElementById("cmbVdsYear");
    const vdsYearValue=vdsYear.options[vdsYear.selectedIndex].text;
    const vdsMonth=document.getElementById("cmbVdsMonth");
    const vdsMonthValue=vdsMonth.options[vdsMonth.selectedIndex].text;
    const vdsRevenue=document.getElementById("txtVdsRevenue").value;

    if(vdsYear.selectedIndex==0){cogoToast.error("Please select FY")}else{
    if(vdsMonth.selectedIndex==0){cogoToast.error("Please select Month")}else{
        if(vdsRevenue==""){cogoToast.error("Please insert amount")}else{
            RestClient.PostRequest(AppUrl.saveData, {"sql": "Update vds_24_25 set "+vdsMonthValue+"="+vdsRevenue+" where Vds_Id="+Vds_Id+" "}).then(result => {
                if (result == 1) {
                    cogoToast.success("Revenue Data saved")

                } else {
                    cogoToast.error("Data not saved")
                }
            }).catch(error => {
                cogoToast.error(error)
            });

        }}
    }

}
    const saveVdsRevenue=()=>{


        RestClient.PostRequest(AppUrl.saveData, {"sql": "Insert into vds_24_25 (Id, Vds_Id)Values(NULL, (select count(Id) from vds_bin)) "}).then(result => {
            if (result == 1) {
                cogoToast.success("Revenue Data saved")

            } else {
                cogoToast.error("Data not saved")
            }
        }).catch(error => {
            cogoToast.error(error)
        });

    }
    const saveVdsData=()=>{

        const vdsName=document.getElementById("txtVdsName").value;
        const vdsBin=document.getElementById("txtVdsBin").value;
        const vdsMobile=document.getElementById("txtVdsMobile").value;
        const vdsAddress=document.getElementById("txtVdsAddress").value;
        const vdsDivision=document.getElementById("cmbVdsDivision");
        const vdsDivisionValue=vdsDivision.options[vdsDivision.selectedIndex].text;
        const vdsCircle=document.getElementById("cmbVdsCircle");
        const vdsCircleValue=vdsCircle.options[vdsCircle.selectedIndex].text;
        const vdsCategory=document.getElementById("cmbVdsCategory");
        const vdsCategoryValue=vdsCategory.options[vdsCategory.selectedIndex].text;


        if(vdsName==""){cogoToast.error("Please insert name")}else {
            if(vdsBin==""){cogoToast.error("Please insert bin")}else {
                if(vdsMobile==""){cogoToast.error("Please insert mobile")}else {
                    if(vdsAddress==""){cogoToast.error("Please insert address")}else {
                        if(vdsDivision.selectedIndex==0){cogoToast.error("Please select Division")}else {
                            if(vdsCircle.selectedIndex==0){cogoToast.error("Please select Circle")}else {
                                if(vdsCategory.selectedIndex==0){cogoToast.error("Please select Category")}else {

                                    RestClient.PostRequest(AppUrl.saveData, {"sql": "Insert into Vds_Bin (Id,Name,Bin,Mobile,Address,Division,Circle,Category)Values(NULL,'"+vdsName+"','"+vdsBin+"','"+vdsMobile+"','"+vdsAddress+"','"+vdsDivisionValue+"','"+vdsCircleValue+"','"+vdsCategoryValue+"') "}).then(result => {
                                        if (result == 1) {
                                            cogoToast.success("Vds Name saved")
                                            saveVdsRevenue();
                                        } else {
                                            cogoToast.error("Data not saved")
                                        }
                                    }).catch(error => {
                                        cogoToast.error(error)
                                    });

                                }}}}}}}
    }

    return(
        <Fragment>
            <div >
                <Row className="mt-2">

                    <Col lg={3}>
                        <div className="">
                             <Row className="mt-2">
                            <Col lg={3}><label className="text-danger fw-bold">Division</label></Col>
                            <Col lg={9}>
                                <select className="float-start w-100 customfontsize" id="cmbVdsBinDivision"
                                        onChange={()=>{
                                            setVdsCircle([])
                                            const e = document.getElementById("cmbVdsBinDivision");
                                            const value = e.options[e.selectedIndex].value;
                                            const dt=state.circle.filter(x => x.Division_Id==value);
                                            setVdsCircle(dt)


                                        }}
                                >
                                    <option >All Division</option>
                                    {


                                        state.division.map((ctr,index)=>{
                                            return (
                                                <option key={index} value={ctr.Id}>{ctr.Name}</option>
                                            )
                                        })

                                    }
                                </select>
                            </Col>

                             </Row>
                            <Row className="mt-2">
                                <Col lg={3}><label className="text-danger fw-bold">Circle</label></Col>
                                <Col lg={9}>
                                    <select className="float-start w-100 customfontsize" id="cmbVdsBinCircle"  >
                                        <option >All Circle</option>
                                        {


                                            VdsCircle.map((ctr,index)=>{
                                                return (
                                                    <option key={index} value={ctr.Id}>{ctr.Name}</option>
                                                )
                                            })

                                        }
                                    </select>
                                </Col>

                            </Row>
                            <Row className="mt-2">
                                <Col lg={3}><label className="text-danger fw-bold">Category</label></Col>
                                <Col lg={9}>
                                    <select className="float-start w-100 customfontsize" id="cmbVdsBinCategory"  >
                                        <option >All Category</option>
                                        <option >NGO</option>
                                        <option >Govt</option>
                                    </select>
                                </Col>

                            </Row>

                            <Row className="mt-2">
                                <Col lg={3}>

                                </Col>
                                <Col lg={9}>
                                    <button className="w-100 text-primary font-weight-bold" onClick={getVdsBIN}>Search</button>
                                </Col>


                            </Row>

                               <div className="mt-lg-4">
                                   <button className="text-danger w-100" onClick={handleShow}>VDS Data Entry</button>
                               </div>





                        </div>
                    </Col>

                    <Col lg={9}>
                        <div className="overflow-scroll animated zoomIn purchaseSmallDiv">
                            <Row className="mt-2">

                                <Col lg={3}>
                                    <label className="text-danger float-start  fw-bold">Column-1</label>
                                    <select className=" w-auto " id="cmbRevenueColumn1"  >
                                        <option >No Month</option>
                                        <option >July</option>
                                        <option >August</option>
                                        <option >September</option>
                                        <option >October</option>
                                        <option >November</option>
                                        <option >December</option>
                                        <option >January</option>
                                        <option >February</option>
                                        <option >March</option>
                                        <option >April</option>
                                        <option >May</option>
                                        <option >June</option>

                                    </select>
                                </Col>

                                <Col lg={3}>
                                    <label className="text-danger float-start fw-bold">Column-2</label>
                                    <select className=" w-auto " id="cmbRevenueColumn2"  >
                                        <option >No Month</option>
                                        <option >July</option>
                                        <option >August</option>
                                        <option >September</option>
                                        <option >October</option>
                                        <option >November</option>
                                        <option >December</option>
                                        <option >January</option>
                                        <option >February</option>
                                        <option >March</option>
                                        <option >April</option>
                                        <option >May</option>
                                        <option >June</option>

                                    </select>
                                </Col>

                                <Col lg={3}>
                                    <label className="text-danger float-start fw-bold">Column-3</label>
                                    <select className=" w-auto " id="cmbRevenueColumn3"  >
                                        <option >No Month</option>
                                        <option >July</option>
                                        <option >August</option>
                                        <option >September</option>
                                        <option >October</option>
                                        <option >November</option>
                                        <option >December</option>
                                        <option >January</option>
                                        <option >February</option>
                                        <option >March</option>
                                        <option >April</option>
                                        <option >May</option>
                                        <option >June</option>

                                    </select>
                                </Col>

                                <Col lg={3}>
                                    <label className="text-danger float-start fw-bold">Column-4</label>
                                    <select className=" w-auto " id="cmbRevenueColumn4"  >
                                        <option >No Month</option>
                                        <option >July</option>
                                        <option >August</option>
                                        <option >September</option>
                                        <option >October</option>
                                        <option >November</option>
                                        <option >December</option>
                                        <option >January</option>
                                        <option >February</option>
                                        <option >March</option>
                                        <option >April</option>
                                        <option >May</option>
                                        <option >June</option>

                                    </select>
                                </Col>


                            </Row>
                            <hr/>
                            {
                                DataStatus?<LoadingText/>
                                    : <BootstrapTable   selectRow={selectRow}  keyField='Id' data={DataCoEffeciant} columns={ColumnCoEfficient}></BootstrapTable>

                            }
                              </div>
                        <label className="text-danger customfontsize">Data found: {DataLength}</label>
                    </Col>

                </Row>

            </div>
            <Modal show={Vdsshow} onHide={handleCloseVds}>
                <Modal.Header closeButton>
                    <Modal.Title >
                        <label className="text-danger">Revenue Enty Form </label>

                        <hr/>
                        <label>{Vds_Name}</label> <label className="text-danger">- ID: {Vds_Id}</label>
                    </Modal.Title>


                </Modal.Header>
                <Modal.Body>
                    <div className="flex-column">
                        <label>Year :</label>
                        <select className="float-right me-2 w-50 customfontsize" id="cmbVdsYear"  >
                            <option >Select FY</option>
                            <option >24-25</option>
                            <option >25-26</option>
                            <option >26-27</option>
                            <option >27-28</option>
                        </select>
                        <hr/>
                        <label>Month :</label>
                        <select className="float-right me-2 w-50 customfontsize" id="cmbVdsMonth"  >
                            <option >Select Month</option>
                            <option >July</option>
                            <option >August</option>
                            <option >September</option>
                            <option >October</option>
                            <option >November</option>
                            <option >December</option>
                            <option >January</option>
                            <option >February</option>
                            <option >March</option>
                            <option >April</option>
                            <option >May</option>
                            <option >June</option>
                        </select>
                        <hr/>
                        <label>Amount:</label>
                        <input type="text" className="w-50" id="txtVdsRevenue"/>
                        <label>Crore</label>

                    </div>



                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={UpdateVdsRevenue}  >
                        Save
                    </Button>
                    <Button variant="danger" onClick={handleCloseVds}>
                        Close
                    </Button>


                </Modal.Footer>
            </Modal>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title >
                        <label className="text-danger">VDS Entry Form</label>
                    </Modal.Title>


                </Modal.Header>
                <Modal.Body>
                    <div className="flex-column">
                        <label >Name:</label><input className="w-100" type="text" id="txtVdsName"/>
                        <hr/>
                        <label >BIN:</label><input className="w-50" type="text" id="txtVdsBin"/>

                        <label className="m-1 p-1">Mobile:</label><input className="w-25" type="text" id="txtVdsMobile"/>

                        <label className="">Address:</label><input className="w-100" type="text" id="txtVdsAddress"/>
                        <hr/>
                        <label>Division:</label>

                        <select className="float-right me-2 w-75 customfontsize" id="cmbVdsDivision"
                                onChange={()=>{
                                    setVdsModelCircle([])
                                    const e = document.getElementById("cmbVdsDivision");
                                    const value = e.options[e.selectedIndex].value;
                                    const dt=state.circle.filter(x => x.Division_Id==value);
                                    setVdsModelCircle(dt)


                                }}
                        >
                            <option >Select Division</option>
                            {
                                state.division.map((ctr,index)=>{
                                    return (
                                        <option key={index} value={ctr.Id}>{ctr.Name}</option>
                                    )
                                })

                            }
                        </select>
                        <hr/>
                        <label>Circle:</label>

                        <select className="float-right me-2 w-75 customfontsize" id="cmbVdsCircle"  >
                            <option >Select Circle</option>
                            {
                                VdsModelCircle.map((ctr,index)=>{
                                    return (
                                        <option key={index} value={ctr.Id}>{ctr.Name}</option>
                                    )
                                })

                            }
                        </select>
                        <hr/>
                        <label>Category :</label>

                        <select className="float-right me-2 w-75 customfontsize" id="cmbVdsCategory"  >
                            <option >Select</option>
                            <option >NGO</option>
                            <option >Govt</option>
                            <option >Autonomous Body</option>
                        </select>



                    </div>



                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={saveVdsData}>
                        Save
                    </Button>
                    <Button variant="danger" onClick={handleClose}>
                        Close
                    </Button>


                </Modal.Footer>
            </Modal>
        </Fragment>

    )
}
