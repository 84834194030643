import {Fragment, useState} from "react";
import {Button, Col, Row} from "react-bootstrap";
import {useSelector} from "react-redux";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
import cogoToast from "cogo-toast";
import LoadingText from "../Loading/LoadingText";


export default function AssignOfficer(){
    let state=useSelector((state)=> state);

    const[DataStatus, setDataStatus]=useState(false)

    const updateDivisionOfficer=()=>{

        const Division=document.getElementById("cmbDivisionAssign");
        const DivisionName=Division.options[Division.selectedIndex].text;
        const DO=document.getElementById("txtDO").value;
        const DoMobile=document.getElementById("txtDoMobile").value;

        if(Division.selectedIndex==0){cogoToast.error("Please select Division")}else{
            if(DO==""){cogoToast.error("Please Insert Divisional Officer Name")}else{
                if(DoMobile==""){cogoToast.error("Please Insert Mobile Number")}else{
                    setDataStatus(true);
                    RestClient.PostRequest(AppUrl.updateData, {"sql": "Update bin set Divisional_Officer='"+DO+"', DO_Mobile='"+DoMobile+"' where Division like'%"+DivisionName+"%' "}).then(result => {
                        if (result == 1) {
                            setDataStatus(false);
                            cogoToast.success("Data Updated")

                        } else {
                            cogoToast.error("Data not Updated")
                            setDataStatus(false);
                        }
                    }).catch(error => {
                        cogoToast.error(error)
                        setDataStatus(false);
                    });
                }
            }
        }


    }
    const updateCircleOfficer=()=>{

        const Circle=document.getElementById("cmbCircleAssign");
        const CircleName=Circle.options[Circle.selectedIndex].text;
        const CO=document.getElementById("txtCO").value;
        const CoMobile=document.getElementById("txtCoMobile").value;

        if(Circle.selectedIndex==0){cogoToast.error("Please select Circle")}else{
            if(CO==""){cogoToast.error("Please Insert Circle Officer Name")}else{
                if(CoMobile==""){cogoToast.error("Please Insert Mobile Number")}else{
                    setDataStatus(true);
                    RestClient.PostRequest(AppUrl.updateData, {"sql": "Update bin set Circle_Officer='"+CO+"', CO_Mobile='"+CoMobile+"' where Circle ='"+CircleName+"' "}).then(result => {
                        if (result == 1) {
                            setDataStatus(false);
                            cogoToast.success("Data Updated")

                        } else {
                            cogoToast.error("Data not Updated")
                            setDataStatus(false);
                        }
                    }).catch(error => {
                        cogoToast.error(error)
                        setDataStatus(false);
                    });
                }
            }
        }


    }
    const updateRangeOfficer=()=>{

        const CircleARO=document.getElementById("cmbCircleAro");
        const CircleNameARO=CircleARO.options[CircleARO.selectedIndex].text;

        const RangeAro=document.getElementById("cmbRangeAro");
        const RangeAroValue=RangeAro.options[RangeAro.selectedIndex].text;

        const RO=document.getElementById("txtRO").value;
        const RoMobile=document.getElementById("txtRoMobile").value;

        if(CircleARO.selectedIndex==0){cogoToast.error("Please select Circle")}else{
        if(RangeAro.selectedIndex==0){cogoToast.error("Please select Range")}else{
            if(RO==""){cogoToast.error("Please Insert Range Officer Name")}else{
                if(RoMobile==""){cogoToast.error("Please Insert Mobile Number")}else{
                    setDataStatus(true);
                    RestClient.PostRequest(AppUrl.updateData, {"sql": "Update bin set ARO='"+RO+"', Aro_Mobile='"+RoMobile+"' where Circle ='"+CircleNameARO+"' And Range_Name='"+RangeAroValue+"' "}).then(result => {
                        if (result == 1) {
                            setDataStatus(false);
                            cogoToast.success("Data Updated")

                        } else {
                            cogoToast.error("Data not Updated")
                            setDataStatus(false);
                        }
                    }).catch(error => {
                        cogoToast.error(error)
                        setDataStatus(false);
                    });
                }
            }
        }}


    }
    return(
        <Fragment>
            <div >
                <Row className="mt-2">

                    <Col lg={12}>
                        <div className="">

                            <Row className="mt-2">
                                <Col lg={1} xs={4}><label className="text-danger fw-bold">Division</label></Col>
                                <Col lg={2} xs={8}>
                                <select className="float-start w-100 customfontsize" id="cmbDivisionAssign"

                                >
                                    <option >All Division</option>

                                    {
                                        state.division.map((ctr,index)=>{
                                            return (
                                                <option key={index} value={ctr.Id}>{ctr.Name}</option>
                                            )
                                        })

                                    }
                                </select>
                                </Col>
                                <Col lg={2}>Divisional Officer</Col>
                                <Col lg={2}><input type="text" id="txtDO"/></Col>
                                <Col lg={1}>Mobile</Col>
                                <Col lg={2}><input type="text" id="txtDoMobile"/></Col>
                                <Col lg={1}><Button onClick={updateDivisionOfficer}>Save</Button></Col>

                            </Row>
                            <Row><hr className="m-4"/></Row>
                            <Row className="mt-2">
                                <Col lg={1} xs={4}><label className="text-danger fw-bold">Circle</label></Col>
                                <Col lg={2} xs={8}>
                                <select className="float-start w-100 customfontsize" id="cmbCircleAssign" >
                                    <option >All Circle</option>

                                    {
                                        state.circle.map((ctr,index)=>{
                                            return (
                                                <option key={index} value={ctr.Id}>{ctr.Name}</option>
                                            )
                                        })

                                    }


                                </select>
                                </Col>
                                <Col lg={2}>Circle Officer</Col>
                                <Col lg={2}><input type="text" id="txtCO"/></Col>
                                <Col lg={1}>Mobile</Col>
                                <Col lg={2}><input type="text" id="txtCoMobile"/></Col>
                                <Col lg={1}><Button onClick={updateCircleOfficer}>Save</Button></Col>
                            </Row>
                            <hr className="m-4"/>
                            <Row className="mt-2">
                                <Col lg={1} xs={4}><label className="text-danger fw-bold">Circle</label></Col>
                                <Col lg={2} xs={8}>
                                    <select className="float-start w-100 customfontsize" id="cmbCircleAro" >
                                        <option >All Circle</option>

                                        {
                                            state.circle.map((ctr,index)=>{
                                                return (
                                                    <option key={index} value={ctr.Id}>{ctr.Name}</option>
                                                )
                                            })

                                        }


                                    </select>
                                </Col>
                                <Col lg={2} xs={8}>
                                    <select className="float-start w-100 customfontsize" id="cmbRangeAro" >
                                        <option >All-Range</option>
                                        <option >Range-1</option>
                                        <option >Range-2</option>
                                        <option >Range-3</option>
                                        <option >Range-4</option>
                                        <option >Range-5</option>
                                        <option >Range-6</option>
                                        <option >Range-7</option>
                                    </select>
                                </Col>
                                <Col lg={1}>Range Officer</Col>
                                <Col lg={2}><input type="text" id="txtRO"/></Col>
                                <Col lg={1}>Mobile</Col>
                                <Col lg={2}><input type="text" id="txtRoMobile"/></Col>
                                <Col lg={1}><Button onClick={updateRangeOfficer}>Save</Button></Col>
                            </Row>
                            <Row><hr/>
                                {
                                    DataStatus? <LoadingText/>
                                        :<label>Data updated successfully</label>
                                }
                            </Row>
                            <Row><label className="text-danger">Please be careful about kotwali circle, There are two circle same name</label></Row>

                        </div>
                    </Col>



                </Row>

            </div>

        </Fragment>

    )
}
