import {Fragment, useRef, useState} from "react";
import {Button, Col, Container, Modal, Row} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import {useSelector} from "react-redux";
import cogoToast from "cogo-toast";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
import DatePicker from "react-datepicker";
import AppUtility from "../../RestAPI/AppUtility";
import LoadingText from "../Loading/LoadingText";

export default function CaseEntry(){
    let state=useSelector((state)=> state);
    const ref=useRef();

    const [StatusDate, setStatusDate] = useState(new Date());
    const[DataStatus, setDataStatus]=useState(false)

    const [DataMamla, setDataMamla]=useState([]);
    const [Data, setData]=useState([]);
    const [ColumnMamla, setColumnMamla]=useState([{}]);
    const [Column, setColumn]=useState([{}]);
    const [Name, setName]=useState();
    const [BIN, setBIN]=useState();
    const [Commissionerate, setCommissionerate]=useState();
    const [DivisionMamla, setDivisionMamla]=useState([]);
    const [Division, setDivision]=useState([]);
    const [Circle, setCircle]=useState([]);
    const [CircleMamla, setCircleMamla]=useState([]);
    const [Items, setItems]=useState("");
    const [MamlaNo, setMamlaNo]=useState();

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const selectRow={
        mode:'radio',
        onSelect:(row,isSelect,rowIndex)=>{
            setName(row['Name']);
            setBIN(row['BIN']);
            setDivisionMamla(row['Division']);
            setCircleMamla(row['Circle']);
            setCommissionerate(row['Commissionerate']);

            setItems(row['Items']);
            handleShow()


        }
    }

    const [showStatus, setShowStatus] = useState(false);
    const handleCloseStatus = () => setShowStatus(false);
    const handleShowStatus = () => setShowStatus(true);
    const selectRowStatus={
        mode:'radio',
        onSelect:(row,isSelect,rowIndex)=>{
                setName(row['Name']);
                setMamlaNo(row['Mamla_No']);

                handleShowStatus()


        }
    }
const updateStatus = () => {


        const txtAreaComments=document.getElementById("txtAreaComments").value;
        const MamlaStatus=document.getElementById("cmbMamlaStatus");
        const MamlaStatusValue=MamlaStatus.options[MamlaStatus.selectedIndex].text;

        let jsonObject={"sql":"Update Mamla set Status='"+MamlaStatusValue+"', Status_Date='"+AppUtility.getDate(StatusDate)+"',Comments='"+txtAreaComments+"' where Mamla_No='"+MamlaNo+"' "}

        if(MamlaStatus.selectedIndex==0){cogoToast.error("Please insert Mamla Status")}else{
        if(txtAreaComments==""){cogoToast.error("Please insert Comments")}else{
            if(StatusDate==null){cogoToast.error("Please insert Date")}else{
                RestClient.PostRequest(AppUrl.updateData,jsonObject).then(result=>{
                    if(result==1){cogoToast.success("Data saved")}else{cogoToast.error("Data not saved")}
                }).catch(error=>{ cogoToast.error(error)});
            }}
        }



}
    const saveData=()=>{
        const CourtIndex=document.getElementById("cmbCourt");
        const CourtName=CourtIndex.options[CourtIndex.selectedIndex].text;
        const MamlaNo=document.getElementById("textMamlaEntryNo").value;
        const MamlaYear=document.getElementById("txtYearMamla").value;
        const MamlaAmount=document.getElementById("txtMamlaAmount").value;
        const MamlaType=document.getElementById("cmbMamlaType");
        const MamlaTypeValue=MamlaType.options[MamlaType.selectedIndex].text;


        const jsonObject={"sql":"INSERT INTO `Mamla` (`Id`, `Name`, `BIN`, `Commissionerate`,`Division`,`Circle`,`Court`, `Mamla_No`, `Mamla_Year`, `Mamla_Type`,`Amount`, `Status`, `Last_Hearing`, `Status_Date`, `Comments`,`Mamla_Title`,`Analogous`)" +
                " VALUES (NULL, '"+Name+"','"+BIN+"', '"+Commissionerate+"','"+DivisionMamla+"', '"+CircleMamla+"', '"+CourtName+"', '"+MamlaNo+"', '"+MamlaYear+"', '"+MamlaTypeValue+"', '"+MamlaAmount+"','Active', NULL,NULL,'','','')"}

        if(MamlaNo==""){cogoToast.error("Please Insert mamla No")}else{
        if(CourtIndex.selectedIndex==0){cogoToast.error("Please select Court")}else{
        if(MamlaType.selectedIndex==0){cogoToast.error("Please select mamla type")}else{
            if(MamlaYear==" "){cogoToast.error("Please Insert mamla Year")}else{
                if(MamlaAmount==""){cogoToast.error("Please Insert mamla Amount")}else{
                    RestClient.PostRequest(AppUrl.saveData,jsonObject).then(result=>{
                        if(result==1){cogoToast.success("Data saved")}else{cogoToast.error("Data not saved")}
                    }).catch(error=>{ cogoToast.error(error)});
                }}}
            }
        }


        }

    const getData=()=>{

        setDataStatus(true);
        const BinCommi=document.getElementById("cmbBinCommi");
        const BinCommiValue=BinCommi.options[BinCommi.selectedIndex].text;

        const BinDivision=document.getElementById("cmbBinDivision");
        const BinDivisionValue=BinDivision.options[BinDivision.selectedIndex].text;

        const MamlaCircle=document.getElementById("cmbBinCircle");
        const Circle=MamlaCircle.options[MamlaCircle.selectedIndex].text;

        const MamlaBinName=document.getElementById("txtMamlaBinName").value;
        const MamlaBin=document.getElementById("txtMamlaBin").value;

        let getCommi=""
        let getDivision=""
        let getCircle=""
        let getBin=""
        let getName=""

        if(BinCommi.selectedIndex !=0){getCommi=" and Commissionerate='"+BinCommiValue+"' "}
        if(BinDivision.selectedIndex !=0){getDivision=" and Division Like'%"+BinDivisionValue+"%' "}
        if(MamlaCircle.selectedIndex !=0){getCircle=" and Circle Like '%"+Circle+"%' "}
        if(MamlaBinName!=""){getName=" and Name Like'%"+MamlaBinName+"%' "}
        if(MamlaBin!=""){getBin=" and BIN Like'%"+MamlaBin+"%' "}

        const jsonObject={"sql":"select Name,BIN,Commissionerate,Division,Circle,Category from BIN where 1=1 "+getCommi+" "+getDivision+" "+getCircle+"  "+getName+" "+getBin+" Order by Name ASC limit 100"}
        setData([])
        RestClient.PostRequest(AppUrl.getData,jsonObject).then(result=>{

            setColumn(
                Object.keys(result[0]).map((k) => {
                    return {dataField: k, text: k, sort: true}
                })
            )
            setData(result);
            setDataStatus(false);

        }).catch(error=>{setDataStatus(false); });


    }
    const getMamla=()=>{

        const BinNameIndex=document.getElementById("cmbBinName");
        const BinName=BinNameIndex.options[BinNameIndex.selectedIndex].text;
        const MamlaCircle=document.getElementById("cmbMamlaCircle");
        const Circle=MamlaCircle.options[MamlaCircle.selectedIndex].text;

        let getCircle=""
        let getBin=""

        if(MamlaCircle.selectedIndex !=0){getCircle=" and Circle='"+Circle+"' "}
        if(BinNameIndex.selectedIndex !=0){getBin=" and Name='"+BinName+"' "}

        const jsonObject={"sql":"select Id AS 'SL', Name,Mamla_No,Mamla_Year,Mamla_Type As Type,Court  from Mamla where 1=1 "+getCircle+" "+getBin+" Order by Name ASC"}
        setDataMamla([])
        RestClient.PostRequest(AppUrl.getData,jsonObject).then(result=>{

            setColumnMamla(
                Object.keys(result[0]).map((k) => {
                    return {dataField: k, text: k, sort: true}
                })
            )
            setDataMamla(result)

        }).catch(error=>{ });


    }

    return(

        <Fragment>
            <Container className="animated zoomIn" >
                <Row>
                    <Col lg={2}>
                        <Row className="mt-2">
                            <Col lg={3} xs={4}><label className="text-danger fw-bold">NBR</label></Col>
                            <Col lg={9}  xs={8}>
                                <select className="float-start w-100 customfontsize" id="cmbBinCommi"
                                        onChange={()=>{
                                            setDivision([]);
                                            setCircle([]);
                                            const e = document.getElementById("cmbBinCommi");
                                            const value = e.options[e.selectedIndex].value;
                                            const dt=state.division.filter(x => x.Commissionerate_Id==value);
                                            setDivision(dt)


                                        }}
                                >




                                    <option >NBR</option>



                                    {


                                        state.commi.map((ctr,index)=>{
                                            return (
                                                <option key={index} value={ctr.Id}>{ctr.Name}</option>
                                            )
                                        })

                                    }
                                </select>
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col lg={3} xs={4}><label className="text-danger fw-bold">Division</label></Col>
                            <Col lg={9} xs={8}>
                                <select className="float-start w-100 customfontsize" id="cmbBinDivision"
                                        onChange={()=>{
                                            setCircle([])
                                            const e = document.getElementById("cmbBinDivision");
                                            const value = e.options[e.selectedIndex].value;
                                            const dt=state.circle.filter(x => x.Division_Id==value);
                                            setCircle(dt)


                                        }}
                                >
                                    <option >All Division</option>

                                    {
                                        Division.map((ctr,index)=>{
                                            return (
                                                <option key={index} value={ctr.Id}>{ctr.Name}</option>
                                            )
                                        })

                                    }
                                </select>
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col lg={3} xs={4}><label className="text-danger fw-bold">Circle</label></Col>
                            <Col lg={9} xs={8}>
                                <select className="float-start w-100 customfontsize" id="cmbBinCircle" >
                                    <option >All Circle</option>

                                    {
                                        Circle.map((ctr,index)=>{
                                            return (
                                                <option key={index} value={ctr.Id}>{ctr.Name}</option>
                                            )
                                        })

                                    }


                                </select>
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col lg={3}><label className="text-danger fw-bold">Name</label></Col>
                            <Col lg={9}>
                             <input type="text" id="txtMamlaBinName"/>
                            </Col>

                        </Row>
                        <Row className="mt-2">
                            <Col lg={3}><label className="text-danger fw-bold">BIN</label></Col>
                            <Col lg={9}>
                                <input type="text" id="txtMamlaBin"/>
                            </Col>

                        </Row>
                        <Row className="mt-2"> <Button onClick={getData}>Search</Button></Row>
                        <Row className="mt-2"> <Button onClick={getMamla}>GET Mamla</Button></Row>
                    </Col>
                    <Col lg={10}>
                        <Row>
                            <Col lg={6}>
                                <label>Case entry</label>
                                <div className="tableDiv tableDivBorder">
                                    {
                                        DataStatus? <LoadingText/>:
                                            <BootstrapTable ref={ref} id="tblSearch" keyField='BIN' data={Data} columns={Column} selectRow={selectRow} > </BootstrapTable>
                                    }
                                </div>
                            </Col>
                            <Col lg={6}>
                                <label>Update Status</label>
                                <div className="tableDiv tableDivBorder">
                                             <BootstrapTable  keyField='Mamla_No' data={DataMamla} columns={ColumnMamla} selectRow={selectRowStatus}  > </BootstrapTable>

                                     </div>
                            </Col>
                        </Row>

                    </Col>
                </Row>
            </Container>
            <Modal  show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{Name}</Modal.Title>


                </Modal.Header>
                <Modal.Body>
                    <label className="w-25">Mamla No</label><input id="textMamlaEntryNo" type="text" className="w-75"/>

                    <select className="float-start w-50 mt-2  " id="cmbCourt"  >
                        <option>Select Court Name</option>
                        <option>Appeal</option>
                        <option>Tribunal</option>
                        <option>High Court</option>
                        <option>Supreme Court</option>
                        <option>Other</option>
                    </select>

                    <select className="float-start mt-2 w-50  " id="cmbMamlaType"  >
                        <option>Select Mamla Type</option>
                        <option>Vat appeal</option>
                        <option>Writ</option>
                        <option>Civil Rules</option>
                        <option>CP_CMP</option>
                        <option>Vat Revision</option>
                        <option>Other</option>
                    </select>

                    <h3 className="mt-3 text-danger">Mamla Year</h3>
                   <input id="txtYearMamla" className="w-50"/>
                    <h3 className="mt-3 text-danger">Total Amount</h3>
                    <input  type="text" id="txtMamlaAmount" className=" w-50"/>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="success" onClick={saveData} >
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal  show={showStatus} onHide={handleCloseStatus}>
                <Modal.Header closeButton>
                    <Modal.Title>{Name}</Modal.Title>


                </Modal.Header>
                <Modal.Body>
                    <label className="w-25">Mamla No</label>
                    <input id="textMamlaNo" value={MamlaNo} type="text" disabled={true} className="w-75"/>
                    <label className="w-25 mt-2">Status</label>
                    <select className="w-75 mt-2" id="cmbMamlaStatus"  >
                        <option>Select</option>
                        <option>ADR</option>
                        <option>Stay</option>
                        <option>Judgement</option>
                        <option>Solved</option>
                        <option>Cause List</option>
                    </select>
                    <label className=" w-25">Comments</label>
                    <textarea className="mt-2 w-75" id="txtAreaComments"></textarea>
                    <h3 className="w-50">Date</h3>
                    <DatePicker selected={StatusDate}
                                onChange={(date) => setStatusDate(date)}
                                dateFormat='dd-MM-yyyy'
                                seletable
                                isClearable
                                showYearDropdown
                                scrollableMonthYearDropdown
                    />


                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleCloseStatus}>
                        Close
                    </Button>
                    <Button variant="success" onClick={updateStatus} >
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
}
