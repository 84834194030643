import {Fragment, useState} from "react";
import TopNavigation from "../components/TopNavigation/TopNavigation";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import {Button, Card, Col, Container, ListGroup, Row} from "react-bootstrap";
import {useSelector} from "react-redux";
import cogoToast from "cogo-toast";
import RestClient from "../RestAPI/RestClient";
import AppUrl from "../RestAPI/AppUrl";
import Report from "../components/Report/Report";
import CoEfficientReport from "../components/UnitProfile/CoEfficientReport";
import BricksReport from "../components/Bricks/BricksReport";
import Refund from "../components/Refund/Refund";
import VdsReport from "../components/VDS/VdsReport";
import FloorSpaceReport from "../components/FloorSpace/FloorSpaceReport";
import UnRegisteredBin from "../components/UnRegisteredBin/UnRegisteredBin";
import CoEfficientEntry from "../components/UnitProfile/CoEfficientEntry";
import AuditReport from "../components/Audit/AuditReport";
import Juwellery from "../components/Juwellery/Juwellery";
import Efd from "../components/EFD/Efd";
import DebtReport from "../components/Debt/DebtReport";
export default function UnitProfilePage(){
    let state=useSelector((state)=> state);
    const[BinName,setBinName]=useState("No Name found");
    const[BtnSearch,setBtnSearch]=useState("primary");

    const[AllData,setAllData]=useState([{}]);

    const getBinName=()=>{
        const BinNameIndex=document.getElementById("cmbBinNameUnit");
        const BinNameValue=BinNameIndex.options[BinNameIndex.selectedIndex].text;


        let jsonObject={"sql":"Select *  from IVAS_BIN where Name='"+BinNameValue+"' "}
        setBtnSearch("secondary")
        setAllData([{}])
        RestClient.PostRequest(AppUrl.getData,jsonObject).then(result=>{
            if(result==""){cogoToast.error("Data not found");setBinName([]);setBtnSearch("primary")}else {
                        cogoToast.success("Data found");setBtnSearch("primary")
                setBinName(result[0]["Name"])

                setAllData(result)



            }
        }).catch(error=>{
            setBtnSearch("primary")
        });
    }

    return(
        <Fragment>
            <TopNavigation/>
            <div className="purchaseDiv text-center mergin_top">
                <Tabs
                    defaultActiveKey="home"
                    transition={false}
                    id="noanim-tab-"
                    className="mb-3"
                >
                    <Tab eventKey="BIN" title="BIN">
                       <Report/>
                    </Tab>
                    <Tab eventKey="4.3-Entry" title="4.3 Entry">
                        <CoEfficientEntry/>
                    </Tab>
                    <Tab eventKey="4.3-Report" title="4.3 Report">
                        <CoEfficientReport/>
                    </Tab>

                    <Tab eventKey="Bricks" title="Bricks">
                            <BricksReport/>
                    </Tab>
                    <Tab eventKey="VDS" title="VDS">
                        <VdsReport/>
                    </Tab>
                    <Tab eventKey="FloorSpace" title="Floor Space">
                        <FloorSpaceReport/>
                    </Tab>
                    <Tab eventKey="Refund" title="Refund">
                        <Refund/>
                    </Tab>
                    <Tab eventKey="audit" title="Audit">
                        <AuditReport/>
                    </Tab>
                    <Tab eventKey="debt" title="Debt">
                        <DebtReport/>
                    </Tab>
                    <Tab eventKey="juwellery" title="Juwellery">
                        <Juwellery/>
                    </Tab>
                    <Tab eventKey="efd" title="EFDMS">
                        <Efd/>
                    </Tab>
                    <Tab eventKey="Unregistered" title="Unregistered BIN">
                        <UnRegisteredBin/>
                    </Tab>




                </Tabs>


            </div>
        </Fragment>
    )
}
