import {Fragment, useState} from "react";
import {Button, Col, Container, Modal, Row} from "react-bootstrap";
import {useSelector} from "react-redux";
import cogoToast from "cogo-toast";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
import BootstrapTable from "react-bootstrap-table-next";
export default function TableSetting(){
    let state=useSelector((state)=> state);
    const [Data, setData]=useState([]);
    const [Column, setColumn]=useState([{}]);


    const getUser=()=>{

        let jsonObject={"sql":" show tables "}


        setColumn([{}]);setData([])
        RestClient.PostRequest(AppUrl.deleteData,jsonObject).then(async result=>{

            if(result==""){cogoToast.error("Data not found");}else {
                await setColumn(
                    Object.keys(result[0]).map((k) => {
                        return {dataField: k, text: k, sort: true}
                    })
                )
                await setData(result);

            }
        }).catch(error=>{ });

    }



    const saveUser=()=>{



const SelectQuery=document.getElementById("txtSelectQuery").value;
const WhereQuery=document.getElementById("txtWhereQuery").value;


        let jsonObject={"sql":""+SelectQuery+" where "+WhereQuery+" "};

    if(SelectQuery==""){cogoToast.error("Please insert Select")}else{
    if(WhereQuery==""){cogoToast.error("Please insert condition")}else{


        RestClient.PostRequest(AppUrl.updateData,jsonObject).then(result=>{
            if(result==1){cogoToast.success("Execution successful");}else{cogoToast.warn("Execution not successful");}

        }).catch(error=>{
            cogoToast.error(error);

        });
    }}


    }

    return(
        <Fragment>
               <Container>
                <Row>
                    <Col lg={4} sm={12}>

                        <Row className="mt-2 text-justify">

                            <Col lg={12}> <input id="txtSelectQuery" className="w-100"/></Col>

                        </Row>
                        <Row className="mt-2 text-justify">
                            <Col lg={12}><label className="customfontsize" className="w-100">Where</label></Col>


                        </Row>
                        <Row className="mt-2 text-justify">

                            <Col lg={12}> <input id="txtWhereQuery" className="w-100"/></Col>

                        </Row>


                        <Row className="mt-2 text-justify">
                            <Col lg={6}> </Col>
                            <Col lg={6}><Button onClick={saveUser} className="w-100">Save</Button></Col>
                        </Row>
                    </Col>
                    <Col lg={8} sm={12}>
                        <div className="purchaseDiv text-center">

                            <Row >

                                <Col lg={6} md={6} sm={6}><Button onClick={getUser}  className="w-50 m-4"  >Search User</Button></Col>

                            </Row>

                            <div className="tableDiv overflow-scroll">
                                <BootstrapTable  keyField='Id' data={Data} columns={Column}  > </BootstrapTable>
                            </div>





                        </div>
                    </Col>
                </Row>

               </Container>

        </Fragment>
    )
}
