import {Fragment, useState} from "react";
import {Button, Col, Modal, Row} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
import cogoToast from "cogo-toast";
import LoadingText from "../Loading/LoadingText";
import {useSelector} from "react-redux";
import DatePicker from "react-datepicker";
import appUtility from "../../RestAPI/AppUtility";


export default function Juwellery(){
    let state=useSelector((state)=> state);
    const[JuwelleryDivision,setJuwelleryDivision]=useState([]);
    const[JuwelleryCircle,setJuwelleryCircle]=useState([]);
    const [DataCoEffeciant, setDataCoEffeciant]=useState([]);
    const [ColumnCoEfficient, setColumnCoEfficient]=useState([{}]);
    const [BinJuwellery, setBinJuwellery]=useState()
    const [NameJuwellery, setNameJuwellery]=useState()
    const [DataLength, setDatalength]=useState(0)
    const[DataStatus, setDataStatus]=useState(false)

    const[ShowJuwellery, setShowJuwellery]=useState(false)

    const handleCloseJuwellery = () => setShowJuwellery(false);
    const handleShowJuwellery = () => setShowJuwellery(true);

    const selectRow={
        mode:'radio',
        onSelect:(row,isSelect,rowIndex)=>{
            setNameJuwellery(row['Name']);
            setBinJuwellery(row['BIN']);
            handleShowJuwellery();
        }
    }
    const getJuwelleryBIN=()=>{
        setDataStatus(true)

        const JewelleryCommi=document.getElementById("cmbJewelleryBinCommi");
        const JewelleryCommiValue=JewelleryCommi.options[JewelleryCommi.selectedIndex].text;

        const JuwelleryDivision=document.getElementById("cmbJuwellerysDivision");
        const JuwelleryDivisionValue=JuwelleryDivision.options[JuwelleryDivision.selectedIndex].text;

        const JuwelleryCircle=document.getElementById("cmbJuwelleryCircle");
        const JuwelleryCircleValue=JuwelleryCircle.options[JuwelleryCircle.selectedIndex].text;

        const JuwelleryPaymentStatus=document.getElementById("cmbJuwelleryPaymentStatus");

        let getJuwelleryCommi="";
        let getJuwelleryDivision="";
        let getJuwelleryCircle="";

       if(JewelleryCommi.selectedIndex!=0){getJuwelleryCommi=" And bin.Commissionerate Like '%"+JewelleryCommiValue+"%' "}
       if(JuwelleryDivision.selectedIndex!=0){getJuwelleryDivision=" And bin.Division Like '%"+JuwelleryDivisionValue+"%' "}
        if(JuwelleryCircle.selectedIndex!=0){getJuwelleryCircle=" And bin.Circle Like '%"+JuwelleryCircleValue+"%' "}


        let getSql={"sql":"select bin.BIN,bin.Name, bin.Division,bin.Circle, juwellery_revenue.July from bin left join juwellery_revenue on  juwellery_revenue.BIN = bin.BIN where Category='Jewellery' "+getJuwelleryCommi+" "+getJuwelleryDivision+" "+getJuwelleryCircle+" "}

        if(JuwelleryPaymentStatus.selectedIndex==1){getSql={"sql":"select bin.BIN,bin.Name, bin.Division,bin.Circle, juwellery_revenue.July, juwellery_revenue.August, juwellery_revenue.September, juwellery_revenue.October, juwellery_revenue.November, juwellery_revenue.December, juwellery_revenue.January, juwellery_revenue.February,juwellery_revenue.March, juwellery_revenue.April, juwellery_revenue.May, juwellery_revenue.June from bin inner join juwellery_revenue  on juwellery_revenue.BIN=bin.BIN where bin.Category='Jewellery' "+getJuwelleryDivision+" "+getJuwelleryCircle+""}}
        if(JuwelleryPaymentStatus.selectedIndex==2){getSql={"sql":"select bin.BIN,bin.Name, bin.Division,bin.Circle   from bin   where not bin.BIN  in (select BIN from juwellery_revenue ) And Category='Jewellery' "+getJuwelleryDivision+"  "+getJuwelleryCircle+" "}}

        setColumnCoEfficient([{}]);setDataCoEffeciant([])
        RestClient.PostRequest(AppUrl.getData,getSql).then(async result=>{
            setDatalength(result.length)
            if(result==""){cogoToast.error("Data not found");
                setDataStatus(false)}else {
                await setColumnCoEfficient(
                    Object.keys(result[0]).map((k) => {
                        return {dataField: k, text: k, sort: true}
                    })
                )
                await setDataCoEffeciant(result);
                setDataStatus(false)
            }
        }).catch(error=>{setDataStatus(false)});
    }
    const saveJuwelleryRevenue=()=>{
        const juwelleryYear=document.getElementById("cmbJuwelleryYear");
        const juwelleryYearValue=juwelleryYear.options[juwelleryYear.selectedIndex].text;
        const juwelleryMonth=document.getElementById("cmbJuwelleryMonth");
        const juwelleryMonthValue=juwelleryMonth.options[juwelleryMonth.selectedIndex].text;
        const juwelleryRevenue=document.getElementById("txtJuwelleryAmount").value;
        let getJsonObject={"sql":"Insert into juwellery_revenue(Id,BIN,July,FY)values(NULL,'"+BinJuwellery+"','"+juwelleryRevenue+"', '"+juwelleryYearValue+"') "}
        if(juwelleryMonth.selectedIndex!=1){
            getJsonObject={"sql":"Update juwellery_revenue set "+juwelleryMonthValue+"='"+juwelleryRevenue+"' where BIN='"+BinJuwellery+"' "}
        }

        if(juwelleryYear.selectedIndex==0){cogoToast.error("Please select FY")}else{
            if(juwelleryMonth.selectedIndex==0){cogoToast.error("Please select Month")}else{
                if(juwelleryRevenue==""){cogoToast.error("Please insert amount")}else{
                    RestClient.PostRequest(AppUrl.saveData,  getJsonObject).then(result => {
                        if (result == 1) {
                            cogoToast.success("Revenue Data saved")

                        } else {
                            cogoToast.error("Data not saved")
                        }
                    }).catch(error => {
                        cogoToast.error(error)
                    });

                }}
        }

    }

    return(
        <Fragment>
            <div >
                <Row className="mt-2">

                    <Col lg={3}>
                        <div className="">
                            <Row className="mt-2">
                                <Col lg={3} xs={4}><label className="text-danger fw-bold">NBR</label></Col>
                                <Col lg={9}  xs={8}>
                                    <select className="float-start w-100 customfontsize" id="cmbJewelleryBinCommi"
                                            onChange={()=>{
                                                setJuwelleryDivision([]);
                                                setJuwelleryCircle([]);
                                                const e = document.getElementById("cmbJewelleryBinCommi");
                                                const value = e.options[e.selectedIndex].value;
                                                const dt=state.division.filter(x => x.Commissionerate_Id==value);
                                                setJuwelleryDivision(dt)


                                            }}
                                    >
                                        <option >NBR</option>
                                        {
                                            state.commi.map((ctr,index)=>{
                                                return (
                                                    <option key={index} value={ctr.Id}>{ctr.Name}</option>
                                                )
                                            })

                                        }
                                    </select>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col lg={3}xs={4}><label className="text-danger fw-bold">Division</label></Col>
                                <Col lg={9} xs={8}>
                                    <select className="float-start w-100 customfontsize" id="cmbJuwellerysDivision"
                                            onChange={()=>{
                                                setJuwelleryCircle([]);
                                                const e = document.getElementById("cmbJuwellerysDivision");
                                                const value = e.options[e.selectedIndex].value;
                                                const dt=state.circle.filter(x => x.Division_Id==value);
                                                setJuwelleryCircle(dt)

                                            }}
                                    >
                                        <option >All Division</option>
                                        {


                                            JuwelleryDivision.map((ctr,index)=>{
                                                return (
                                                    <option key={index} value={ctr.Id}>{ctr.Name}</option>
                                                )
                                            })

                                        }
                                    </select>
                                </Col>

                            </Row>
                            <Row className="mt-2">
                                <Col lg={3} xs={4}><label className="text-danger fw-bold">Circle</label></Col>
                                <Col lg={9} xs={8}>
                                    <select className="float-start w-100 customfontsize" id="cmbJuwelleryCircle"  >
                                        <option >All Circle</option>
                                        {


                                            JuwelleryCircle.map((ctr,index)=>{
                                                return (
                                                    <option key={index} value={ctr.Id}>{ctr.Name}</option>
                                                )
                                            })

                                        }
                                    </select>
                                </Col>

                            </Row>
                            <Row className="mt-2">
                                <Col lg={3} xs={4}><label className="text-danger fw-bold">Payment</label></Col>
                                <Col lg={9} xs={8}>
                                    <select className="float-start w-100 customfontsize" id="cmbPaymentStatus"  >
                                        <option >All BIN</option>
                                        <option >Paid Only</option>
                                        <option >No Paid</option>

                                    </select>
                                </Col>

                            </Row>
                            <Row className="mt-2">
                                <Col lg={3} xs={4}><label className="text-danger fw-bold">Payment</label></Col>
                                <Col lg={9} xs={8}>
                                    <select className="float-start w-100 customfontsize" id="cmbJuwelleryPaymentStatus"  >
                                        <option >All </option>
                                        <option >Paid</option>
                                        <option >Not Paid</option>

                                    </select>
                                </Col>

                            </Row>
                            <Row className="mt-2">
                                <Col lg={3} xs={4}><label className="text-danger fw-bold">Amount</label></Col>
                                <Col lg={9} xs={8}>
                                    <select className="float-start w-100 customfontsize" id="cmbPaymentAmountStatus"  >
                                        <option >No Amount</option>
                                        <option >Greater Than</option>
                                        <option >Less Than</option>

                                    </select>
                                </Col>

                            </Row>
                            <Row className="mt-2">
                                <Col lg={3}xs={4}><label className="text-danger fw-bold">Value</label></Col>
                                <Col lg={9} xs={8}>
                                    <input type="text" className="w-100"/>
                                </Col>

                            </Row>

                            <Row className="mt-2">
                                <Col lg={3}>

                                </Col>
                                <Col lg={9}>
                                    <button className="w-100" onClick={getJuwelleryBIN}>Search</button>
                                </Col>


                            </Row>


                        </div>
                    </Col>

                    <Col lg={9}>
                        <div className="overflow-scroll animated zoomIn purchaseSmallDiv">
                            {
                                DataStatus?<LoadingText/>
                                    : <BootstrapTable selectRow={selectRow}   keyField='BIN' data={DataCoEffeciant} columns={ColumnCoEfficient}></BootstrapTable>

                            }
                        </div>
                        <label className="text-danger customfontsize">Data found: {DataLength}</label>
                    </Col>

                </Row>

            </div>


            <Modal show={ShowJuwellery} onHide={handleCloseJuwellery}>
                <Modal.Header closeButton>
                    <Modal.Title >
                        <label className="text-danger">Revenue Enty Form </label>

                        <hr/>
                        <label>Name :</label> <label className="text-danger"> {NameJuwellery}</label>
                        <hr/>
                        <label>BIN :</label> <label className="text-danger"> {BinJuwellery}</label>
                    </Modal.Title>


                </Modal.Header>
                <Modal.Body>
                    <div className="flex-column">
                        <label>Year :</label>
                        <select className="float-right me-2 w-50 customfontsize" id="cmbJuwelleryYear"  >
                            <option >Select FY</option>
                            <option >24-25</option>
                            <option >25-26</option>
                            <option >26-27</option>
                            <option >27-28</option>
                        </select>
                        <hr/>
                        <label>Month :</label>
                        <select className="float-right me-2 w-50 customfontsize" id="cmbJuwelleryMonth"  >
                            <option >Select Month</option>
                            <option >July</option>
                            <option >August</option>
                            <option >September</option>
                            <option >October</option>
                            <option >November</option>
                            <option >December</option>
                            <option >January</option>
                            <option >February</option>
                            <option >March</option>
                            <option >April</option>
                            <option >May</option>
                            <option >June</option>
                        </select>
                        <hr/>
                        <label>Amount:</label>
                        <input type="text" className="w-50" id="txtJuwelleryAmount"/>
                        <label>Crore</label>

                    </div>



                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary"  onClick={saveJuwelleryRevenue} >
                        Save
                    </Button>
                    <Button variant="danger" onClick={handleCloseJuwellery}>
                        Close
                    </Button>


                </Modal.Footer>
            </Modal>
        </Fragment>

    )
}
