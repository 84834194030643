import {Fragment, useEffect, useState} from "react";
import {Button, Card, Col, Container, Modal, Row} from "react-bootstrap";
import cogoToast from "cogo-toast";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
import {useSelector} from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import DatePicker from "react-datepicker";
import AppUtility from "../../RestAPI/AppUtility";
import appUtility from "../../RestAPI/AppUtility";
import LoadingText from "../Loading/LoadingText";

export default function NewFile(){
    let state=useSelector((state)=> state);
    const[DataStatus, setDataStatus]=useState(false)
    const [DateSubmit, setDateSubmit] = useState(new Date());
    const [DateMovement, setDateMovement] = useState(new Date());
    const [DataLength, setDatalength]=useState(0)

    const[Data,setData]=useState([]);
    const [Column, setColumn]=useState([{}]);
    const[Show,setShow]=useState();
    const[ShowFT,setShowFT]=useState();
    const[NothiId,setNothiId]=useState();
    const[NothiNo,setNothiNo]=useState();
    const[NothiHeading,setnothiHeading]=useState();
    const handleClose=()=>{

        setShow(false)

    }
    const handleOpen=()=>{
        setShow(true)
    }

    const handleCloseFT=()=>{

        setShowFT(false)

    }
    const handleOpenFT=()=>{
        setShowFT(true)
    }



    const selectRow={
        mode:'radio',
        onSelect:(row,isSelect,rowIndex)=>{
            setNothiId(row["Id"])
            setNothiNo(row["Nothi_No"])
            setnothiHeading(row["Id"])
            handleOpenFT()
        }
    }

    const saveNothi=()=>{
        const NothiNo=document.getElementById("txtNothiNo").value;
        const NothiHeading=document.getElementById("txtNothiHeading").value;
        const NothiSection=document.getElementById("cmbNothiSection");
        const NothiSectionValue=NothiSection.options[NothiSection.selectedIndex].text;
        const NothiComments=document.getElementById("txtNothiComments").value;

        const NothiAt=document.getElementById("cmbNothiAt");
        const NothiAtValue=NothiAt.options[NothiAt.selectedIndex].text;

        let jsonObject={"sql":"Insert into Nothi (Id,Nothi_No,Nothi_Heading,Section,Comments,Status,File_Location,File_At,Creation_Date)" +
                "Values(NULL,'"+NothiNo+"','"+NothiHeading+"','"+NothiSectionValue+"','"+NothiComments+"','Active','"+NothiSectionValue+"','"+NothiAtValue+"','"+appUtility.getDate(DateSubmit)+"')"}

        if(NothiNo==""){cogoToast.error("Please insert Nothi")}else{
            if(NothiHeading==""){cogoToast.error("Please Insert Nothi Heading")}else{
                if(NothiSection.selectedIndex==0){cogoToast.error("Please select Nothi Section")}else{
                if(NothiAt.selectedIndex==0){cogoToast.error("Please select Officer Name")}else{
                    if(NothiComments==""){cogoToast.error("Please insert Comments")}else{
                        RestClient.PostRequest(AppUrl.saveData,jsonObject).then(async result=>{
                            if(result==""){cogoToast.error("Data not found");}else {
                                cogoToast.success("Data saved");getFile();

                            }
                        }).catch(error=>{});
                    }
                }}}
        }
    }
    const getFile=()=>{
        setDataStatus(true)
        let jsonObject={"sql":"SELECT Id,Nothi_No, Nothi_Heading,Section,File_Location, File_At,File_Entry_date,DATEDIFF(CURDATE(),File_Entry_date ) AS 'File Waiting days', Comments FROM `Nothi` where Status='Active' and File_At='"+state.name+"' "}

        setColumn([{}]);setData([])

        RestClient.PostRequest(AppUrl.getData, jsonObject).then(async result => {
            setDatalength(result.length)
            await setColumn(
                Object.keys(result[0]).map((k) => {
                    return {dataField: k, text: k, sort: true}
                })
            )
            await setData(result);
            setDataStatus(false)


        }).catch(error => {
            setDataStatus(false)
        });
    }
    const moveFile=()=>{
        const NothiSent=document.getElementById("cmbNothiSent");
        const NothiSentValue=NothiSent.options[NothiSent.selectedIndex].text;
        const NothiSentTo=document.getElementById("cmbNothiSentTo");
        const NothiSentToValue=NothiSentTo.options[NothiSentTo.selectedIndex].text;


        let jsonObject={"sql":"update nothi set File_Location='"+NothiSentValue+"' , File_At='"+NothiSentToValue+"', File_Entry_Date='"+appUtility.getDate(DateMovement)+"' where Id='"+NothiId+"' "}

        if(NothiSent.selectedIndex==0){cogoToast.error("Please select section")}else{
            if(NothiSentTo.selectedIndex==0){cogoToast.error("Please select office name")}else{
                RestClient.PostRequest(AppUrl.updateData, jsonObject).then(async result => {
                    if(result==""){cogoToast.error("File not Saved");}else {
                        cogoToast.success("File saved");getFile()

                    }


                }).catch(error => {
                });
            }
        }


    }
    const updateStatus=()=>{
        const NothiStatus=document.getElementById("cmbNothiStatus");
        const NothiStatusValue=NothiStatus.options[NothiStatus.selectedIndex].text;



        let jsonObject={"sql":"update nothi set Status='"+NothiStatusValue+"'  where Id='"+NothiId+"' "}

        if(NothiStatus.selectedIndex==0){cogoToast.error("Please select Status")}else{

                RestClient.PostRequest(AppUrl.updateData, jsonObject).then(async result => {
                    if(result==""){cogoToast.error("Status not Changed");}else {
                        cogoToast.success("Status Changed");getFile()

                    }


                }).catch(error => {
                });
            }



    }
    return (
        <Fragment>
            <Container className="animated zoomIn  ">


                <Row className="mt-2">
                    <Col sm={12} xs={12} md={12} lg={12}>
                        <Card>
                            <Card.Body>
                                <Container fluid={true}>
                                    <Row>
                                        <Col className="m-1 " lg={2}xs={12} sm={12}><button onClick={handleOpen} className="float-start circular-btn">File Creation <i className="fa fa-plus"/></button></Col>
                                        <Col className="m-1" lg={2} xs={12}sm={12}><button onClick={getFile} className="float-start circular-btn">File Load <i className="fa fa-plus"/></button></Col>
                                        <Col className="m-1" lg={2} xs={12}sm={12}><button  className="float-start circular-btn">Letter Creation <i className="fa fa-plus"/></button></Col>
                                        <Col className="m-1" lg={2} xs={12} sm={12}><input className="w-50" placeholder="Search file" type="text"/> <button>Search</button></Col>

                                    </Row>
                                </Container>
                                <hr className="bg-secondary"/>
                                <Container fluid={true}>
                                    <Row>
                                        <Col sm={12} xs={12} md={12} lg={12}>
                                            <div className="purchaseMediumDiv">
                                                {
                                                    DataStatus? <LoadingText/>
                                                        : <BootstrapTable  keyField='Id' data={Data} columns={Column}  selectRow={selectRow} > </BootstrapTable>

                                                }
                                                </div>
                                            <label className="text-danger">File Found :{DataLength}</label>
                                        </Col>
                                    </Row>
                                </Container>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>


            </Container>

            <Modal animation={false} className="animated zoomIn" show={Show} onHide={handleClose}>
                <Modal.Header>
                    <div >
                        <label className="text-primary font-weight-bold fs-5 text-center"> New File Creation Form</label>

                    </div>

                </Modal.Header>
                <Modal.Body>

                    <label className="form-label text-danger">Nothi No.</label>
                    <input className="form-control form-control-sm"   type="text" id="txtNothiNo"/>
                    <hr/>
                    <label className="form-label text-danger">Nothi Heading</label>
                    <input className="w-100" type="text" id="txtNothiHeading"/>
                    <hr/>
                    <label className="form-label text-danger">Section</label>
                    <select  className="w-25 " id="cmbNothiSection">
                        <option>Select</option>
                        <option>GL</option>
                        <option>Law</option>
                        <option>Refund</option>
                        <option>Section-4.3</option>
                        <option>Vat</option>
                        <option>Customs</option>
                        <option>Audit</option>
                        <option>Modernisation</option>

                    </select>
                    <label className="form-label text-danger">Name :-</label>
                    <select  className="w-25 " id="cmbNothiAt">
                        <option>Select</option>
                        {
                            state.officers.map((ctr,index)=>{
                                return (
                                    <option key={index} value={ctr.Id}>{ctr.Name}</option>
                                )
                            })

                        }

                    </select>
                    <hr/>
                    <label className="form-label text-danger">Creation Date</label>
                    <DatePicker selected={DateSubmit} onChange={(date) => setDateSubmit(date)} dateFormat='dd-MM-yyyy' seletable maxDate={new Date()} isClearable showYearDropdown scrollableMonthYearDropdown  />

                    <label className="form-label">Comments</label>
                    <textarea className="form-control form-control-sm" id="txtNothiComments" ></textarea>
                    <hr/>

                </Modal.Body>
                <Modal.Footer>

                        <Button className="btn btn-sm btn-site" variant="danger" onClick={handleClose}>
                            Close
                        </Button>
                        <button className="" onClick={saveNothi} >
                            Save
                        </button>




                </Modal.Footer>
            </Modal>
            <Modal animation={false} className="animated zoomIn" show={ShowFT} onHide={handleCloseFT}>
                <Modal.Header>
                    <div >
                        <label className="text-primary font-weight-bold fs-5 text-center">File Movement</label>

                    </div>

                </Modal.Header>
                <Modal.Body>

                    <label className="form-label text-danger">Nothi ID:-{NothiId}</label>
                    <label className="form-label float-end text-danger">Nothi No:-{NothiNo}</label>

                    <hr/>
                    <label className="form-label text-danger">Nothi Heading: </label>
                   <label>{NothiHeading}</label>
                    <hr/>
                    <label className="form-label text-danger">Section</label>
                    <select  className="w-25 " id="cmbNothiSent">
                        <option>Select</option>
                        <option>Commissioner</option>
                        <option>ADC</option>
                        <option>JC</option>
                        <option>DC</option>
                        <option>AC</option>
                        <option>RO</option>
                        <option>ARO</option>
                        <option>Section</option>


                    </select>
                    <label className="form-label text-danger">Name :-</label>
                    <select  className="w-25 " id="cmbNothiSentTo">
                        <option>Select</option>
                        {
                            state.officers.map((ctr,index)=>{
                                return (
                                    <option key={index} value={ctr.Id}>{ctr.Name}</option>
                                )
                            })

                        }

                    </select>
                    <hr/>
                    <label className="form-label text-danger">File Sent Date</label>
                    <DatePicker selected={DateMovement} onChange={(date) => setDateMovement(date)} dateFormat='dd-MM-yyyy' seletable maxDate={new Date()} isClearable showYearDropdown scrollableMonthYearDropdown  />
                    <hr/>
                    <select  className="w-25 mr-1 " id="cmbNothiStatus">
                        <option>Select</option>
                        <option>Active</option>
                        <option>Inactive</option>
                    </select>
                    <button className="text-center text-success" onClick={updateStatus} >
                        Status Update ?
                    </button>

                </Modal.Body>
                <Modal.Footer>

                    <Button className="btn btn-sm btn-site" variant="danger" onClick={handleCloseFT}>
                        Close
                    </Button>
                    <button className="" onClick={moveFile} >
                        Save
                    </button>
                </Modal.Footer>
            </Modal>



        </Fragment>
    );
}
