import {Fragment, useState} from "react";
import {Button, Col, Modal, Row} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
import cogoToast from "cogo-toast";
import LoadingText from "../Loading/LoadingText";
import {useSelector} from "react-redux";


export default function DebtReport(){
    let state=useSelector((state)=> state);
    const [DebtDivision, setDebtDivision]=useState([]);
    const [DebtCircle, setDebtCircle]=useState([]);
    const [DataCoEffeciant, setDataCoEffeciant]=useState([]);
    const [ColumnCoEfficient, setColumnCoEfficient]=useState([{}]);
    const [DataLength, setDatalength]=useState(0)
    const[DataStatus, setDataStatus]=useState(false)

    const[Division, setDivision]=useState([])







    const selectRow={
        mode:'radio',
        onSelect:(row,isSelect,rowIndex)=>{






        }
    }

    const getDebtData=()=>{
        setDataStatus(true);

        const DebtCommi=document.getElementById("cmbDebtCommi");
        const DebtCommiValue=DebtCommi.options[DebtCommi.selectedIndex].text;

        const DivisionDebt=document.getElementById("cmbDebtDivision");
        const DivisionDebtValue=DivisionDebt.options[DivisionDebt.selectedIndex].text;

        const CircleDebt=document.getElementById("cmbAuditBinCircle");
        const CircleDebtValue=CircleDebt.options[CircleDebt.selectedIndex].text;

        const BinCategoryDebt=document.getElementById("cmbAuditBinCategory");
        const CategoryDebtValue=BinCategoryDebt.options[BinCategoryDebt.selectedIndex].text;
        let getCommiDebt=" ";
        let getDivisionDebt=" ";
        let getCircleDebt=" ";
        let getCategoryDebt=" ";
        if(DebtCommi.selectedIndex!=0){getCommiDebt="And Commissionerate Like '%"+DebtCommiValue+"%'"}
        if(DivisionDebt.selectedIndex!=0){getDivisionDebt="And Division Like '%"+DivisionDebtValue+"%'"}
        if(CircleDebt.selectedIndex!=0){getCircleDebt="And Circle Like '%"+CircleDebtValue+"%'"}
        if(BinCategoryDebt.selectedIndex!=0){getCategoryDebt="And Economic_Activity Like '%"+CategoryDebtValue+"%'"}

        let jsonObject={"sql":" Select * from Debt_report where 1=1  "+getCommiDebt+" "+getDivisionDebt+" "+getCircleDebt+" "+getCategoryDebt+" "}

        setColumnCoEfficient([{}]);setDataCoEffeciant([])
        RestClient.PostRequest(AppUrl.getData,jsonObject).then(async result=>{
            setDatalength(result.length);
            setDataStatus(false);
            if(result==""){cogoToast.error("Data not found")}else {
                await setColumnCoEfficient(
                    Object.keys(result[0]).map((k) => {
                        return {dataField: k, text: k, sort: true}
                    })
                )
                await setDataCoEffeciant(result);
                setDataStatus(false);

            }
        }).catch(error=>{});
    }
    return(
        <Fragment>
            <div >
                <Row className="mt-2">

                    <Col lg={3}>
                        <div className="">
                            <Row className="mt-2">
                                <Col lg={3} xs={4}><label className="text-danger fw-bold">NBR</label></Col>
                                <Col lg={9}  xs={8}>
                                    <select className="float-start w-100 customfontsize" id="cmbDebtCommi"
                                            onChange={()=>{
                                                setDebtDivision([]);
                                                setDebtCircle([]);
                                                const e = document.getElementById("cmbDebtCommi");
                                                const value = e.options[e.selectedIndex].value;
                                                const dt=state.division.filter(x => x.Commissionerate_Id==value);
                                                setDebtDivision(dt)


                                            }}
                                    >




                                        <option >NBR</option>



                                        {


                                            state.commi.map((ctr,index)=>{
                                                return (
                                                    <option key={index} value={ctr.Id}>{ctr.Name}</option>
                                                )
                                            })

                                        }
                                    </select>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col lg={3}xs={4}><label className="text-danger fw-bold">Division</label></Col>
                                <Col lg={9}xs={8}>
                                    <select className="float-start w-100 customfontsize" id="cmbDebtDivision"
                                            onChange={()=>{
                                                setDebtCircle([])
                                                const e = document.getElementById("cmbDebtDivision");
                                                const value = e.options[e.selectedIndex].value;
                                                const dt=state.circle.filter(x => x.Division_Id==value);
                                                setDebtCircle(dt)


                                            }}
                                    >
                                        <option>All Division</option>

                                        {


                                            DebtDivision.map((ctr,index)=>{
                                                return (
                                                    <option key={index} value={ctr.Id}>{ctr.Name}</option>
                                                )
                                            })

                                        }


                                    </select>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col lg={3}xs={4}><label className="text-danger fw-bold">Circle</label></Col>
                                <Col lg={9}xs={8}>
                                    <select className="float-start w-100 customfontsize" id="cmbDebtBinCircle" >
                                        <option>All Circle</option>

                                        {


                                            DebtCircle.map((ctr,index)=>{
                                                return (
                                                    <option key={index} value={ctr.Id}>{ctr.Name}</option>
                                                )
                                            })

                                        }


                                    </select>
                                </Col>
                            </Row>

                            <Row className="mt-2">
                                <Col lg={3}xs={4}><label className="text-danger fw-bold">Category</label></Col>
                                <Col lg={9}xs={8}>
                                    <select className="float-start w-100 customfontsize" id="cmbAuditBinCategory"  >
                                        <option >All BIN</option>
                                        <option >Manufacturing</option>
                                        <option >Services</option>
                                        <option >Other</option>
                                        <option >Imports</option>
                                        <option >Wholesale</option>
                                    </select>
                                </Col>

                            </Row>
                            <Row className="mt-2">
                                <Col lg={3}>

                                </Col>
                                <Col lg={9}>
                                    <button className="w-100" onClick={getDebtData}>Search</button>
                                </Col>


                            </Row>


                        </div>
                    </Col>

                    <Col lg={9}>
                        <div className="overflow-scroll animated zoomIn purchaseSmallDiv">

                            {
                                DataStatus?<LoadingText/>
                                    :  <BootstrapTable   keyField='Id' selectRow={selectRow} data={DataCoEffeciant} columns={ColumnCoEfficient}></BootstrapTable>

                            }
                           </div>
                        <label className="text-danger customfontsize">Data found: {DataLength}</label>

                    </Col>

                </Row>

            </div>

        </Fragment>

    )
}
