import {Fragment, useState} from "react";
import {Button, Col, Row} from "react-bootstrap";
import LoadingText from "../Loading/LoadingText";
import BootstrapTable from "react-bootstrap-table-next";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
import cogoToast from "cogo-toast";
import {useSelector} from "react-redux";
export default function DebtEntry(){
    let state=useSelector((state)=> state);
    const [Division, setDivision]=useState([]);
    const [Circle, setCircle]=useState([]);
    const[DataStatus, setDataStatus]=useState(false)
    const [DataCoEffeciant, setDataCoEffeciant]=useState([]);
    const [ColumnCoEfficient, setColumnCoEfficient]=useState([{}]);

    const getBIN=()=> {
        setDataStatus(true);
    const txtBin=document.getElementById("txtBin").value;
       let jsonObject={"sql":" Select Commissionerate,Division,Circle,BIN,Name,Mobile,Address from BIN where BIN Like '%"+txtBin+"%' LIMIT 1"}

        setColumnCoEfficient([{}]);
        setDataCoEffeciant([])
        RestClient.PostRequest(AppUrl.getData, jsonObject).then(async result => {

            if (result == "") {
                cogoToast.error("Data not found");
                setDataStatus(false)
            } else {
                await setColumnCoEfficient(
                    Object.keys(result[0]).map((k) => {
                        return {dataField: k, text: k, sort: true}
                    })
                )
                await setDataCoEffeciant(result);
                setDataStatus(false)
            }
        }).catch(error => {
            setDataStatus(false);
        });
    }
    return(
        <Fragment>

                <div className="overflow-scroll animated zoomIn purchaseMediumDiv">
                <Row>
                    <Col lg={2}><input className="w-50" id="txtBin" type="text"/> <Button onClick={getBIN}>Search</Button></Col>
                    <Col lg={10}>
                        {
                            DataStatus? <LoadingText/>
                                :<BootstrapTable   keyField='BIN' data={DataCoEffeciant} columns={ColumnCoEfficient}></BootstrapTable>
                        }
                    </Col>
                </Row>
                <Row>
                    <hr/>
                </Row>
                    <Col lg={3}>
                        <div className="">
                            <Row className="mt-2">
                                <Col lg={3} xs={4}><label className="text-danger fw-bold">NBR</label></Col>
                                <Col lg={9}  xs={8}>
                                    <select className="float-start w-100 customfontsize" id="cmbBinCommi"
                                            onChange={()=>{
                                                setDivision([]);
                                                setCircle([]);
                                                const e = document.getElementById("cmbBinCommi");
                                                const value = e.options[e.selectedIndex].value;
                                                const dt=state.division.filter(x => x.Commissionerate_Id==value);
                                                setDivision(dt)


                                            }}
                                    >
                                        <option >NBR</option>
                                        {
                                            state.commi.map((ctr,index)=>{
                                                return (
                                                    <option key={index} value={ctr.Id}>{ctr.Name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col lg={3} xs={4}><label className="text-danger fw-bold">Division</label></Col>
                                <Col lg={9} xs={8}>
                                    <select className="float-start w-100 customfontsize" id="cmbBinDivision"
                                            onChange={()=>{
                                                setCircle([])
                                                const e = document.getElementById("cmbBinDivision");
                                                const value = e.options[e.selectedIndex].value;
                                                const dt=state.circle.filter(x => x.Division_Id==value);
                                                setCircle(dt);
                                            }}
                                    >
                                        <option >All Division</option>

                                        {
                                            Division.map((ctr,index)=>{
                                                return (
                                                    <option key={index} value={ctr.Id}>{ctr.Name}</option>
                                                )
                                            })

                                        }
                                    </select>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col lg={3} xs={4}><label className="text-danger fw-bold">Circle</label></Col>
                                <Col lg={9} xs={8}>
                                    <select className="float-start w-100 customfontsize" id="cmbBinCircle" >
                                        <option >All Circle</option>

                                        {
                                            Circle.map((ctr,index)=>{
                                                return (
                                                    <option key={index} value={ctr.Id}>{ctr.Name}</option>
                                                )
                                            })

                                        }


                                    </select>
                                </Col>
                            </Row>

                            <Row className="mt-4">
                                <Col lg={3} >

                                </Col>
                                <Col lg={9} >
                                    <button className="w-100 text-danger" >Save</button>
                                </Col>


                            </Row>



                        </div>
                    </Col>

            </div>

        </Fragment>

    )
}
