import {Fragment, useEffect, useState} from "react";
import {Button, Card, Col, Container, ListGroup, Modal, Row} from "react-bootstrap";

import {useSelector} from "react-redux";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
import cogoToast from "cogo-toast";
import LoadingText from "../Loading/LoadingText";
import BootstrapTable from "react-bootstrap-table-next";
import appUtility from "../../RestAPI/AppUtility";
import DatePicker from "react-datepicker";

export default function ET_Dashboard(){
    let state=useSelector((state)=> state);
    const [Section,setSection]=useState()
    const[DataLength, setDataLength]=useState(0)
    const[DataStatus, setDataStatus]=useState(false)
    const[Pending,setPending]=useState([]);
    const [DataCoEffeciant, setDataCoEffeciant]=useState([]);
    const [ColumnCoEfficient, setColumnCoEfficient]=useState([{}]);
    const [DateMovement, setDateMovement] = useState(new Date());
    const[ShowFT,setShowFT]=useState();
    const[NothiId,setNothiId]=useState();
    const[NothiNo,setNothiNo]=useState();
    const[NothiHeading,setnothiHeading]=useState();
useEffect(()=>{
    getFile();
},[])
    const handleCloseFT=()=>{

        setShowFT(false)

    }
    const handleOpenFT=()=>{
        setShowFT(true)
    }
    const selectRow={
        mode:'radio',
        onSelect:(row,isSelect,rowIndex)=>{
            setNothiId(row["Id"])
            setNothiNo(row["Nothi_No"])
            setnothiHeading(row["Id"])
            handleOpenFT()
        }
    }
    const getFile=()=>{

        let jsonObject={"sql":"SELECT count(Id) As Id, Section  FROM `Nothi` where Status='Active' and File_At='"+state.name+"'  Group By Section  "}



        RestClient.PostRequest(AppUrl.getData, jsonObject).then(async result => {

                setPending(result)

        }).catch(error => {

        });
    }

    const getFileTable=(section)=>{

    setSection(section)

        let jsonObject={"sql":"SELECT *  FROM `Nothi` where Status='Active' and File_At='"+state.name+"' and Section='"+section+"' "}

        setDataStatus(true)

        RestClient.PostRequest(AppUrl.getData, jsonObject).then(async result => {
            if(result==""){cogoToast.error("Data not found"); setDataStatus(false)}else {
                await setColumnCoEfficient(
                    Object.keys(result[0]).map((k) => {
                        return {dataField: k, text: k, sort: true}
                    })
                )
                await setDataCoEffeciant(result);
                setDataStatus(false)
            }


        }).catch(error => {
            setDataStatus(false)
        });
    }
    const moveFile=()=>{
        const NothiSent=document.getElementById("cmbNothiSent");
        const NothiSentValue=NothiSent.options[NothiSent.selectedIndex].text;
        const NothiSentTo=document.getElementById("cmbNothiSentTo");
        const NothiSentToValue=NothiSentTo.options[NothiSentTo.selectedIndex].text;


        let jsonObject={"sql":"update nothi set File_Location='"+NothiSentValue+"' , File_At='"+NothiSentToValue+"', File_Entry_Date='"+appUtility.getDate(DateMovement)+"' where Id='"+NothiId+"' "}

        if(NothiSent.selectedIndex==0){cogoToast.error("Please select section")}else{
            if(NothiSentTo.selectedIndex==0){cogoToast.error("Please select office name")}else{
                RestClient.PostRequest(AppUrl.updateData, jsonObject).then(async result => {
                    if(result==""){cogoToast.error("File not Saved");}else {
                        cogoToast.success("File saved");
                        getFileTable(Section);
                        getFile();




                    }


                }).catch(error => {
                });
            }
        }


    }
    return(
        <Fragment>
            <Container className=" text-center">
                <Row>
                    <Col lg={6} >

                        <Row>
                            {
                                Pending.map((ctr,index)=>{
                                    return (
                                        <Col lg={4}>

                                                <Card className="m-1 w-100 ">
                                                    <Card.Header className="bg-success">{ctr['Section']}</Card.Header>
                                                    <Card.Body>
                                                        <Card.Title > <button onClick={()=>{getFileTable(ctr['Section'])}} className="w-100">{ctr['Id']} </button></Card.Title>

                                                    </Card.Body>
                                                </Card>

                                        </Col>

                                    )
                                })

                            }


                        </Row>

                    </Col>



                </Row>
                <Row>
                    <div className="purchaseDiv">
                        {
                            DataStatus? <LoadingText/>
                                :<BootstrapTable selectRow={selectRow}  keyField='Id' data={DataCoEffeciant} columns={ColumnCoEfficient}></BootstrapTable>


                        }
                    </div>
                </Row>
            </Container>
            <Modal animation={false} className="animated zoomIn" show={ShowFT} onHide={handleCloseFT}>
                <Modal.Header>
                    <div >
                        <label className="text-primary font-weight-bold fs-5 text-center">File Movement</label>

                    </div>

                </Modal.Header>
                <Modal.Body>

                    <label className="form-label text-danger">Nothi ID:-{NothiId}</label>
                    <label className="form-label float-end text-danger">Nothi No:-{NothiNo}</label>

                    <hr/>
                    <label className="form-label text-danger">Nothi Heading: </label>
                    <label>{NothiHeading}</label>
                    <hr/>
                    <label className="form-label text-danger">Section</label>
                    <select  className="w-25 " id="cmbNothiSent">
                        <option>Select</option>
                        <option>Commissioner</option>
                        <option>ADC</option>
                        <option>JC</option>
                        <option>DC</option>
                        <option>AC</option>
                        <option>RO</option>
                        <option>ARO</option>
                        <option>Section</option>


                    </select>
                    <label className="form-label text-danger">Name :-</label>
                    <select  className="w-25 " id="cmbNothiSentTo">
                        <option>Select</option>
                        {
                            state.officers.map((ctr,index)=>{
                                return (
                                    <option key={index} value={ctr.Id}>{ctr.Name}</option>
                                )
                            })

                        }

                    </select>
                    <hr/>
                    <label className="form-label text-danger">File Sent Date</label>
                    <DatePicker selected={DateMovement} onChange={(date) => setDateMovement(date)} dateFormat='dd-MM-yyyy' seletable maxDate={new Date()} isClearable showYearDropdown scrollableMonthYearDropdown  />


                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn btn-sm btn-site" variant="danger" onClick={handleCloseFT}>
                        Close
                    </Button>
                    <button className="" onClick={moveFile} >
                        Save
                    </button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
}
