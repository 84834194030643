import {Fragment, useState} from "react";
import {Button, Col, Modal, Row} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
import cogoToast from "cogo-toast";
import LoadingText from "../Loading/LoadingText";
import DatePicker from "react-datepicker";
import appUtility from "../../RestAPI/AppUtility";
import {useSelector} from "react-redux";


export default function UnRegisteredBin(){
    let state=useSelector((state)=> state);
    const [UregisteredCircle, setUregisteredCircle]=useState([]);
    const [UregisteredModelCircle, setUregisteredModelCircle]=useState([]);
    const [SurveyDate, setSurveyDate] = useState(new Date());
    const [SurveyUpdatedDate, setSurveyUpdatedDate] = useState(new Date());
    const [DataCoEffeciant, setDataCoEffeciant]=useState([]);
    const [ColumnCoEfficient, setColumnCoEfficient]=useState([{}]);
    const [DataLength, setDatalength]=useState(0)
    const [show, setShow] = useState(false);
    const [showUpdate, setShowUpdate] = useState(false);

    const [Jewellery_Id, setJewellery_Id] = useState();
    const [Jewellery_Name, setJewellery_Name] = useState();

    const[DataStatus, setDataStatus]=useState(false)
    const handleCloseJewellery = () => setShowUpdate(false);
    const handleShowJewellery = () => setShowUpdate(true);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const selectRow={
        mode:'radio',
        onSelect:(row,isSelect,rowIndex)=>{
            setJewellery_Id(row['Id']);
            setJewellery_Name(row['Name']);

            handleShowJewellery();

        }
    }

    const getRefundApplication=()=>{
        setDataStatus(true)

        const SurveySearchDivision=document.getElementById("cmbSurveySearchDivision");
        const SurveySearchDivisionValue=SurveySearchDivision.options[SurveySearchDivision.selectedIndex].text;
        const SurveySearchCircle=document.getElementById("cmbSurveySearchCircle");
        const SurveySearchCircleValue=SurveySearchCircle.options[SurveySearchCircle.selectedIndex].text;
        const SurveySearchCategory=document.getElementById("cmbSurveySearchCategory");
        const SurveySearchCategoryValue=SurveySearchCategory.options[SurveySearchCategory.selectedIndex].text;
        const SurveySearchStatus=document.getElementById("cmbSurveySearchStatus");
        const SurveySearchStatusValue=SurveySearchStatus.options[SurveySearchStatus.selectedIndex].text;


        let getSurveyDivision=" ";
        let getSurveyCircle=" ";
        let getSurveyCategory=" ";
        let getSurveySearchStatus=" ";
        if(SurveySearchDivision.selectedIndex !=0){getSurveyDivision=" And Division Like '%"+SurveySearchDivisionValue+"%'"}
        if(SurveySearchCircle.selectedIndex !=0){getSurveyCircle=" And Circle Like '%"+SurveySearchCircleValue+"%'"}
        if(SurveySearchCategory.selectedIndex !=0){getSurveyCategory=" And Category Like '%"+SurveySearchCategoryValue+"%'"}
        if(SurveySearchStatus.selectedIndex !=0){getSurveySearchStatus=" And Status ='"+SurveySearchStatusValue+"'"}


        let jsonObject={"sql":" select * from survey_bin where 1=1 "+getSurveyDivision+" "+getSurveyCircle+" "+getSurveyCategory+" "+getSurveySearchStatus+" "}
        setColumnCoEfficient([{}]);setDataCoEffeciant([])
        RestClient.PostRequest(AppUrl.getData,jsonObject).then(async result=>{
            setDatalength(result.length)
            if(result==""){cogoToast.error("Data not found");setDataStatus(false)}else {
                await setColumnCoEfficient(
                    Object.keys(result[0]).map((k) => {
                        return {dataField: k, text: k, sort: true}
                    })
                )
                await setDataCoEffeciant(result);
                setDataStatus(false)
            }
        }).catch(error=>{});
    }


    const saveSurveyData=()=>{
            const SurveyName=document.getElementById("txtSurveyName").value;
            const SurveyMobile=document.getElementById("txtSurveyMobile").value;
            const SurveyAddress=document.getElementById("txtSurveyAddress").value;
            const SurveyComments=document.getElementById("txtSurveyComments").value;
        const SurveyCategory=document.getElementById("cmbSurveyCategory");
        const SurveyCategoryValue=SurveyCategory.options[SurveyCategory.selectedIndex].text;
        const SurveyDivision=document.getElementById("cmbSurveyDivision");
        const SurveyDivisionValue=SurveyDivision.options[SurveyDivision.selectedIndex].text;
        const SurveyCircle=document.getElementById("cmbSurveyCircle");
        const SurveyCircleValue=SurveyCircle.options[SurveyCircle.selectedIndex].text;

        const SurveyDateValue=appUtility.getDate(SurveyDate);
            if(SurveyName==""){cogoToast.error("Please insert Name")}else{
                if(SurveyMobile==""){cogoToast.error("Please insert Mobile")}else{
                    if(SurveyAddress==""){cogoToast.error("Please insert Address")}else{
                        if(SurveyComments==""){cogoToast.error("Please insert Comments")}else{
                            if(SurveyDivision.selectedIndex==0){cogoToast.error("Please Select Division")}else{
                            if(SurveyCircle.selectedIndex==0){cogoToast.error("Please Select Circle")}else{
                                if(SurveyCategory.selectedIndex==0){cogoToast.error("Please Select Category")}else{
                                    let jsonObject={"sql":"Insert into survey_bin(Id,Name,Mobile,Address,Division,Circle,Category,Comments,Survey_Date)" +
                                            "values(NULL,'"+SurveyName+"','"+SurveyMobile+"','"+SurveyAddress+"','"+SurveyDivisionValue+"','"+SurveyCircleValue+"','"+SurveyCategoryValue+"','"+SurveyComments+"','"+SurveyDateValue+"')"}

                                    RestClient.PostRequest(AppUrl.saveData, jsonObject).then(result => {
                                        if (result == 1) {
                                            cogoToast.success("Survey Data saved")

                                        } else {
                                            cogoToast.error("Survey Data not saved")
                                        }
                                    }).catch(error => {
                                        cogoToast.error(error)
                                    });
                                }
                            }
                        }}
                    }
                }
            }






    }
    const updateSurveyData=()=>{

        const BINUpdate=document.getElementById("txtUpdatedBin").value;

         let jsonObject={"sql":"Update survey_bin set BIN='"+BINUpdate+"' , Status='Yes', Survey_Date='"+appUtility.getDate(SurveyUpdatedDate)+"'  where Id='"+Jewellery_Id+"' "}

       if(BINUpdate==""){cogoToast.error("Please insert BIN")}else{
           RestClient.PostRequest(AppUrl.updateData, jsonObject).then(result => {
               if (result == 1) {
                   cogoToast.success("Data Updated")

               } else {
                   cogoToast.error("Data not Updated")
               }
           }).catch(error => {
               cogoToast.error(error)
           });
       }




    }
    return(
        <Fragment>
            <div >
                <Row className="mt-2">

                    <Col lg={3}>
                        <div className="">
                            <Row className="mt-2">
                                <Col lg={3}><label className="text-danger fw-bold">Division</label></Col>
                                <Col lg={9}>
                                    <select className="float-start w-100 customfontsize" id="cmbSurveySearchDivision"
                                            onChange={()=>{
                                                setUregisteredCircle([])
                                                const e = document.getElementById("cmbSurveySearchDivision");
                                                const value = e.options[e.selectedIndex].value;
                                                const dt=state.circle.filter(x => x.Division_Id==value);
                                                setUregisteredCircle(dt)


                                            }}
                                    >
                                        <option >All Division</option>
                                        {
                                            state.division.map((ctr,index)=>{
                                                return (
                                                    <option key={index} value={ctr.Id}>{ctr.Name}</option>
                                                )
                                            })

                                        }
                                    </select>
                                </Col>

                            </Row>
                            <Row className="mt-2">
                                <Col lg={3}><label className="text-danger fw-bold">Circle</label></Col>
                                <Col lg={9}>
                                    <select className="float-start w-100 customfontsize" id="cmbSurveySearchCircle"  >
                                        <option >All Circle</option>
                                        {
                                            UregisteredCircle.map((ctr,index)=>{
                                                return (
                                                    <option key={index} value={ctr.Id}>{ctr.Name}</option>
                                                )
                                            })

                                        }
                                    </select>
                                </Col>

                            </Row>
                            <Row className="mt-2">
                                <Col lg={3}><label className="text-danger fw-bold">Category</label></Col>
                                <Col lg={9}>
                                    <select className="float-start w-100 customfontsize" id="cmbSurveySearchCategory"  >
                                        <option>All Category</option>
                                        {
                                            state.category.map((ctr,index)=>{
                                                return (
                                                    <option key={index} value={ctr.Id}>{ctr.Name}</option>
                                                )
                                            })

                                        }
                                    </select>
                                </Col>

                            </Row>
                            <Row className="mt-2">
                                <Col lg={3}><label className="text-danger fw-bold">Status</label></Col>
                                <Col lg={9}>
                                    <select className="float-start w-100 customfontsize" id="cmbSurveySearchStatus"  >
                                        <option>All</option>
                                        <option>Yes</option>
                                        <option>No</option>

                                    </select>
                                </Col>

                            </Row>

                            <Row className="mt-2">
                                <Col lg={3}>

                                </Col>
                                <Col lg={9}>
                                    <button className="w-100 text-primary font-weight-bold" onClick={getRefundApplication} >Search</button>
                                </Col>


                            </Row>
                            <Row className="m-4">
                                <div className="mt-4">
                                    <button className="text-danger w-100" onClick={handleShow}>New BIN Entry                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                </button>
                                </div>
                            </Row>






                        </div>
                    </Col>

                    <Col lg={9}>
                        <div className="overflow-scroll animated zoomIn purchaseSmallDiv">

                            {
                                DataStatus?<LoadingText/>
                                    : <BootstrapTable   selectRow={selectRow}  keyField='Id' data={DataCoEffeciant} columns={ColumnCoEfficient}></BootstrapTable>

                            }
                        </div>
                        <label>Data found: {DataLength}</label>
                    </Col>

                </Row>

            </div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title >
                        <label className="text-danger">New BIn Entry</label>

                    </Modal.Title>


                </Modal.Header>
                <Modal.Body>
                    <div className="flex-column">

                        <label className="p-1 text-danger">Name :-</label><input id="txtSurveyName" className="w-75" type="text"/>
                        <hr/>
                        <label className="p-1 text-danger">Mobile :-</label><input id="txtSurveyMobile" className="w-75"  type="text"/>
                        <hr/>
                        <label className="text-danger ">Address :-</label>
                        <textarea id="txtSurveyAddress" className="w-75"></textarea>
                        <hr/>
                        <label className="text-danger">Comments :-</label>
                        <textarea className="w-75" id="txtSurveyComments"></textarea>
                        <hr/>
                        <label className="p-1 text-danger">Division:</label>
                        <select className=" w-75 customfontsize" id="cmbSurveyDivision"
                                onChange={()=>{
                                    setUregisteredModelCircle([])
                                    const e = document.getElementById("cmbSurveyDivision");
                                    const value = e.options[e.selectedIndex].value;
                                    const dt=state.circle.filter(x => x.Division_Id==value);
                                    setUregisteredModelCircle(dt)


                                }}
                        >
                            <option >All Division</option>
                            {
                                state.division.map((ctr,index)=>{
                                    return (
                                        <option key={index} value={ctr.Id}>{ctr.Name}</option>
                                    )
                                })

                            }
                        </select>

                        <hr/>
                        <label className="p-1 text-danger">Circle:</label>
                        <select className=" w-75 customfontsize" id="cmbSurveyCircle"  >
                            <option >All Circle</option>
                            {
                                UregisteredModelCircle.map((ctr,index)=>{
                                    return (
                                        <option key={index} value={ctr.Id}>{ctr.Name}</option>
                                    )
                                })

                            }
                        </select>

                        <hr/>

                        <label className="p-1 text-danger">Category:-</label>
                        <select className=" w-75 customfontsize" id="cmbSurveyCategory"  >
                            <option value="0">All Items</option>
                            {
                                state.category.map((ctr,index)=>{
                                    return (
                                        <option key={index} value={ctr.Id}>{ctr.Name}</option>
                                    )
                                })

                            }
                        </select>
                        <hr/>
                        <label className="text-danger">Survey Date:</label>
                        <DatePicker selected={SurveyDate} onChange={(date) => setSurveyDate(date)} dateFormat='dd-MM-yyyy' seletable  isClearable showYearDropdown scrollableMonthYearDropdown  />


                    </div>



                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={saveSurveyData} >
                        Save
                    </Button>
                    <Button variant="danger" onClick={handleClose}>
                        Close
                    </Button>

                </Modal.Footer>
            </Modal>
            <Modal show={showUpdate} onHide={handleCloseJewellery}>
                <Modal.Header closeButton>
                    <Modal.Title >
                        <label className="text-danger">Update BIN_</label>
                        <label className="text-danger">_ID:_</label>
                        <label className="text-danger">{Jewellery_Id}</label>

                    </Modal.Title>


                </Modal.Header>
                <Modal.Body>
                    <div className="flex-column">

                        <label className="p-1 text-danger">Name :-</label><label>{Jewellery_Name}</label>
                        <hr/>

                        <label className="text-danger">BIN :-</label>
                        <input type="text" className="w-75" id="txtUpdatedBin"/>

                        <hr/>
                        <label className="text-danger">Survey Date:</label>
                        <DatePicker selected={SurveyUpdatedDate} onChange={(date) => setSurveyUpdatedDate(date)} dateFormat='dd-MM-yyyy' seletable  isClearable showYearDropdown scrollableMonthYearDropdown  />


                    </div>



                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={updateSurveyData} >
                        Save
                    </Button>
                    <Button variant="danger" onClick={handleCloseJewellery}>
                        Close
                    </Button>


                </Modal.Footer>
            </Modal>
        </Fragment>

    )
}
