import {Fragment} from "react";
import TopNavigation from "../components/TopNavigation/TopNavigation";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import OfficerEntry from "../components/ET/OfficerEntry";
import NewFile from "../components/ET/NewFile";
import ET_Dashboard from "../components/ET/ET_Dashboard";
export default function ET_Page(){



    return(
        <Fragment>
            <TopNavigation/>
            <div className="purchaseDiv text-center mergin_top">

                <Tabs
                    defaultActiveKey="home"
                    transition={false}
                    id="noanim-tab-example"
                    className="mb-3"
                >
                    <Tab eventKey="home" title="Dashboard"> <ET_Dashboard/></Tab>
                    <Tab eventKey="file" title="File"><NewFile/></Tab>


                </Tabs>
            </div>
        </Fragment>
    )
}
