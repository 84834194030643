import {createStore} from "redux";


let counterReducer=(state={name:"", access:"", accessDiv:"", accessCom:"", accessCir:"",posting:"",designation:"",connection:0,bin:[],mamla_date:[],all_mamla:[],internal_mamla:[],commi:[],division:[],circle:[],c_balance:[],officers:[],category:[] }, action)=>{


    if(action.type==="name"){ return { ...state,name:action.payload}}
    if(action.type==="accessCom"){ return { ...state,accessCom:action.payload}}
    if(action.type==="accessDiv"){ return { ...state,accessDiv:action.payload}}
    if(action.type==="accessCir"){ return { ...state,accessCir:action.payload}}
    if(action.type==="access"){ return { ...state,access:action.payload}}
    if(action.type==="posting"){return { ...state,posting:action.payload } }
    if(action.type==="designation"){return { ...state,designation:action.payload } }
    if(action.type==="connection"){return { ...state,connection:action.payload } }
    if(action.type==="bin"){return { ...state,bin:action.payload } }
    if(action.type==="mamla_date"){return { ...state,mamla_date:action.payload } }
    if(action.type==="all_mamla"){return { ...state,all_mamla:action.payload } }
    if(action.type==="internal_mamla"){return { ...state,internal_mamla:action.payload } }
    if(action.type==="commi"){return { ...state,commi:action.payload } }
    if(action.type==="division"){return { ...state,division:action.payload } }
    if(action.type==="circle"){return { ...state,circle:action.payload } }
    if(action.type==="c_balance"){return { ...state,c_balance:action.payload } }
    if(action.type==="officers"){return { ...state,officers:action.payload } }
    if(action.type==="category"){return { ...state,category:action.payload } }


    return state;
}
let store =createStore(counterReducer);
export default store;
