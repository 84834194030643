import {Fragment, useState} from "react";
import {Button, Col, Modal, Row} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
import cogoToast from "cogo-toast";
import Loading from "../Loading/Loading";
import LoadingText from "../Loading/LoadingText";
import {useSelector} from "react-redux";
import appUtility from "../../RestAPI/AppUtility";
import {dateFilter} from "react-bootstrap-table2-filter";


export default function Report(){
    let state=useSelector((state)=> state);
    const [Division, setDivision]=useState([]);
    const [Circle, setCircle]=useState([]);
    const [DataCoEffeciant, setDataCoEffeciant]=useState([]);
    const [ColumnCoEfficient, setColumnCoEfficient]=useState([{}]);
    const [DataLength, setDatalength]=useState(0)
    const[ID, setId]=useState()
    const[Bin, setBin]=useState()
    const[Name, setName]=useState()
    const[Mobile, setMobile]=useState()
    const[History, setHistory]=useState([]);
    const[DataStatus, setDataStatus]=useState(false);
    const [showHistory, setShowHistory] = useState(false);
    const handleCloseHistory = () => setShowHistory(false);
    const handleShowHistory = () =>setShowHistory(true);
    const [showEntry, setShowEntry] = useState(false);
    const handleCloseEntry = () => setShowEntry(false);
    const handleShowEntry = () => setShowEntry(true);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [showSMS, setShowSMS] = useState(false);
    const handleCloseSMS = () => setShowSMS(false);
    const handleShowSMS = () => setShowSMS(true);

    const selectRow={
        mode:'radio',
        onSelect:(row,isSelect,rowIndex)=>{
            setId(row['Id']);
            setBin(row['BIN']);
            setName(row['Name']);
            setMobile(row['Mobile']);

            handleShow();
        }
    }
    const getBIN=()=>{
        setDataStatus(true);
        setDatalength(0)
        const Category=document.getElementById("cmbBinCategory");
        const CategoryValue=Category.options[Category.selectedIndex].text;

        const Commi=document.getElementById("cmbBinCommi");
        const CommiValue=Commi.options[Commi.selectedIndex].text;

        const Division=document.getElementById("cmbBinDivision");
        const DivisionValue=Division.options[Division.selectedIndex].text;


        const Circle=document.getElementById("cmbBinCircle");
        const CircleValue=Circle.options[Circle.selectedIndex].text;

        const Range=document.getElementById("cmbRange");
        const RangeValue=Range.options[Range.selectedIndex].text;

        const Items=document.getElementById("cmbItems");
        const ItemsValue=Items.options[Items.selectedIndex].text;
        const SmArea=document.getElementById("txtSmArea").value;

        const DataLimit=document.getElementById("cmbDataLimit");
        const DataLimitValue=DataLimit.options[DataLimit.selectedIndex].text;
        const SearchBin=document.getElementById("txtSearchBin").value;
        const SearchName=document.getElementById("txtSearchName").value;
        const SearchMobile=document.getElementById("txtSearchMobile").value;


        let getCommi=" ";
        let getDivision=" ";
        let getCircle=" ";
        let getCategory=" ";
        let getItems="";
        let getFloorSpace;
        let getlimit ;
        let getBin="";
        let getName="";
        let getMobile="";
        let getSmArea=""
        let jsonObject;
        let getRange="";
        if(state.accessCom=="NBR")
        {if(Commi.selectedIndex!=0){getCommi="And Commissionerate Like '%"+CommiValue+"%'"}}
        else{getCommi="And Commissionerate Like '%"+state.accessCom+"%'"}

        if(state.accessDiv=="All Division"){if(Division.selectedIndex!=0){getDivision="And Division Like '%"+DivisionValue+"%'"}}
        else{getDivision="And Division Like '%"+state.accessDiv+"%'"}

        if(state.accessCir=="All Circle"){if(Circle.selectedIndex!=0){getCircle="And Circle Like '%"+CircleValue+"%'"}}
        else {getCircle="And Circle Like '%"+state.accessCir+"%'"}

        if(Range.selectedIndex!=0){getRange="And Range_Name ='"+RangeValue+"'"}



        if(Category.selectedIndex!=0){getCategory="And Economic_Activity Like '%"+CategoryValue+"%' OR Manufacturing_Area Like '%"+CategoryValue+"%' OR Service_Area Like '%"+CategoryValue+"%' "}
        if(SmArea!=""){getCategory="And Economic_Activity Like '%"+SmArea+"%' OR Service_Area Like '%"+SmArea+"%' OR Manufacturing_Area Like '%"+SmArea+"%' "}
        if(Items.selectedIndex!=0){getItems="And Category= '"+ItemsValue+"'"}

        if(SearchBin!=""){ getBin=" And BIN Like '%"+SearchBin+"%'"}
        if(SearchName!=""){ getName=" And Name Like '%"+SearchName+"%'"}
        if(SearchMobile!=""){ getMobile=" And Mobile Like '%"+SearchMobile+"%'"}

        if(DataLimit.selectedIndex!=0){getlimit=" LIMIT " +DataLimitValue
            jsonObject={"sql":" Select * from Bin where 1=1 "+getCommi+" "+getDivision+" "+getCircle+" "+getCategory+" "+getItems+"  "+getBin+" "+getName+" "+getSmArea+" "+getMobile+" "+getRange+" "+getlimit+" "}

        }else{getlimit=""
            jsonObject={"sql":" Select count(BIN)AS 'Total BIN' from Bin where 1=1 "+getCommi+" "+getDivision+" "+getCircle+" "+getCategory+" "+getItems+"  "+getBin+" "+getName+" "+getSmArea+" "+getMobile+" "+getRange+" "+getlimit+" "}

        }

        setColumnCoEfficient([{}]);setDataCoEffeciant([])
        RestClient.PostRequest(AppUrl.getData,jsonObject).then(async result=>{
           setDatalength(result.length)
            if(result==""){cogoToast.error("Data not found");setDataStatus(false)}else {
                await setColumnCoEfficient(
                    Object.keys(result[0]).map((k) => {
                        return {dataField: k, text: k, sort: true}
                    })
                )
                await setDataCoEffeciant(result);
                    setDataStatus(false)
            }
        }).catch(error=>{ setDataStatus(false);});
    }
    const updateCategory=()=>{

        const ItemModel=document.getElementById("cmbItemsModel");
        const ItemModelValue=ItemModel.options[ItemModel.selectedIndex].text;

        if(ItemModel.selectedIndex==0){cogoToast.error("Please select Item Box")}else {
            RestClient.PostRequest(AppUrl.saveData, {"sql": "Update bin set Category='"+ItemModelValue+"' where Bin='"+Bin+"' "}).then(result => {
                if (result == 1) {
                    cogoToast.success("Data saved")

                } else {
                    cogoToast.error("Data not saved")
                }
            }).catch(error => {
                cogoToast.error(error)
            });
        }

    }
    const updateRange=()=>{

        const RangeNo=document.getElementById("cmbRangeOption");
        const RangeNoValue=RangeNo.options[RangeNo.selectedIndex].text;

        if(RangeNo.selectedIndex==0){cogoToast.error("Please select Range")}else {
            RestClient.PostRequest(AppUrl.updateData, {"sql": "Update bin set Range_Name='"+RangeNoValue+"' where Bin='"+Bin+"' "}).then(result => {
                if (result == 1) {
                    cogoToast.success("Range saved")

                } else {
                    cogoToast.error("Range not saved")
                }
            }).catch(error => {
                cogoToast.error(error)
            });
        }

    }
    const saveFloorSpaceRevenue=()=>{
        RestClient.PostRequest(AppUrl.saveData, {"sql": "Insert into floor_space_24_25 (Id, BIN)Values(NULL, '"+Bin+"') "}).then(result => {
            if (result == 1) {
                cogoToast.success("Revenue Data saved")

            } else {
                cogoToast.error("Data not saved")
            }
        }).catch(error => {
            cogoToast.error(error)
        });

    }
    const saveHistory=()=>{

        const history=document.getElementById("txtAreaDetailes").value;

        if(history==""){cogoToast.error("Please insert")}else{
        RestClient.PostRequest(AppUrl.saveData, {"sql": "Insert into bin_detailes (Id, BIN,Detailes,Entry_date,Entry_User)Values(NULL, '"+Bin+"','"+history+"','"+appUtility.getDate(new Date())+"','"+state.name+"') "}).then(result => {
            if (result == 1) {
                cogoToast.success("Data saved")

            } else {
                cogoToast.error("Data not saved")
            }
        }).catch(error => {
            cogoToast.error(error)
        });}

    }
    const getHistory=()=>{

       let jsonObject={"sql":" SELECT Id,Detailes FROM bin_detailes where BIN='"+Bin+"'  "};

        RestClient.PostRequest(AppUrl.getData,jsonObject).then(async result=>{

            if(result==""){cogoToast.error("Data not found");}else {
                  setHistory(result);setShowHistory(true);

            }
        }).catch(error=>{ });

    }
    const UpdateFloorSpace=()=>{


            RestClient.PostRequest(AppUrl.saveData, {"sql": "Update bin set Floor_Space='Yes' where Bin='"+Bin+"' "}).then(result => {
                if (result == 1) {
                    cogoToast.success("Data saved")
                    saveFloorSpaceRevenue();
                } else {
                    cogoToast.error("Data not saved")
                }
            }).catch(error => {
                cogoToast.error(error)
            });
        }
    const UpdateEFD=()=>{


        RestClient.PostRequest(AppUrl.updateData, {"sql": "Update bin set EFD='Yes' where Bin='"+Bin+"' "}).then(result => {
            if (result == 1) {
                cogoToast.success("EFD saved")

            } else {
                cogoToast.error("EFD not saved")
            }
        }).catch(error => {
            cogoToast.error(error)
        });
    }
    const UpdateAudit=()=>{



        RestClient.PostRequest(AppUrl.saveData, {"sql": "Update bin set Audit='Yes' where Bin='"+Bin+"' "}).then(result => {
            if (result == 1) {
                cogoToast.success("Audit saved")
            } else {
                cogoToast.error("Audit not saved")
            }
        }).catch(error => {
            cogoToast.error(error)
        });
    }
    return(
        <Fragment>
            <div >
                <Row className="mt-2">

                    <Col lg={3}>
                        <div className="">
                            <Row className="mt-2">
                                <Col lg={3} xs={4}><label className="text-danger fw-bold">NBR</label></Col>
                                <Col lg={9}  xs={8}>
                                    <select className="float-start w-100 customfontsize" id="cmbBinCommi"
                                            onChange={()=>{
                                                setDivision([]);
                                                setCircle([]);
                                                const e = document.getElementById("cmbBinCommi");
                                                const value = e.options[e.selectedIndex].value;
                                                const dt=state.division.filter(x => x.Commissionerate_Id==value);
                                                setDivision(dt)


                                            }}
                                    >
                                        <option >NBR</option>
                                        {
                                            state.commi.map((ctr,index)=>{
                                                return (
                                                    <option key={index} value={ctr.Id}>{ctr.Name}</option>
                                                )
                                            })

                                        }
                                    </select>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col lg={3} xs={4}><label className="text-danger fw-bold">Division</label></Col>
                                <Col lg={9} xs={8}>
                                <select className="float-start w-100 customfontsize" id="cmbBinDivision"
                                        onChange={()=>{
                                            setCircle([])
                                            const e = document.getElementById("cmbBinDivision");
                                            const value = e.options[e.selectedIndex].value;
                                            const dt=state.circle.filter(x => x.Division_Id==value);
                                            setCircle(dt)


                                        }}
                                >
                                    <option >All Division</option>

                                    {
                                        Division.map((ctr,index)=>{
                                            return (
                                                <option key={index} value={ctr.Id}>{ctr.Name}</option>
                                            )
                                        })

                                    }
                                </select>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col lg={3} xs={4}><label className="text-danger fw-bold">Circle</label></Col>
                                <Col lg={9} xs={8}>
                                <select className="float-start w-100 customfontsize" id="cmbBinCircle" >
                                    <option >All Circle</option>

                                    {
                                        Circle.map((ctr,index)=>{
                                            return (
                                                <option key={index} value={ctr.Id}>{ctr.Name}</option>
                                            )
                                        })

                                    }


                                </select>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col lg={3} xs={4}><label className="text-danger fw-bold">Range</label></Col>
                                <Col lg={9} xs={8}>
                                    <select className="float-start w-100 customfontsize" id="cmbRange"  >
                                        <option >All Range</option>
                                        <option >Range-1</option>
                                        <option >Range-2</option>
                                        <option >Range-3</option>
                                        <option >Range-4</option>
                                        <option >Range-5</option>
                                        <option >Range-6</option>
                                        <option >Range-7</option>
                                        <option >Range-8</option>
                                    </select>
                                </Col>

                            </Row>
                            <Row className="mt-2">
                                <Col lg={3} xs={4}><label className="text-danger fw-bold">Category</label></Col>
                                <Col lg={9} xs={8}>
                                    <select className="float-start w-100 customfontsize" id="cmbBinCategory"  >
                                        <option >All Category</option>
                                        <option >Manufacturing</option>
                                        <option >Services</option>
                                        <option >Other</option>
                                        <option >Imports</option>
                                        <option >Exports</option>
                                        <option >Construction</option>
                                        <option >Wholesale</option>
                                    </select>
                                </Col>

                            </Row>
                            <Row className="mt-2">
                                <Col lg={3} xs={4}><label className="text-danger fw-bold">S/M Area</label></Col>
                                <Col lg={9} xs={8}>
                                    <input type="text" className="w-100" id="txtSmArea"/>
                                </Col>

                            </Row>
                            <Row className="mt-2">
                                <Col lg={3}xs={4}><label className="text-danger fw-bold">Items</label></Col>
                                <Col lg={9} xs={8}>
                                    <select className="float-start w-100 customfontsize" id="cmbItems"  >
                                        <option value="0">All Items</option>
                                        {
                                            state.category.map((ctr,index)=>{
                                                return (
                                                    <option key={index} value={ctr.Id}>{ctr.Name}</option>
                                                )
                                            })

                                        }

                                    </select>
                                </Col>

                            </Row>
                            <Row className="mt-2">
                                <Col lg={3}xs={4}><label className="text-danger fw-bold">BIN</label></Col>
                                <Col lg={9}xs={8}>
                                    <input type="text" className="w-100" id="txtSearchBin"/>
                                </Col>

                            </Row>
                            <Row className="mt-2">
                                <Col lg={3}xs={4}><label className="text-danger fw-bold">Name</label></Col>
                                <Col lg={9}xs={8}>
                                    <input type="text" className="w-100" id="txtSearchName"/>
                                </Col>

                            </Row>
                            <Row className="mt-2">
                                <Col lg={3}xs={4}><label className="text-danger fw-bold">Mobile</label></Col>
                                <Col lg={9}xs={8}>
                                    <input type="text" className="w-100" id="txtSearchMobile"/>
                                </Col>

                            </Row>
                            <Row className="mt-2">
                                <Col lg={3}xs={4}><label className="text-danger fw-bold">Data Limit</label></Col>
                                <Col lg={9}xs={8}>
                                    <select defaultValue={100} className="float-start w-100 customfontsize" id="cmbDataLimit"  >
                                        <option >All</option>
                                        <option >100</option>
                                        <option >200</option>
                                        <option >500</option>
                                        <option >1000</option>
                                        <option >2000</option>
                                        <option >3000</option>
                                        <option >5000</option>
                                        <option >10000</option>
                                        <option >20000</option>
                                        <option >50000</option>


                                    </select>
                                </Col>

                            </Row>
                            <Row className="mt-4">
                                <Col lg={3} >

                                </Col>
                                <Col lg={9} >
                                    <button className="w-100 text-danger" onClick={getBIN}>Search</button>
                                </Col>


                            </Row>



                        </div>
                    </Col>

                    <Col lg={9}>
                        <div className="overflow-scroll animated zoomIn purchaseMediumDiv">
                            {
                               DataStatus? <LoadingText/>
                               :<BootstrapTable selectRow={selectRow}  keyField='Id' data={DataCoEffeciant} columns={ColumnCoEfficient}></BootstrapTable>


                            }
                              </div>

                    </Col>
                    <label className="text-danger">Data found: {DataLength}</label>
                </Row>

            </div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{Name}</Modal.Title>


                </Modal.Header>
                <Modal.Body>
                    <div className="flex-column">

                        <select className="float-start me-2 w-50 customfontsize" id="cmbItemsModel"  >
                            <option value="0">All Items</option>
                            {
                                state.category.map((ctr,index)=>{
                                    return (
                                        <option key={index} value={ctr.Id}>{ctr.Name}</option>
                                    )
                                })

                            }
                        </select>

                        <Button variant="primary" onClick={updateCategory}>
                            Save
                        </Button>
                        <hr/>
                        <select className="float-start me-2 w-50 customfontsize" id="cmbRangeOption"  >
                            <option value="0">All Range</option>
                            <option value="0">Range-1</option>
                            <option value="0">Range-2</option>
                            <option value="0">Range-3</option>
                            <option value="0">Range-4</option>
                            <option value="0">Range-5</option>
                            <option value="0">Range-6</option>
                            <option value="0">Range-7</option>
                        </select>

                        <Button variant="primary" onClick={updateRange}>
                            Save
                        </Button>
                    </div>
                    <hr/>
                    <div className="w-75">

                         <Button className="" variant="success" onClick={UpdateFloorSpace} >
                             Want to add Floor Space ? Yes
                        </Button>
                    </div>

                    <hr/>
                    <div className="w-75">

                        <Button className="" variant="success" onClick={UpdateAudit} >
                            Want to add Audit ? Yes
                        </Button>
                    </div>
                        <hr/>
                    <div className="w-75">

                        <Button className="" variant="success" onClick={UpdateEFD} >
                            Want to add EFD ? Yes
                        </Button>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={getHistory}>
                        Show History
                    </Button>
                    <Button variant="danger" onClick={handleShowEntry}>
                        Entry History
                    </Button>
                    <Button className="float-end" variant="success" onClick={()=>{
                    handleShowSMS();handleClose();
                    }
                    }>
                        Send sms
                    </Button>

                    <Button variant="danger" onClick={handleClose}>
                        Close
                    </Button>



                </Modal.Footer>
            </Modal>





            <Modal show={showSMS} onHide={handleCloseSMS}>
                <Modal.Header closeButton>
                    <Modal.Title> <label className="text-primary">New SMS send</label> </Modal.Title>

                </Modal.Header>
                <Modal.Body>
                    <label>{Name}</label>
                    <hr/>
                    <label className="text-danger">Mobile No :</label>
                    <input type="text" defaultValue={Mobile} id="txtSmsNumber"/>
                    <hr/>

                    <textarea id="txtSms" placeholder="Please write your sms here. Maximum 120 letters" className="w-100 h-50"></textarea>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="float-end" variant="success" onClick={()=>{
                        const sms=document.getElementById("txtSms").value;
                        const smsNumber=document.getElementById("txtSmsNumber").value;
                        if(smsNumber==""){cogoToast.error("Please insert send Number")}else{
                            if(sms==""){cogoToast.error("Please write sms in the box")}else{
                                appUtility.sendSMS(sms,smsNumber);
                            }
                        }

                    }
                    } >
                        Send sms
                    </Button>

                    <Button variant="danger" onClick={handleCloseSMS}>
                        Close
                    </Button>




                </Modal.Footer>


            </Modal>
            <Modal show={showEntry} onHide={handleCloseEntry}>
                <Modal.Header closeButton>

                    <label>History entry form</label>

                </Modal.Header>
                <Modal.Body>
                <textarea className="text-area" id="txtAreaDetailes"></textarea>
                </Modal.Body>
                <Modal.Footer>

                    <Button variant="danger" onClick={handleCloseEntry}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={saveHistory} >
                        Save
                    </Button>

                </Modal.Footer>
            </Modal>
            <Modal show={showHistory} onHide={handleCloseHistory}>
                <Modal.Header closeButton>

                    <label>Detailes History</label>

                </Modal.Header>
                <Modal.Body>
                    {
                        History.map((ctr,index)=>{
                            return (
                              <div> <label>{ctr.Detailes}</label>
                                <hr/>
                              </div>
                            )
                        })
                    }

                </Modal.Body>
                <Modal.Footer>

                    <Button variant="danger" onClick={handleCloseHistory}>
                        Close
                    </Button>


                </Modal.Footer>
            </Modal>
        </Fragment>

    )
}
