import {Fragment, useState} from "react";
import {Button, Col, Modal, Row} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
import cogoToast from "cogo-toast";
import LoadingText from "../Loading/LoadingText";
import DatePicker from "react-datepicker";
import appUtility from "../../RestAPI/AppUtility";
import {useSelector} from "react-redux";


export default function Refund(){
    let state=useSelector((state)=> state);
    const [RefundDivision, setRefundDivision]=useState([]);
    const [RefundCircle, setRefundCircle]=useState([]);
    const [ApplicationDate, setApplicationDate] = useState(new Date());
    const [DataCoEffeciant, setDataCoEffeciant]=useState([]);
    const [ColumnCoEfficient, setColumnCoEfficient]=useState([{}]);
    const [DataLength, setDatalength]=useState(0)
    const [show, setShow] = useState(false);
    const [Vdsshow, setVdsShow] = useState(false);
    const [Vds_Id, setVds_Id] = useState();
    const [Vds_Name, setVds_Name] = useState();
    const[BIN, setBin]=useState();
    const[Name, setName]=useState();
    const[Address, setAddress]=useState();
    const[Division, setDivision]=useState();
    const[Circle, setCircle]=useState();
    const[Mobile, setMobile]=useState();
    const[Category, setCategory]=useState();
    const[DataStatus, setDataStatus]=useState(false)
    const handleCloseVds = () => setVdsShow(false);
    const handleShowVds = () => setVdsShow(true);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const selectRow={
        mode:'radio',
        onSelect:(row,isSelect,rowIndex)=>{
            setVds_Id(row['Id']);
            setVds_Name(row['Name']);

            handleShowVds();



        }
    }
    const getSearchBin=()=>{
        const refundBin=document.getElementById("txtVdsBin").value;

        let jsonObject={"sql":"Select * from Bin where BIN Like '%"+refundBin+"%' "}




        RestClient.PostRequest(AppUrl.getData,jsonObject).then(async result=>{

            if(result==""){cogoToast.error("Data not found");}else {
                        setName(result[0]["Name"]);
                        setBin(result[0]["BIN"]);
                        setAddress(result[0]["Address"]);
                        setDivision(result[0]["Division"]);
                        setCircle(result[0]["Circle"]);
                        setMobile(result[0]["Mobile"]);
                        setCategory(result[0]["Economic_Activity"]);

            }
        }).catch(error=>{});
    }
    const getRefundApplication=()=>{
        setDataStatus(true)

        const RefundCommi=document.getElementById("cmbRefundCommi");
        const RefundCommiValue=RefundCommi.options[RefundCommi.selectedIndex].text;

        const RefundDivision=document.getElementById("cmbRefundDivision");
        const RefundDivisionValue=RefundDivision.options[RefundDivision.selectedIndex].text;


        const RefundCircle=document.getElementById("cmbRefundCircle");
        const RefundCircleValue=RefundCircle.options[RefundCircle.selectedIndex].text;
        const RefundCategory=document.getElementById("cmbRefundCategory");
        const RefundCategoryValue=RefundCategory.options[RefundCategory.selectedIndex].text;

        let getRefundCommi=" ";
        let getRefundDivision=" ";
        let getRefundCircle=" ";
        let getRefundCategory=" ";
        if(RefundCommi.selectedIndex!=0){getRefundCommi=" And Commissionerate Like '%"+RefundCommiValue+"%' "}
        if(RefundDivision.selectedIndex!=0){getRefundDivision=" And Division Like '%"+RefundDivisionValue+"%' "}
        if(RefundCircle.selectedIndex!=0){getRefundCircle=" And Circle Like '%"+RefundCircleValue+"%' "}
        if(RefundCategory.selectedIndex!=0){getRefundCategory=" And Category Like '%"+RefundCategoryValue+"%' "}









        let jsonObject={"sql":" select * from refund where 1=1 "+getRefundCommi+"  "+getRefundDivision+" "+getRefundCircle+"  "+getRefundCategory+"  "}



        setColumnCoEfficient([{}]);setDataCoEffeciant([])
        RestClient.PostRequest(AppUrl.getData,jsonObject).then(async result=>{
            setDatalength(result.length)
            if(result==""){cogoToast.error("Data not found");setDataStatus(false)}else {
                await setColumnCoEfficient(
                    Object.keys(result[0]).map((k) => {
                        return {dataField: k, text: k, sort: true}
                    })
                )
                await setDataCoEffeciant(result);
                setDataStatus(false)
            }
        }).catch(error=>{});
    }


    const saveRefundApplication=()=>{

        const Amount=document.getElementById("txtRefundAmount").value;
        const appDate=appUtility.getDate(ApplicationDate);

        let jsonObject={"sql":"Insert into refund (Id,BIN,Name,Mobile,Address,Division,Circle,Category,Amount,App_Date)" +
                "values(NULL,'"+BIN+"','"+Name+"','"+Mobile+"','"+Address+"','"+Division+"','"+Circle+"','"+Category+"','"+Amount+"','"+appDate+"')"}
        if(BIN==undefined){cogoToast.error("Please select BIN")}else{
            if(Amount==""){cogoToast.error("Please insert Amount")}else{
                RestClient.PostRequest(AppUrl.saveData, jsonObject).then(result => {
                    if (result == 1) {
                        cogoToast.success("Application Data saved")

                    } else {
                        cogoToast.error("Application Data not saved")
                    }
                }).catch(error => {
                    cogoToast.error(error)
                });
            }
        }


    }
    return(
        <Fragment>
            <div >
                <Row className="mt-2">

                    <Col lg={3}>
                        <div className="">
                            <Row className="mt-2">
                                <Col lg={3} xs={4}><label className="text-danger fw-bold">NBR</label></Col>
                                <Col lg={9}  xs={8}>
                                    <select className="float-start w-100 customfontsize" id="cmbRefundCommi"
                                            onChange={()=>{
                                                setRefundDivision([]);
                                                setRefundCircle([]);
                                                const e = document.getElementById("cmbRefundCommi");
                                                const value = e.options[e.selectedIndex].value;
                                                const dt=state.division.filter(x => x.Commissionerate_Id==value);
                                                setRefundDivision(dt)


                                            }}
                                    >




                                        <option >NBR</option>



                                        {


                                            state.commi.map((ctr,index)=>{
                                                return (
                                                    <option key={index} value={ctr.Id}>{ctr.Name}</option>
                                                )
                                            })

                                        }
                                    </select>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col lg={3} xs={4}><label className="text-danger fw-bold">Division</label></Col>
                                <Col lg={9} xs={8}>
                                    <select className="float-start w-100 customfontsize" id="cmbRefundDivision"
                                            onChange={()=>{
                                                setRefundCircle([])
                                                const e = document.getElementById("cmbRefundDivision");
                                                const value = e.options[e.selectedIndex].value;
                                                const dt=state.circle.filter(x => x.Division_Id==value);
                                                setRefundCircle(dt)


                                            }}
                                    >
                                        <option >All Division</option>
                                        {
                                            RefundDivision.map((ctr,index)=>{
                                                return (
                                                    <option key={index} value={ctr.Id}>{ctr.Name}</option>
                                                )
                                            })

                                        }

                                    </select>
                                </Col>

                            </Row>
                            <Row className="mt-2">
                                <Col lg={3} xs={4}><label className="text-danger fw-bold">Circle</label></Col>
                                <Col lg={9} xs={8}>
                                    <select className="float-start w-100 customfontsize" id="cmbRefundCircle"  >
                                        <option >All Circle</option>
                                        {
                                            RefundCircle.map((ctr,index)=>{
                                                return (
                                                    <option key={index} value={ctr.Id}>{ctr.Name}</option>
                                                )
                                            })

                                        }
                                    </select>
                                </Col>

                            </Row>
                            <Row className="mt-2">
                                <Col lg={3} xs={4}><label className="text-danger fw-bold">Category</label></Col>
                                <Col lg={9} xs={8}>
                                    <select className="float-start w-100 customfontsize" id="cmbRefundCategory"  >
                                        <option value="0">All Items</option>
                                        {
                                            state.category.map((ctr,index)=>{
                                                return (
                                                    <option key={index} value={ctr.Id}>{ctr.Name}</option>
                                                )
                                            })

                                        }
                                    </select>
                                </Col>

                            </Row>

                            <Row className="mt-2">
                                <Col lg={3}>

                                </Col>
                                <Col lg={9}>
                                    <button className="w-100 text-primary font-weight-bold" onClick={getRefundApplication}>Search</button>
                                </Col>


                            </Row>
                            <hr className="mt-4"/>
                            <div className="mt-lg-4">
                                <button className="text-danger w-100" onClick={handleShow}>Refund Application Entry                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 </button>
                            </div>





                        </div>
                    </Col>

                    <Col lg={9}>
                        <div className="overflow-scroll animated zoomIn purchaseSmallDiv">

                            {
                                DataStatus?<LoadingText/>
                                    : <BootstrapTable   selectRow={selectRow}  keyField='Id' data={DataCoEffeciant} columns={ColumnCoEfficient}></BootstrapTable>

                            }
                        </div>
                        <label>Data found: {DataLength}</label>
                    </Col>

                </Row>

            </div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title >
                        <label className="text-danger">Refund Application</label>
                        <label className="p-1">BIN:</label>
                        <label>{BIN}</label>
                    </Modal.Title>


                </Modal.Header>
                <Modal.Body>
                    <div className="flex-column">
                        <label >BIN:</label>
                        <input className="w-25" type="text" id="txtVdsBin"/>
                        <button onClick={getSearchBin}>Search</button>
                        <label className="m-1 text-danger">Mobile</label><label>{Mobile}</label>
                        <hr/>
                        <label className="p-1 text-danger">Name :-</label><label>{Name}</label>
                        <hr/>
                        <label className="text-danger p-1">Address :-</label>
                        <label>{Address}</label>
                        <hr/>
                        <label className="p-1 text-danger">Division:</label>
                        <label className="p-1">{Division}</label>
                        <label className="p-1 text-danger">Circle:</label>
                        <label className="p-1">{Circle}</label>
                        <hr/>
                        <label className="p-1 text-danger">Amount:</label>
                        <input type="text" className="w-25" id="txtRefundAmount"/>
                        <label className="p-1 text-danger">Category:-</label>
                       <label>{Category}</label>
                        <hr/>
                        <label className="text-danger">Application Date:</label>
                        <DatePicker selected={ApplicationDate} onChange={(date) => setApplicationDate(date)} dateFormat='dd-MM-yyyy' seletable  isClearable showYearDropdown scrollableMonthYearDropdown  />


                    </div>



                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={saveRefundApplication}>
                        Save
                    </Button>
                    <Button variant="danger" onClick={handleClose}>
                        Close
                    </Button>


                </Modal.Footer>
            </Modal>
        </Fragment>

    )
}
