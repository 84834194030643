import {Fragment, useState} from "react";
import {Button, Col, Container, Modal, Row} from "react-bootstrap";
import {useSelector} from "react-redux";
import cogoToast from "cogo-toast";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
import BootstrapTable from "react-bootstrap-table-next";
import LoadingText from "../Loading/LoadingText";
export default function NewCategory(){
    let state=useSelector((state)=> state);
    const [Data, setData]=useState([]);
    const [Column, setColumn]=useState([{}]);
    const [CategoryName, setCategoryName]=useState();
    const[DataStatus, setDataStatus]=useState(false);
    const selectRowCategory={
        mode:'radio',
        onSelect:(row,isSelect,rowIndex)=>{
            setCategoryName(row['Name']);

        }
    }
    const getUser=()=>{

        let jsonObject={"sql":" Select * from Category "}


        setColumn([{}]);setData([])
        RestClient.PostRequest(AppUrl.getData,jsonObject).then(async result=>{

            if(result==""){cogoToast.error("Data not found");}else {
                await setColumn(
                    Object.keys(result[0]).map((k) => {
                        return {dataField: k, text: k, sort: true}
                    })
                )
                await setData(result);

            }
        }).catch(error=>{ });

    }



    const saveUser=()=>{
const CategroyName=document.getElementById("txtCategroyName").value;
        let jsonObject={"sql":"Insert into Category(Name)value('"+CategroyName+"')"};

    if(CategroyName==""){cogoToast.error("Please insert Name of Category")}else{


        RestClient.PostRequest(AppUrl.saveData,jsonObject).then(result=>{
            if(result==1){cogoToast.success("User Saved Saved");}else{cogoToast.warn("Data not Saved");}

        }).catch(error=>{
            cogoToast.error(error);

        });
    }}
    const updateItems=()=>{
        const CategroyName=document.getElementById("txtCategroyUpdate").value;
        const BinName=document.getElementById("txtCategoryBinName").value;
        let jsonObject={"sql":"Update Bin set Category='"+CategroyName+"' where Name Like'%"+BinName+"%' "};

        if(CategroyName==""){cogoToast.error("Please insert Name of Category")}else{
        if(BinName==""){cogoToast.error("Please insert BIN Name")}else{

            setDataStatus(true);
            RestClient.PostRequest(AppUrl.saveData,jsonObject).then(result=>{
                if(result==1){cogoToast.success("User Saved Saved");
                    setDataStatus(false);}else{cogoToast.warn("Data not Saved");setDataStatus(false);}

            }).catch(error=>{
                cogoToast.error(error);setDataStatus(false);

            });
        }}}
    return(
        <Fragment>
               <Container>
                <Row>
                    <Col lg={4} sm={12}>

                        <Row className="mt-2 text-justify">
                            <Col lg={6}><label className="customfontsize">New Category</label></Col>
                            <Col lg={6}> <input id="txtCategroyName"/></Col>

                        </Row>


                        <Row className="mt-2 text-justify">
                            <Col lg={6}> </Col>
                            <Col lg={6}><Button onClick={saveUser} className="w-100">Save</Button></Col>
                        </Row>
                        <hr/>
                        <Row className="mt-2 text-justify">
                            <Col lg={6}><label className="customfontsize">BIN Name</label></Col>
                            <Col lg={6}> <input id="txtCategoryBinName"/></Col>

                        </Row>
                        <Row className="mt-2 text-justify">
                            <Col lg={6}><label className="customfontsize">Category Name</label></Col>
                            <Col lg={6}> <input value={CategoryName}  id="txtCategroyUpdate"/></Col>

                        </Row>

                        <Row className="mt-2 text-justify">
                            <Col lg={6}>
                                {
                                    DataStatus? <LoadingText/>:<label>Data saved successfully</label>
                                }
                            </Col>
                            <Col lg={6}><Button onClick={updateItems} className="w-100">Save</Button></Col>
                        </Row>
                        <hr/>

                    </Col>
                    <Col lg={8} sm={12}>
                        <div className="purchaseDiv text-center">

                            <Row >

                                <Col lg={6} md={6} sm={6}><Button onClick={getUser}  className="w-50 m-4"  >Search User</Button></Col>

                            </Row>


                            <div className="tableDiv overflow-scroll">
                                <BootstrapTable selectRow={selectRowCategory} keyField='Id' data={Data} columns={Column}  > </BootstrapTable>
                            </div>





                        </div>
                    </Col>
                </Row>

               </Container>

        </Fragment>
    )
}
