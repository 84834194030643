import {Fragment, useState} from "react";
import {Button, Col, Modal, Row} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
import cogoToast from "cogo-toast";
import LoadingText from "../Loading/LoadingText";
import {useSelector} from "react-redux";
import appUtility from "../../RestAPI/AppUtility";
import AppUtility from "../../RestAPI/AppUtility";


export default function EfdInfogenex(){
    let state=useSelector((state)=> state);
    const [DivisionEfd, setDivisionEfd]=useState(state.division);
    const [CircleEfd, setCircleEfd]=useState([]);
    const [DataCoEffeciant, setDataCoEffeciant]=useState([]);
    const [ColumnCoEfficient, setColumnCoEfficient]=useState([{}]);
    const [DataLength, setDatalength]=useState(0)
    const[DataStatus, setDataStatus]=useState(false)

    const[ActionId, setActionId]=useState();
    const[Name, setName]=useState();

    const [showUpdateInfo, setShowUpdateInfo] = useState(false);
    const handleCloseUpdateInfo = () => setShowUpdateInfo(false);
    const handleShowUpdateInfo = () => setShowUpdateInfo(true);

    const selectRow={
        mode:'radio',
        onSelect:(row,isSelect,rowIndex)=>{
            setActionId(row['Complain ID']);
            setName(row['Name']);
                handleShowUpdateInfo();
        }
    }

    const getBIN=()=>{
        setDataStatus(true);


        const BinCategoryEFD=document.getElementById("cmbUpdateInfoCategory");
        const CategoryEFDValue=BinCategoryEFD.options[BinCategoryEFD.selectedIndex].text;
        const SearchBinInfo=document.getElementById("txtSearchBinInfo").value;

        const DaysValue=document.getElementById("txtDaysValue").value;
        const cmbDaysParam=document.getElementById("cmbUpdateInfoDays");

        let getCategoryEFD=" ";
        let getDaysValue=" ";
        let getSearchBinInfo=" ";

        if(BinCategoryEFD.selectedIndex!=2){getCategoryEFD="And Status ='"+CategoryEFDValue+"'"}
        if(SearchBinInfo!=""){getSearchBinInfo="And BIN Like '%"+SearchBinInfo+"%'"}

        if(cmbDaysParam.selectedIndex==1){ if(DaysValue ==""){cogoToast.error("Please insert value for days")}else{getDaysValue="And DATEDIFF(CURDATE(),Sms_Date ) >'"+DaysValue+"'"}}
        if(cmbDaysParam.selectedIndex==2){ if(DaysValue ==""){cogoToast.error("Please insert value for days")}else{getDaysValue="And DATEDIFF(CURDATE(),Sms_Date ) <'"+DaysValue+"'"}}


        let jsonObject={"sql":" SELECT Id As 'Complain ID',BIN,SMS As 'Complain',Sms_Date As 'Complain Date', DATEDIFF(CURDATE(),Sms_Date ) AS 'Waiting_Days',Status,Entry_User As'Complainer',Action_Date,Action_Sms,Action_User " +
                "FROM infogenex_sms where 1=1 "+getCategoryEFD+" "+getDaysValue+" "+getSearchBinInfo+" "}

        setColumnCoEfficient([{}]);setDataCoEffeciant([])
        RestClient.PostRequest(AppUrl.getData,jsonObject).then(async result=>{
            setDatalength(result.length);
            setDataStatus(false);
            if(result==""){cogoToast.error("Data not found")}else {
                await setColumnCoEfficient(
                    Object.keys(result[0]).map((k) => {
                        return {dataField: k, text: k, sort: true}
                    })
                )
                await setDataCoEffeciant(result);
                setDataStatus(false);

            }
        }).catch(error=>{ setDataStatus(false);});
    }


    const updateGenexAction=(sms)=>{
                const txtSmsInfo=document.getElementById("txtSmsInfo").value;

        RestClient.PostRequest(AppUrl.saveData, {"sql": "Update infogenex_sms set Status='Done', Action_Sms='"+txtSmsInfo+"',Action_Date='"+appUtility.getDate(new Date())+"',Action_User='"+state.name+"' where Id='"+ActionId+"'  "}).then(result => {
            if (result == 1) {
                cogoToast.success("Action Data saved")

            } else {
                cogoToast.error("Action Data not saved")
            }
        }).catch(error => {
            cogoToast.error(error)
        });

    }


    return(
        <Fragment>
            <div >
                <Row className="mt-2">

                    <Col lg={3}>
                        <div className="">
                            <Row className="mt-2">
                                <Col lg={3} xs={4}><label className="text-danger fw-bold">NBR</label></Col>
                                <Col lg={9}  xs={8}>
                                    <select className="float-start w-100 customfontsize" id="cmbBinCommi"
                                            onChange={()=>{
                                                setDivisionEfd([]);
                                                setCircleEfd([]);
                                                const e = document.getElementById("cmbBinCommi");
                                                const value = e.options[e.selectedIndex].value;
                                                const dt=state.division.filter(x => x.Commissionerate_Id==value);
                                                setDivisionEfd(dt)


                                            }}
                                    >




                                        <option >NBR</option>



                                        {


                                            state.commi.map((ctr,index)=>{
                                                return (
                                                    <option key={index} value={ctr.Id}>{ctr.Name}</option>
                                                )
                                            })

                                        }
                                    </select>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col lg={3}xs={4}><label className="text-danger fw-bold">Division</label></Col>
                                <Col lg={9}xs={8}>
                                    <select className="float-start w-100 customfontsize" id="cmbEFDDivision"
                                            onChange={()=>{
                                                setCircleEfd([])
                                                const e = document.getElementById("cmbEFDDivision");
                                                const value = e.options[e.selectedIndex].value;
                                                const dt=state.circle.filter(x => x.Division_Id==value);
                                                setCircleEfd(dt)


                                            }}
                                    >
                                        <option>All Division</option>

                                        {

                                            DivisionEfd.map((ctr,index)=>{
                                                return (
                                                    <option key={index} value={ctr.Id}>{ctr.Name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col lg={3}xs={4}><label className="text-danger fw-bold">Circle</label></Col>
                                <Col lg={9}xs={8}>
                                    <select className="float-start w-100 customfontsize" id="cmbEFDBinCircle" >
                                        <option >All Circle</option>

                                        {
                                            CircleEfd.map((ctr,index)=>{
                                                return (
                                                    <option key={index} value={ctr.Id}>{ctr.Name}</option>
                                                )
                                            })
                                        }

                                    </select>
                                </Col>
                            </Row>

                            <Row className="mt-2">
                                <Col lg={3}xs={4}><label className="text-danger fw-bold">Status</label></Col>
                                <Col lg={9}xs={8}>
                                    <select className="float-start w-100 customfontsize" id="cmbUpdateInfoCategory"  >
                                        <option >Pending</option>
                                        <option >Done</option>
                                        <option >All</option>

                                    </select>
                                </Col>

                            </Row>
                            <Row className="mt-2">
                                <Col lg={3}xs={4}><label className="text-danger fw-bold">Days</label></Col>
                                <Col lg={5}xs={5}>
                                    <select className="float-start w-100 customfontsize" id="cmbUpdateInfoDays"  >
                                        <option >All</option>
                                        <option >More</option>
                                        <option >Less</option>
                                    </select>
                                </Col>
                                <Col lg={4}xs={3}><input className="w-100" id="txtDaysValue" type="text"/></Col>

                            </Row>
                            <Row className="mt-2">
                                <Col lg={3}xs={4}><label className="text-danger fw-bold">BIN</label></Col>
                                <Col lg={9}xs={8}>
                                    <input type="text" className="w-100" id="txtSearchBinInfo"/>
                                </Col>

                            </Row>
                            <Row className="mt-2">
                                <Col lg={3}>

                                </Col>
                                <Col lg={9}>
                                    <button className="w-100" onClick={getBIN}>Search</button>
                                </Col>


                            </Row>


                        </div>
                    </Col>

                    <Col lg={9}>
                        <div className="overflow-scroll animated zoomIn purchaseSmallDiv">

                            {
                                DataStatus?<LoadingText/>
                                    :  <BootstrapTable selectRow={selectRow}  keyField='Complain ID'  data={DataCoEffeciant} columns={ColumnCoEfficient}></BootstrapTable>

                            }
                        </div>
                        <label className="text-danger customfontsize">Data found: {DataLength}</label>

                    </Col>

                </Row>

            </div>
            <Modal show={showUpdateInfo} onHide={handleCloseUpdateInfo}>
                <Modal.Header closeButton>
                    <Modal.Title> <label className="text-primary">Update Action</label> </Modal.Title>

                </Modal.Header>
                <Modal.Body>
                    <label>{Name}</label>
                    <hr/>


                    <select className="float-start w-100 customfontsize" id="cmbSmsInfo" onClick={()=>{
                        const cmbSmsInfo=document.getElementById("cmbSmsInfo");
                        let txtInfo=document.getElementById("txtSmsInfo");
                        txtInfo.innerText=cmbSmsInfo.options[cmbSmsInfo.selectedIndex].text;
                    }} >
                        <option >Machine fixed</option>
                        <option >Need some time</option>
                        <option >its an old machine</option>

                    </select>
                    <hr/>

                    <textarea id="txtSmsInfo" placeholder="Please write your sms here. Maximum 120 letters" className="w-100 h-50"></textarea>
                </Modal.Body>
                <Modal.Footer>


                    <Button className="float-end" variant="success" onClick={updateGenexAction} >
                        Save Action
                    </Button>

                    <Button variant="danger" onClick={handleCloseUpdateInfo}>
                        Close
                    </Button>



                </Modal.Footer>
            </Modal>



        </Fragment>

    )
}
