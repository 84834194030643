import {Fragment, useRef, useState} from "react";
import {Button, Card, Col, Row} from "react-bootstrap";
import DatePicker from "react-datepicker";
import {useSelector} from "react-redux";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
import BootstrapTable from "react-bootstrap-table-next";
import AppUtility from "../../RestAPI/AppUtility";
import cogoToast from "cogo-toast";

export default function Asset(){
    const ref=useRef();
    let state=useSelector((state)=> state);
    const [FirstJoinDate, setFirstJoinDate] = useState(new Date());
    const [Data, setData]=useState([]);
    const [DataLand, setDataLand]=useState([]);
    const [Column, setColumn]=useState([{}]);
    const [ColumnLand, setColumnLand]=useState([{}]);

    const [FDobDate, setFDobDate] = useState(new Date());
    const [NID, setNID] = useState(12345);



    const selectRow={
        mode:'radio',
        onSelect:(isSelect,rowIndex)=>{

        }
    }
    const getData=()=> {

        const jsonObject = {"sql": "SELECT * FROM `Officer_List`"}
        setData([])
        RestClient.PostRequest(AppUrl.getData, jsonObject).then(result => {
            setColumn(
                Object.keys(result[0]).map((k) => {
                    return {dataField: k, text: k, sort: true}
                })
            )
            setData(result)


        }).catch(error => {
        });


    }
    const getFamily=()=> {

        const jsonObject = {"sql": "SELECT Id,SL,Name,NID_BRC_TIN,DOB,Relation,Profession,Designation_Address FROM `Asset_Part_A_family`"}
        setData([])
        RestClient.PostRequest(AppUrl.getData, jsonObject).then(result => {
            setColumn(
                Object.keys(result[0]).map((k) => {
                    return {dataField: k, text: k, sort: true}
                })
            )
            setData(result)


        }).catch(error => {
        });


    }
    const getNonMovableLand=()=> {

        const jsonObject = {"sql": "SELECT * FROM `Asset_Part_B_NonMovable`"}
        setData([])
        RestClient.PostRequest(AppUrl.getData, jsonObject).then(result => {
            setColumnLand(
                Object.keys(result[0]).map((k) => {
                    return {dataField: k, text: k, sort: true}
                })
            )
            setDataLand(result)


        }).catch(error => {
        });
    }
    const saveDataFamily=()=>{

        const F_Sl=document.getElementById("txtFSl").value;
        const F_Name=document.getElementById("txtFName").value;
        const F_NID=document.getElementById("txtFNidTin").value;
        const F_DOB=AppUtility.getDate(FDobDate);
        const F_Relation=document.getElementById("txtFRelation").value;
        const F_Profession=document.getElementById("txtFProfession").value;
        const F_Address=document.getElementById("txtPA").value;

        const jsonObject={"sql":"INSERT INTO `Asset_Part_A_family` (`Id`, `NID`, `SL`, `Name`, `NID_BRC_TIN`, `DOB`, `Relation`, `Profession`, `Designation_Address`)" +
                " VALUES (NULL, '"+F_Sl+"', '1234', '"+F_Name+"', '"+F_NID+"', '"+F_DOB+"', '"+F_Relation+"', '"+F_Profession+"', '"+F_Address+"')"}

        RestClient.PostRequest(AppUrl.saveData,jsonObject).then(result=>{
            if(result==1){cogoToast.success("Data saved"); ref.current.value=""}else{cogoToast.error("Data not saved")}


        }).catch(error=>{ });
        getFamily();




    }
    const saveLand=()=>{


        const LandDesc=document.getElementById("cmbLandDescription");
        const LandDescValue=LandDesc.options[LandDesc.selectedIndex].text
        const LandPlace=document.getElementById("txtLandPlace").value;
        const LandQty=document.getElementById("txtLandQty").value;
        const LandAcquiType=document.getElementById("txtAquirType").value;
        const LandOwner=document.getElementById("txtWhoOwn").value;
        const AcquisitionDate=document.getElementById("txtAcquireDate").value;
        const MoneySource=document.getElementById("txtSourceMoney").value;
        const jsonObject={"sql":"INSERT INTO `Asset_Part_B_NonMovable` (`Id`, `NID`, `Description`, `Loaction`, `Quantity`, `Acquisition_Type`, `Asset_Holder_Name`, `Date_And_Value`, `Source_of_Value`, `Comments`)" +
                " VALUES (NULL, '"+NID+"', '"+LandDescValue+"', '"+LandPlace+"', '"+LandQty+"', '"+LandAcquiType+"', '"+LandOwner+"', '"+AcquisitionDate+"', '"+MoneySource+"', 'comments')"}



        RestClient.PostRequest(AppUrl.saveData,jsonObject).then(result=>{
            if(result==1){cogoToast.success("Data saved"); ref.current.value=""}else{cogoToast.error("Data not saved")}

            getNonMovableLand();
        }).catch(error=>{ });





    }
    const saveMovableLand=()=>{


        const MLandDesc=document.getElementById("cmbMLand");
        const MLandDescValue=MLandDesc.options[MLandDesc.selectedIndex].text
        const LandQty=document.getElementById("txtLandQty").value;
        const LandAcquiType=document.getElementById("txtAquirType").value;
        const AcquisitionDate=document.getElementById("txtAcquireDate").value;
        const MMoneySource=document.getElementById("txtSourceMoney").value;
        const txtMCommnets=document.getElementById("txtMComments").value;
        const jsonObject={"sql":""}



        RestClient.PostRequest(AppUrl.saveData,jsonObject).then(result=>{
            if(result==1){cogoToast.success("Data saved"); ref.current.value=""}else{cogoToast.error("Data not saved")}

            getNonMovableLand();
        }).catch(error=>{ });
    }







    return(
        <Fragment>
            <div className="purchaseDiv text-center">
                <Row className="mt-2">
                    <Col lg={12} sm={12}><label className="text-danger fs-4">Part-A  General Information</label></Col>

                </Row>
                <Row className="mt-2 text-justify">
                    <Col lg={2} sm={12}><label>1. Name</label></Col>
                    <Col lg={3} sm={12}><input id="txtSName" type="text" className="w-100"/></Col>
                    <Col lg={2} sm={12}><label>2. ID No (if have)</label></Col>
                    <Col lg={3} sm={12}><input id="txtSId" type="text" className="w-100"/></Col>
                </Row>
                <Row className="mt-2 text-justify">
                    <Col lg={2} sm={12}><label>3.Present Designation</label></Col>
                    <Col lg={3} sm={12}>
                        <select className="float-start w-100 customfontsize" id="cmbSDesignation"  >
                            <option value="0">Select</option>
                            <option value="1">MLSS</option>
                            <option value="1">Sepai</option>
                            <option value="1">Sub-Inspector</option>
                            <option value="1">UD</option>
                            <option value="1">Computer Operator</option>
                            <option value="1">Steno Typist</option>
                            <option value="1">Driver</option>



                        </select>
                    </Col>
                    <Col lg={2} sm={12}><label>4. Cadar (if have)</label></Col>
                    <Col lg={3} sm={12}><input id="txtSCadar" type="text" className="w-100"/></Col>
                </Row>
                <Row className="mt-2 text-justify">
                    <Col lg={2} sm={12}><label>5. Present Posting</label></Col>
                    <Col lg={3} sm={12}><input id="txtSPP" type="text" className="w-100"/></Col>
                    <Col lg={2} sm={12}><label>6. Date of First joining</label></Col>
                    <Col lg={3} sm={12}>
                        <DatePicker selected={FirstJoinDate} onChange={(date) => setFirstJoinDate(date)} dateFormat='dd-MM-yyyy' seletable maxDate={new Date()} isClearable showYearDropdown scrollableMonthYearDropdown  />
                    </Col>
                </Row>
                <Row className="mt-2 text-justify">
                    <Col lg={2} sm={12}><label>7. Joining Designation</label></Col>
                    <Col lg={3} sm={12}>
                        <select className="float-start w-100 customfontsize" id="cmbSJoiningDesignation"  >
                            <option value="0">Select</option>
                            <option value="1">MLSS</option>
                            <option value="1">Sepai</option>
                            <option value="1">Sub-Inspector</option>
                            <option value="1">UD</option>
                            <option value="1">Computer Operator</option>
                            <option value="1">Steno Typist</option>
                            <option value="1">Driver</option>



                        </select>
                    </Col>
                    <Col lg={2} sm={12}><label>8. Permanent Address</label></Col>
                    <Col lg={3} sm={12}><input id="txtPssword" type="text" className="w-100"/></Col>
                </Row>
                <Row className="mt-2 text-justify">
                    <Col lg={2} sm={12}><label>9. NID No</label></Col>
                    <Col lg={3} sm={12}><input id="txtSNID" type="text" className="w-100"/></Col>
                    <Col lg={2} sm={12}><label>10. Date of Birth</label></Col>
                    <Col lg={3} sm={12}>
                        <DatePicker selected={FirstJoinDate} onChange={(date) => setFirstJoinDate(date)} dateFormat='dd-MM-yyyy' seletable maxDate={new Date()} isClearable showYearDropdown scrollableMonthYearDropdown  />

                    </Col>
                </Row>
                <Row className="mt-2 text-justify">
                    <Col lg={2} sm={12}><label>11. TIN </label></Col>
                    <Col lg={3} sm={12}><input id="txtSTin" type="text" className="w-100"/></Col>
                    <Col lg={2} sm={12}><label>12. Salary Scale</label></Col>
                    <Col lg={3} sm={12}><input id="txtSSalaryScale" type="text" className="w-100"/></Col>
                </Row>
                <Row className="mt-2 text-justify">
                    <Col lg={2} sm={12}><label>13. Basic Salary</label></Col>
                    <Col lg={3} sm={12}><input id="txtSBasicSalary" type="text" className="w-100"/></Col>
                    <Col lg={2} sm={12}><label>14. Mobile No</label></Col>
                    <Col lg={3} sm={12}><input id="txtSMobile" type="text" className="w-100"/></Col>
                </Row>
                <Row className="mt-2 text-justify ">
                    <Col lg={2} sm={12}><label>15. Email if have</label></Col>
                    <Col lg={3} sm={12}><input id="txtSEmail" type="text" className="w-100"/></Col>
                    <Col lg={2} sm={12}><label>16. Present Address</label></Col>
                    <Col lg={3} sm={12}><input id="txtSPresentAddress" type="text" className="w-100"/></Col>
                </Row>

                <Row className="mt-2">


                    <Col lg={3} sm={12}></Col>
                    <Col lg={3} sm={12}><Button  className="w-100">Save</Button></Col>

                    <Col lg={3} sm={12}><Button  className="w-100">Edit</Button></Col>
                    <Col lg={3} sm={12}></Col>
                </Row>

                <Row className="mt-2">
                    <Col lg={12} sm={12}><hr/></Col>

                </Row>

                <Row className="mt-2">
                    <Col lg={12} sm={12}><label className="text-danger fs-4">Family Members Detailes</label></Col>
                    <div className=" ">
                        <BootstrapTable  ref={ref} id="tblSearch" keyField='Id' data={Data} columns={Column} selectRow={selectRow}  > </BootstrapTable>
                    </div>
                </Row>
                <Row className="mt-2">
                    <Col lg={1} sm={12}>

                        <Card style={{ width: '7rem' }}>

                            <Card.Body>
                                <Card.Title>SL</Card.Title>
                                <Card.Text>
                                    <input id="txtFSl" type="text" className="w-100"/>
                                </Card.Text>

                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg={2} sm={12}>

                        <Card style={{ width: '14rem' }}>

                            <Card.Body>
                                <Card.Title>Name</Card.Title>
                                <Card.Text>
                                    <input id="txtFName" type="text" className="w-100"/>
                                </Card.Text>

                            </Card.Body>
                        </Card>

                        </Col>
                    <Col lg={2} sm={12}>

                        <Card style={{ width: '14rem' }}>

                            <Card.Body>
                                <Card.Title>NID/TIN</Card.Title>
                                <Card.Text>
                                    <input id="txtFNidTin" type="text" className="w-100"/>
                                </Card.Text>

                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg={2} sm={12}>

                        <Card style={{ width: '14rem' }}>

                            <Card.Body>
                                <Card.Title>Date Of Birth</Card.Title>
                                <Card.Text>
                                    <DatePicker selected={FDobDate} onChange={(date) => setFDobDate(date)} dateFormat='dd-MM-yyyy' seletable maxDate={new Date()} isClearable showYearDropdown scrollableMonthYearDropdown  />

                                </Card.Text>

                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg={1} sm={12}>

                        <Card style={{ width: '7rem' }}>

                            <Card.Body>
                                <Card.Title>Relation</Card.Title>
                                <Card.Text>
                                    <input id="txtFRelation" type="text" className="w-100"/>
                                </Card.Text>

                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg={1} sm={12}>

                        <Card style={{ width: '8rem' }}>

                            <Card.Body>
                                <Card.Title>Profession</Card.Title>
                                <Card.Text>
                                    <input id="txtFProfession" type="text" className="w-100"/>
                                </Card.Text>

                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg={3} sm={12}>

                        <Card style={{ width: '21rem' }}>

                            <Card.Body>
                                <Card.Title>Designation, Address if Working</Card.Title>
                                <Card.Text>
                                    <input id="txtPA" type="text" className="w-100"/>
                                </Card.Text>

                            </Card.Body>
                        </Card>
                    </Col>

                </Row>



                <Row className="mt-2">


                    <Col lg={6} sm={12}></Col>
                    <Col lg={2} sm={12}><Button onClick={saveDataFamily} className="w-100">Save</Button></Col>
                    <Col lg={4} sm={12}><Col lg={4} sm={12}><Button onClick={getFamily} className="w-100">Reload</Button></Col></Col>
                </Row>


                <Row className="mt-2">
                    <Col lg={12} sm={12}><hr/></Col>

                </Row>

                <Row className="mt-2">
                    <Col lg={12} sm={12}><label className="text-danger fs-4">Part-B - Asset</label></Col>

                </Row>
                <Row className="mt-2">
                    <Col lg={3} sm={12}><label className="text-success fs-4">1. Non-Moveble Asset</label></Col>

                </Row>
                <div className=" ">
                    <BootstrapTable ref={ref} id="tblLand" keyField='Id' data={DataLand} columns={ColumnLand}  > </BootstrapTable>
                </div>
                <Row className="mt-2">
                    <Col lg={2} sm={12}>
                        <Card style={{ width: '14rem' , height: '12rem' }}>

                            <Card.Body>
                                <Card.Title>Description of Land</Card.Title>
                                <Card.Text  style={{height:'12rem'}}>
                                    <select className="float-start w-100 customfontsize" id="cmbLandDescription"  >
                                        <option value="0">Select</option>
                                        <option value="1">Land-Agri</option>
                                        <option value="1">Land-Non-Agri</option>
                                        <option value="1">Imarat</option>
                                        <option value="1">Basat Bari</option>
                                        <option value="1">Flat</option>
                                        <option value="1">Khamar/Baganbari</option>
                                        <option value="1">Business Farm</option>
                                        <option value="1">Others</option>

                                    </select>
                                </Card.Text>

                            </Card.Body>
                        </Card>

                    </Col>
                    <Col lg={2} sm={12}>



                        <Card style={{ width: '14rem' , height: '12rem' }}>

                            <Card.Body>
                                <Card.Title>Place(District, Upazila/Thana, Mouza, Khotian, Dah No, Holding No/Abroad)</Card.Title>
                                <Card.Text>
                                    <input id="txtLandPlace" type="text" className="w-100"/>
                                </Card.Text>

                            </Card.Body>
                        </Card>

                    </Col>
                    <Col lg={1} className="" sm={12}>

                        <Card style={{ width: '10rem' , height: '12rem'}}>

                            <Card.Body>
                                <Card.Title>Quantity(SFT/Decimal)</Card.Title>

                                    <input id="txtLandPlace" type="text" className="w-100 "/>


                            </Card.Body>
                        </Card>

                    </Col>
                    <Col lg={2} sm={12}><label>Acquisition Type(Heritence,/purchase/Others/ Joint Ownership)</label></Col>
                    <Col lg={1} sm={12}><label>To whome Name Acwuired(Own/wife/Husband/Children)</label></Col>
                    <Col lg={2} sm={12}><label>Acquisition date And Value </label></Col>
                    <Col lg={2} sm={12}><label>Source of Money if Purchased</label></Col>

                </Row>
                <Row className="mt-2">


                    <Col lg={2} sm={12}></Col>
                    <Col lg={1} sm={12}><input id="txtLandQty" type="text" className="w-100"/></Col>
                    <Col lg={2} sm={12}><input id="txtAquirType" type="text" className="w-100"/></Col>
                    <Col lg={1} sm={12}><input id="txtWhoOwn" type="text" className="w-100"/></Col>
                    <Col lg={2} sm={12}><input id="txtAcquireDate" type="text" className="w-100"/></Col>
                    <Col lg={2} sm={12}><input id="txtSourceMoney" type="text" className="w-100"/></Col>

                </Row>
                <Row className="mt-2">

                    <Col lg={4} sm={12}></Col>
                    <Col lg={4} sm={12}><Button onClick={saveLand} className="w-100">Save</Button></Col>
                    <Col lg={4} sm={12}></Col>

                </Row>
                <Row className="mt-2">
                    <Col lg={12} sm={12}><hr/></Col>

                </Row>
                <Row className="mt-2">
                    <Col lg={3} sm={12}><label className="text-success fs-4">2. Moveble Asset</label></Col>

                </Row>
                <div className=" ">
                    <BootstrapTable ref={ref} id="tblSearch" keyField='Id' data={Data} columns={Column}  > </BootstrapTable>
                </div>
                <Row className="mt-2 ">
                    <Col lg={2} sm={12}><label>Description of Asset</label></Col>
                    <Col lg={1} sm={12}><label>To whome Name Acwuired(Own/wife/Husband/Children)</label></Col>
                     <Col lg={1} sm={12}><label>Quantity of Asset</label></Col>
                    <Col lg={2} sm={12}><label>Acquisition Type(Heritence,/purchase/Others/ Joint Ownership)</label></Col>
                    <Col lg={2} sm={12}><label>Acquisition Value/balance </label></Col>
                    <Col lg={2} sm={12}><label>Source of Money if Applicable</label></Col>
                    <Col lg={2} sm={12}><label>Comments</label></Col>

                </Row>
                <Row className="mt-2">

                    <Col lg={2} sm={12}>
                        <select className="float-start w-100 customfontsize" id="cmbMLand"  >
                            <option value="0">Select</option>
                            <option value="1">Juwellery</option>
                            <option value="1">Stocks/Share/Debencher/Bond/Securities</option>
                            <option value="1">Savings/Prise Bond/Saving Scheme</option>
                            <option value="1">Bima</option>
                            <option value="1">Cash/Cash at Bank/Loan to Other</option>
                            <option value="1">FDR/DPS</option>
                            <option value="1">GPF/CPF</option>
                            <option value="1">Motor</option>
                            <option value="1">Electronic Devices</option>
                            <option value="1">fire Arms</option>
                            <option value="1">Others</option>

                        </select>
                    </Col>
                    <Col lg={2} sm={12}><input id="txtMOwner" type="text" className="w-100"/></Col>
                    <Col lg={1} sm={12}><input id="txtMLandQty" type="text" className="w-100"/></Col>
                    <Col lg={2} sm={12}><input id="txtMAcquireType" type="text" className="w-100"/></Col>
                    <Col lg={1} sm={12}><input id="txtMAcquireValue" type="text" className="w-100"/></Col>
                    <Col lg={2} sm={12}><input id="txtMSourceMoney" type="text" className="w-100"/></Col>
                    <Col lg={2} sm={12}><input id="txtMComments" type="text" className="w-100"/></Col>

                </Row>
                <Row className="mt-2">


                    <Col lg={4} sm={12}></Col>
                    <Col lg={4} sm={12}><Button  className="w-100">Save</Button></Col>
                    <Col lg={4} sm={12}></Col>
                </Row>
                <Row className="mt-2">
                    <Col lg={12} sm={12}><hr/></Col>

                </Row>

                <Row className="mt-2">
                    <Col lg={12} sm={12}><label className="text-danger fs-4">Part-C Liability</label></Col>

                </Row>
                <Row className="mt-2">
                    <Col lg={12} sm={12}><label className="text-danger fs-4">(All Liability of Ownself and whloe family)</label></Col>

                </Row>
                <div className=" ">
                    <BootstrapTable ref={ref} id="tblSearch" keyField='Id' data={Data} columns={Column}  > </BootstrapTable>
                </div>
                <Row className="mt-2 ">
                    <Col lg={2} sm={12} className="border-danger border-opacity-25"><label>SL</label></Col>
                    <Col lg={1} sm={12}><label>To whome Name Acwuired(Own/wife/Husband/Children)</label></Col>
                    <Col lg={1} sm={12}><label>Quantity of Asset</label></Col>
                    <Col lg={2} sm={12}><label>Acquisition Type(Heritence,/purchase/Others/ Joint Ownership)</label></Col>
                    <Col lg={2} sm={12}><label>Acquisition Value/balance </label></Col>
                    <Col lg={2} sm={12}><label>Source of Money if Applicable</label></Col>
                    <Col lg={2} sm={12}><label>Comments</label></Col>

                </Row>

                <Row className="mt-2">


                    <Col lg={1} sm={12}><input id="txtPssword" type="text" className="w-100"/></Col>
                    <Col lg={2} sm={12}><input id="txtPssword" type="text" className="w-100"/></Col>
                    <Col lg={1} sm={12}><input id="txtPssword" type="text" className="w-100"/></Col>
                    <Col lg={2} sm={12}><input id="txtPssword" type="text" className="w-100"/></Col>
                    <Col lg={1} sm={12}><input id="txtPssword" type="text" className="w-100"/></Col>
                    <Col lg={2} sm={12}><input id="txtPssword" type="text" className="w-100"/></Col>
                    <Col lg={2} sm={12}><input id="txtPssword" type="text" className="w-100"/></Col>

                </Row>
                <Row className="mt-2">


                    <Col lg={4} sm={12}></Col>
                    <Col lg={4} sm={12}><Button  className="w-100">Save</Button></Col>
                    <Col lg={4} sm={12}></Col>
                </Row>
                <Row className="mt-2">
                    <Col lg={12} sm={12}><hr/></Col>

                </Row>


            </div>
        </Fragment>
    )
}
