import {Fragment, useEffect, useState} from "react";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
import cogoToast from "cogo-toast";
import BootstrapTable from "react-bootstrap-table-next";
import {Button, Col, Container, Modal, Row} from "react-bootstrap";
import {useSelector} from "react-redux";
import LoadingText from "../Loading/LoadingText";

export default function CoEfficientReport(props){
    let state=useSelector((state)=> state);
    const[DataStatus, setDataStatus]=useState(false)
    const [DataLength, setDatalength]=useState(0)
    const [DivisionCo, setDivisionCo]=useState([]);
    const [CircleCO, setCircleCO]=useState([]);
    const [DataCoEffeciant, setDataCoEffeciant]=useState([]);
    const [ColumnCoEfficient, setColumnCoEfficient]=useState([{}]);
    const[CoBinName,setCoBinName]=useState()
    const [Id_4_3, setId_4_3] = useState();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    useEffect(()=>{
        setDataCoEffeciant([])
        setCoBinName(props.bin)

    },[props.bin]);
    const selectRow={
        mode:'radio',
        onSelect:(row,isSelect,rowIndex)=>{
            setId_4_3(row['ID']);
            handleShow();
        }
    }
    const get4_3=()=>{

        setDataStatus(true)



        const DivisionCoReport=document.getElementById("cmbCoReportDivision");
        const DivisionValueCoReport=DivisionCoReport.options[DivisionCoReport.selectedIndex].text;

        const CircleCoReport=document.getElementById("cmbCoReportCircle");
        const CircleValueCoReport=CircleCoReport.options[CircleCoReport.selectedIndex].text;

        const CoStatus=document.getElementById("cmbCoStatus");
        const CoStatusValue=CoStatus.options[CoStatus.selectedIndex].text;

        const CategoryCoReport=document.getElementById("cmbCoReportCategory");
        const CategoryValueCoReport=CategoryCoReport.options[CategoryCoReport.selectedIndex].text;

        const ItemsCoReport=document.getElementById("cmbCoReportItems");
        const ItemsValueCoReport=ItemsCoReport.options[ItemsCoReport.selectedIndex].text;

        const SearchBinCoReport=document.getElementById("txtCoReportSearchBin").value;
        const SearchNameCoReport=document.getElementById("txtCoReportSearchName").value;
        const SearchHscodeCoReport=document.getElementById("txtCoReportSearchHscode").value;

        let getDivisionReport=" ";
        let getCircleReport=" ";
        let getCoStatus="";
        let getCategoryReport=" ";
        let getItemsReport="";
        let getBinReport="";
        let getNameReport="";
        let getHscodeReport="";

        if(DivisionCoReport.selectedIndex!=0){getDivisionReport="And Division Like '%"+DivisionValueCoReport+"%'"}
        if(CircleCoReport.selectedIndex!=0){getCircleReport="And Circle Like '%"+CircleValueCoReport+"%'"}
        if(CoStatus.selectedIndex!=0){getCoStatus=" And Status = '"+CoStatusValue+"'"}
        if(CategoryCoReport.selectedIndex!=0){getCategoryReport="And Economic_Activity Like '%"+CategoryValueCoReport+"%'"}
        if(ItemsCoReport.selectedIndex!=0){getItemsReport="And Category Like '%"+ItemsValueCoReport+"%'"}
        if(SearchBinCoReport!=""){ getBinReport=" And BIN Like '%"+SearchBinCoReport+"%'"}
        if(SearchNameCoReport!=""){ getNameReport=" And Name Like '%"+SearchNameCoReport+"%'"}
        if(SearchHscodeCoReport!=""){ getHscodeReport=" And Hscode Like '%"+SearchHscodeCoReport+"%'"}

        const CoAdditionCompare=document.getElementById("cmbCoAdditionCompare");
        const CoDaysCompare=document.getElementById("cmbCoDaysCompare");

        const CoAdditionValue=document.getElementById("txtCoAdditionValue").value;
        const CoDaysValue=document.getElementById("txtCoDaysValue").value;


        let getAdditionParcentage="";
        let getDays="";

        if(CoAdditionCompare.selectedIndex==1){
            if(CoAdditionValue==""){cogoToast.error("Please insert Addition Value")}else{
                getAdditionParcentage=" And Addition_Parcentage > "+CoAdditionValue+""
            }
        }
        if(CoAdditionCompare.selectedIndex==2){
            if(CoAdditionValue==""){cogoToast.error("Please insert Addition Value")}else{
                getAdditionParcentage=" And Addition_Parcentage < "+CoAdditionValue+""
            }
        }
        if(CoDaysCompare.selectedIndex==1){
            if(CoDaysValue==""){cogoToast.error("Please insert Days Value")}else{
                getDays=" And DATEDIFF(CURDATE(),Entry_Date ) > "+CoDaysValue+""
            }
        }
        if(CoDaysCompare.selectedIndex==2){
            if(CoDaysValue==""){cogoToast.error("Please insert Days Value")}else{
                getDays=" And DATEDIFF(CURDATE(),Entry_Date )< "+CoDaysValue+""
            }
        }




            let jsonObject={"sql":"SELECT ID,BIN,Name,Division,Circle,Description As Description_Of_Goods,Supply_Qty AS Qty,Supply_Unit As Unit,Rm_Price,Addition_Price,Addition_Parcentage AS 'Add_%',Total_Price,Entry_Date As 'Effect_Date',DATEDIFF(CURDATE(),Entry_Date ) AS Total_Days,Status,Sale_Category,Comments As Comments_for_goods FROM `Co_Efficient`" +
                    " where 1=1  "+getDivisionReport+" "+getCircleReport+" "+getCategoryReport+" "+getItemsReport+" "+getBinReport+" "+getNameReport+" "+getHscodeReport+"  "+getAdditionParcentage+" "+getDays+" "+getCoStatus+"  "}
            setColumnCoEfficient([{}]);setDataCoEffeciant([])
        RestClient.PostRequest(AppUrl.getData,jsonObject).then(async result=>{
            setDatalength(result.length);
            if(result==""){cogoToast.error("Data not found");setDataStatus(false)}else {
                await setColumnCoEfficient(
                    Object.keys(result[0]).map((k) => {
                        return {dataField: k, text: k, sort: true}
                    })
                )
                await setDataCoEffeciant(result);
                setDataStatus(false)

            }
        }).catch(error=>{setDataStatus(false)});



    }
    const update4_3=()=>{


        const Status4_3=document.getElementById("cmbStatus4_3");
        const Status4_3Value=Status4_3.options[Status4_3.selectedIndex].text;



        let jsonObject={"sql":"Update  Co_Efficient set Status='"+Status4_3Value+"' where ID="+Id_4_3+" "}

        if(Status4_3.selectedIndex==0){cogoToast.error("Please select status")}else{

            RestClient.PostRequest(AppUrl.updateData,jsonObject).then(async result=>{

                if(result==""){cogoToast.error("Data not Updated");
                }else {
                    cogoToast.success("Data updated successfully")

                }
            }).catch(error=>{setDataStatus(false)});
        }

    }

    return(
        <Fragment>
            <Container className="">
                <Row>
                    <Col lg={3}>
                        <Row className="mt-2">
                            <Col lg={3} xs={4}><label className="text-danger fw-bold">NBR</label></Col>
                            <Col lg={9}  xs={8}>
                                <select className="float-start w-100 customfontsize" id="cmbCOCommi"
                                        onChange={()=>{
                                            setDivisionCo([]);
                                            setCircleCO([]);
                                            const e = document.getElementById("cmbCOCommi");
                                            const value = e.options[e.selectedIndex].value;
                                            const dt=state.division.filter(x => x.Commissionerate_Id==value);
                                            setDivisionCo(dt)


                                        }}
                                >




                                    <option >NBR</option>



                                    {


                                        state.commi.map((ctr,index)=>{
                                            return (
                                                <option key={index} value={ctr.Id}>{ctr.Name}</option>
                                            )
                                        })

                                    }
                                </select>
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col lg={3} xs={4}><label className="text-danger fw-bold">Division</label></Col>
                            <Col lg={9} xs={8}>
                                <select className="float-start w-100 customfontsize" id="cmbCoReportDivision"
                                        onChange={()=>{
                                            setCircleCO([])
                                            const e = document.getElementById("cmbCoReportDivision");
                                            const value = e.options[e.selectedIndex].value;
                                            const dt=state.circle.filter(x => x.Division_Id==value);
                                            setCircleCO(dt)


                                        }}
                                >
                                    <option >All Division</option>

                                    {
                                        DivisionCo.map((ctr,index)=>{
                                            return (
                                                <option key={index} value={ctr.Id}>{ctr.Name}</option>
                                            )
                                        })

                                    }
                                </select>
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col lg={3}xs={4}><label className="text-danger fw-bold">Circle</label></Col>
                            <Col lg={9}xs={8}>
                                <select className="float-start w-100 customfontsize" id="cmbCoReportCircle" >
                                    <option >All Circle</option>

                                    {
                                        CircleCO.map((ctr,index)=>{
                                            return (
                                                <option key={index} value={ctr.Id}>{ctr.Name}</option>
                                            )
                                        })

                                    }


                                </select>
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col lg={3}xs={4}><label className="text-danger fw-bold">Status</label></Col>
                            <Col lg={9}xs={8}>
                                <select className="float-start w-100 customfontsize" id="cmbCoStatus"  >
                                    <option >All</option>
                                    <option >Active</option>
                                    <option >Inactive</option>

                                </select>
                            </Col>

                        </Row>
                        <Row className="mt-2">
                            <Col lg={3}xs={4}><label className="text-danger fw-bold">Category</label></Col>
                            <Col lg={9}xs={8}>
                                <select className="float-start w-100 customfontsize" id="cmbCoReportCategory"  >
                                    <option >All Category</option>
                                    <option >Manufacturing</option>
                                    <option >Services</option>
                                    <option >Other</option>
                                    <option >Imports</option>
                                    <option >Wholesale</option>
                                </select>
                            </Col>

                        </Row>
                        <Row className="mt-2">
                            <Col lg={3}xs={4}><label className="text-danger fw-bold">Items</label></Col>
                            <Col lg={9}xs={8}>
                                <select className="float-start w-100 customfontsize" id="cmbCoReportItems"  >
                                    <option value="0">All Items</option>
                                    {
                                        state.category.map((ctr,index)=>{
                                            return (
                                                <option key={index} value={ctr.Id}>{ctr.Name}</option>
                                            )
                                        })

                                    }
                                </select>
                            </Col>

                        </Row>
                        <Row className="mt-2">
                            <Col lg={3}xs={4}><label className="text-danger fw-bold">BIN</label></Col>
                            <Col lg={9}xs={8}>
                                <input type="text" className="w-100" id="txtCoReportSearchBin"/>
                            </Col>

                        </Row>
                        <Row className="mt-2">
                            <Col lg={3}xs={4}><label className="text-danger fw-bold">Name</label></Col>
                            <Col lg={9}xs={8}>
                                <input type="text" className="w-100" id="txtCoReportSearchName"/>
                            </Col>

                        </Row>
                        <Row className="mt-2">
                            <Col lg={3}xs={4}><label className="text-danger fw-bold">Hscode</label></Col>
                            <Col lg={9}xs={8}>
                                <input type="text" className="w-100" id="txtCoReportSearchHscode"/>
                            </Col>

                        </Row>

                        <Row className="mt-2">
                            <Col lg={3}xs={3}>Addition</Col>
                            <Col lg={6}xs={6}>
                                <select  className="form-control form-control-sm form-select" id="cmbCoAdditionCompare">
                                    <option>All Addition</option>
                                    <option>More than</option>
                                    <option>Less than</option>

                                </select>
                            </Col>
                            <Col lg={3} xs={3}>
                                <input type="text" className="w-100 h-100" id="txtCoAdditionValue"/>
                            </Col>

                        </Row>
                        <Row className="mt-2">
                            <Col lg={3} xs={3}>Days</Col>
                            <Col lg={6} xs={6}>
                                <select  className="form-control w-100 form-control-sm form-select" id="cmbCoDaysCompare">
                                    <option>All Days</option>
                                    <option>More than</option>
                                    <option>Less than</option>

                                </select>
                            </Col>
                            <Col lg={3}xs={3}>
                                <input type="text" className="w-100 h-100" id="txtCoDaysValue"/>
                            </Col>

                        </Row>

                        <Row className="mt-2">
                            <Button variant="success" onClick={get4_3}>Load Data</Button>
                        </Row>

                    </Col>
                    <Col lg={9}>

                        <div className="overflow-scroll animated zoomIn purchaseMediumDiv">
                            {
                                DataStatus? <LoadingText/>
                                    : <BootstrapTable selectRow={selectRow}  keyField='ID' data={DataCoEffeciant} columns={ColumnCoEfficient}   > </BootstrapTable>


                            }
                            </div>
                        <label className="text-danger">Data found: {DataLength}</label>

                    </Col>

                </Row>


            </Container>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Update 4.3 - ID{Id_4_3}</Modal.Title>


                </Modal.Header>
                <Modal.Body>
                    <div className="flex-column">

                        <select className="float-start me-2 w-50 customfontsize" id="cmbStatus4_3"  >
                            <option>Select</option>
                            <option >Active</option>
                            <option>Inactive</option>

                        </select>

                        <Button variant="primary" onClick={update4_3} >
                            Save
                        </Button>
                    </div>



                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleClose}>
                        Close
                    </Button>



                </Modal.Footer>
            </Modal>
        </Fragment>


    );
}
