import {Fragment, useState} from "react";
import {Button, Col, Modal, Row} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
import cogoToast from "cogo-toast";
import LoadingText from "../Loading/LoadingText";
import {useSelector} from "react-redux";


export default function FloorSpaceReport(){
    let state=useSelector((state)=> state);
    const [DivisionFS, setDivisionFS]=useState([]);
    const [CircleFS, setCircleFS]=useState([]);
    const [DataCoEffeciant, setDataCoEffeciant]=useState([]);
    const [ColumnCoEfficient, setColumnCoEfficient]=useState([{}]);
    const [DataLength, setDatalength]=useState(0)
    const[DataStatus, setDataStatus]=useState(false)
    const [FloorSpace_Id, setFloorSpace_Id] = useState();

    const [showFloorSpace, setshowFloorSpace] = useState(false);
    const handleCloseFloorSpace = () => setshowFloorSpace(false);
    const handleShowFloorSpace = () => setshowFloorSpace(true);

    const selectRow={
        mode:'radio',
        onSelect:(row,isSelect,rowIndex)=>{
            setFloorSpace_Id(row['Id']);
            handleShowFloorSpace();
        }
    }
    const getBIN=()=>{
        setDataStatus(true);

        const FSCommi=document.getElementById("cmbFSCommi");
        const FSCommiValue=FSCommi.options[FSCommi.selectedIndex].text;

        const Division=document.getElementById("cmbFsDivision");
        const DivisionValue=Division.options[Division.selectedIndex].text;
        const Circle=document.getElementById("cmbFsBinCircle");
        const CircleValue=Circle.options[Circle.selectedIndex].text;
        const BinCategory=document.getElementById("cmbFsBinCategory");
        const CategoryValue=BinCategory.options[BinCategory.selectedIndex].text;
        let getCommiFS=" ";
        let getDivision=" ";
        let getCircle=" ";
        let getCategory=" ";
        if(FSCommi.selectedIndex!=0){getCommiFS="And Commissionerate Like '%"+FSCommiValue+"%'"}
        if(Division.selectedIndex!=0){getDivision="And Division Like '%"+DivisionValue+"%'"}
        if(Circle.selectedIndex!=0){getCircle="And Circle Like '%"+CircleValue+"%'"}
        if(BinCategory.selectedIndex!=0){getCategory="And Economic_Activity Like '%"+CategoryValue+"%'"}

        let jsonObject={"sql":" Select * from Bin where 1=1 and Floor_Space='Yes' "+getCommiFS+" "+getDivision+" "+getCircle+" "+getCategory+" "}

        setColumnCoEfficient([{}]);setDataCoEffeciant([])
        RestClient.PostRequest(AppUrl.getData,jsonObject).then(async result=>{
           setDatalength(result.length);
            setDataStatus(false);
            if(result==""){cogoToast.error("Data not found")}else {
                await setColumnCoEfficient(
                    Object.keys(result[0]).map((k) => {
                        return {dataField: k, text: k, sort: true}
                    })
                )
                await setDataCoEffeciant(result);
                setDataStatus(false);

            }
        }).catch(error=>{});
    }

    return(
        <Fragment>
            <div >
                <Row className="mt-2">

                    <Col lg={3}>
                        <div className="">
                            <Row className="mt-2">
                                <Col lg={3} xs={4}><label className="text-danger fw-bold">NBR</label></Col>
                                <Col lg={9}  xs={8}>
                                    <select className="float-start w-100 customfontsize" id="cmbFSCommi"
                                            onChange={()=>{
                                                setDivisionFS([]);
                                                setCircleFS([]);
                                                const e = document.getElementById("cmbFSCommi");
                                                const value = e.options[e.selectedIndex].value;
                                                const dt=state.division.filter(x => x.Commissionerate_Id==value);
                                                setDivisionFS(dt)


                                            }}
                                    >




                                        <option >NBR</option>



                                        {


                                            state.commi.map((ctr,index)=>{
                                                return (
                                                    <option key={index} value={ctr.Id}>{ctr.Name}</option>
                                                )
                                            })

                                        }
                                    </select>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col lg={3}xs={4}><label className="text-danger fw-bold">Division</label></Col>
                                <Col lg={9}xs={8}>
                                    <select className="float-start w-100 customfontsize" id="cmbFsDivision"
                                            onChange={()=>{
                                                setCircleFS([])
                                                const e = document.getElementById("cmbFsDivision");
                                                const value = e.options[e.selectedIndex].value;
                                                const dt=state.circle.filter(x => x.Division_Id==value);
                                                setCircleFS(dt)


                                            }}
                                    >
                                        <option value="0">All Division</option>

                                        {


                                            DivisionFS.map((ctr,index)=>{
                                                return (
                                                    <option key={index} value={ctr.Id}>{ctr.Name}</option>
                                                )
                                            })

                                        }


                                    </select>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col lg={3}xs={4}><label className="text-danger fw-bold">Circle</label></Col>
                                <Col lg={9}xs={8}>
                                    <select className="float-start w-100 customfontsize" id="cmbFsBinCircle" >
                                        <option value="0">All Circle</option>

                                        {


                                            CircleFS.map((ctr,index)=>{
                                                return (
                                                    <option key={index} value={ctr.Id}>{ctr.Name}</option>
                                                )
                                            })

                                        }


                                    </select>
                                </Col>
                            </Row>

                            <Row className="mt-2">
                                <Col lg={3}xs={4}><label className="text-danger fw-bold">Category</label></Col>
                                <Col lg={9}xs={8}>
                                    <select className="float-start w-100 customfontsize" id="cmbFsBinCategory"  >
                                        <option >All BIN</option>
                                        <option >Manufacturing</option>
                                        <option >Services</option>
                                        <option >Other</option>
                                        <option >Imports</option>
                                        <option >Wholesale</option>
                                    </select>
                                </Col>

                            </Row>
                            <Row className="mt-2">
                                <Col lg={3}>

                                </Col>
                                <Col lg={9}>
                                    <button className="w-100" onClick={getBIN}>Search</button>
                                </Col>


                            </Row>


                        </div>
                    </Col>

                    <Col lg={9}>
                        <div className="overflow-scroll animated zoomIn purchaseSmallDiv">

                            {
                                DataStatus?<LoadingText/>
                                    :  <BootstrapTable   keyField='Id' selectRow={selectRow} data={DataCoEffeciant} columns={ColumnCoEfficient}></BootstrapTable>

                            }
                           </div>
                        <label className="text-danger customfontsize">Data found: {DataLength}</label>

                    </Col>

                </Row>

            </div>
            <Modal show={showFloorSpace} onHide={handleCloseFloorSpace}>
                <Modal.Header closeButton>
                    <Modal.Title >
                        <label className="text-danger">Revenue Enty Form </label>

                        <hr/>
                        <label>Name</label> <label className="text-danger">- ID: </label>
                    </Modal.Title>


                </Modal.Header>
                <Modal.Body>
                    <div className="flex-column">
                        <label>Year :</label>
                        <select className="float-right me-2 w-50 customfontsize" id="cmbVdsYear"  >
                            <option >Select FY</option>
                            <option >24-25</option>
                            <option >25-26</option>
                            <option >26-27</option>
                            <option >27-28</option>
                        </select>
                        <hr/>
                        <label>Month :</label>
                        <select className="float-right me-2 w-50 customfontsize" id="cmbVdsMonth"  >
                            <option >Select Month</option>
                            <option >July</option>
                            <option >August</option>
                            <option >September</option>
                            <option >October</option>
                            <option >November</option>
                            <option >December</option>
                            <option >January</option>
                            <option >February</option>
                            <option >March</option>
                            <option >April</option>
                            <option >May</option>
                            <option >June</option>
                        </select>
                        <hr/>
                        <label>Amount:</label>
                        <input type="text" className="w-50" id="txtVdsRevenue"/>
                        <label>Crore</label>

                    </div>



                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary"   >
                        Save
                    </Button>
                    <Button variant="danger" onClick={handleCloseFloorSpace}>
                        Close
                    </Button>


                </Modal.Footer>
            </Modal>
        </Fragment>

    )
}
