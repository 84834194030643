import {Fragment, useEffect, useRef, useState} from "react";
import {Button, Card, Col, Container, Modal, Row} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
import cogoToast from "cogo-toast";
import {useSelector} from "react-redux";
import DatePicker from "react-datepicker";


import ReactHTMLTableToExcel from "react-html-table-to-excel";
import ReactToPrint from "react-to-print";
export default function DashBoard(){

    const ref=useRef();

    let state=useSelector((state)=> state);
    const [ScnDate, setScnDate] = useState(new Date());
    const [Data, setData]=useState([{}]);
    const [DataSupremeCourt, setDataSupremeCourt]=useState();
    const [DataHighCourt, setDataHighCourt]=useState();
    const [DataTribunal, setDataTribunal]=useState();
    const [DataCauseList, setDataCauseList]=useState();
    const [DataAppeal, setDataAppeal]=useState();
    const [TotalMamlaLength, setTotalMamlaLength]=useState();
    const [DataDetailes, setDataDetailes]=useState();
    const [Data731, setData731]=useState();
    const [Data732, setData732]=useState();
    const [DataAdr, setDataAdr]=useState();
    const [DataStay, setDataStay]=useState();
    const [DataJudgement, setDataJudgement]=useState();
    const [Column, setColumn]=useState([{}]);
    const [ColumnAllMamla, setColumnAllMamla]=useState([{dataField: 'k', text: 'All mamla list', sort: true}]);
    const [DataAllMamla, setDataAllMamla]=useState([{}]);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [HighCourtAmount, setHighCourtAmount]=useState();
    const [SupremeAmount, setSupremeAmount]=useState();
    const [TribinalAmount, setTribunal]=useState();
    const [AppealAmount, setAppealAmount]=useState();
    const [AdrAmount, setAdrAmount]=useState();
    const [StayAmount, setStayAmount]=useState();
    const [TotalCourtAmount, setTotalCourtAmount]=useState();
    const [Pending731_Amount, setPending731_Amount]=useState();
    const [Pending732_Amount, setPending732_Amount]=useState();
    const [JudgementAmount, setJudgementAmount]=useState();
    const [AnalogusAmount, setAnalogusAmount]=useState();


        useEffect(()=>{

            setColumn(Object.keys(state.mamla_date[0]).map((k) => {return  {dataField: k, text: k, sort: true} }));

            setData(state.mamla_date);
            setColumnAllMamla(Object.keys(state.all_mamla[0]).map((k) => {return {dataField: k, text: k, sort: true}}))
             setDataHighCourt(state.all_mamla.filter(x=>x.Court=="High Court").length)
              setDataSupremeCourt(state.all_mamla.filter(x=>x.Court=="Supreme Court").length)
              setDataTribunal(state.all_mamla.filter(x=>x.Court=="Tribunal").length)
             setDataAppeal(state.all_mamla.filter(x=>x.Court=="Appeal").length)
             setTotalMamlaLength(state.all_mamla.length)

             setData731(state.internal_mamla.filter(x=>x.Status=="0").length)
             setData732(state.internal_mamla.filter(x=>x.Status=="1").length)

             setDataAdr(state.all_mamla.filter(x=>x.Status=="ADR").length)
             setDataStay(state.all_mamla.filter(x=>x.Status=="Stay").length)
             setDataJudgement(state.all_mamla.filter(x=>x.Status=="Judgement").length)
             setDataCauseList(state.all_mamla.filter(x=>x.Status=="Cause List").length)
            getCalculation();
        },[])

const getCalculation=()=>{
    let HicourtAmount=0
    let SupremeAmount=0
    let TribunalAmount=0
    let ADRAmount=0
    let StayAmount=0
    let totalCourtAmount=0
    let appealAmount=0
    let pending731Amount=0
    let pending732Amount=0
    let judgementAmount=0
    let analogusAmount=0
    let CauseListAmount=0

    state.all_mamla.filter(x=>x.Court=="Appeal").map((ctr,index)=>{
        appealAmount=appealAmount+ ctr.Amount
    })
    state.all_mamla.filter(x=>x.Court=="Tribunal").map((ctr,index)=>{
        TribunalAmount=TribunalAmount+ ctr.Amount
    })
    state.all_mamla.filter(x=>x.Court=="High Court").map((ctr,index)=>{
        HicourtAmount=HicourtAmount+ ctr.Amount
    })
    state.all_mamla.filter(x=>x.Court=="Supreme Court").map((ctr,index)=>{
        SupremeAmount=SupremeAmount+ ctr.Amount
    })
    state.all_mamla.filter(x=>x.Status=="ADR").map((ctr,index)=>{
        ADRAmount=ADRAmount+ ctr.Amount
    })
    state.all_mamla.filter(x=>x.Status=="Cause List").map((ctr,index)=>{
        CauseListAmount=CauseListAmount+ ctr.Amount
    })
    state.all_mamla.filter(x=>x.Status=="Stay").map((ctr,index)=>{
        StayAmount=StayAmount+ ctr.Amount
    })
    state.all_mamla.map((ctr,index)=>{
        totalCourtAmount=totalCourtAmount+ ctr.Amount
    })

    state.internal_mamla.filter(x=>x.Status=="0").map((ctr,index)=>{
        pending731Amount=pending731Amount+parseInt(ctr.Amount)
    })
    state.internal_mamla.filter(x=>x.Status=="1").map((ctr,index)=>{
        pending732Amount=pending732Amount+ parseInt(ctr.Amount)

    })
    state.all_mamla.filter(x=>x.Status=="Judgement").map((ctr,index)=>{
        judgementAmount=judgementAmount+ ctr.Amount

    })
    state.all_mamla.filter(x=>x.Status=="Analogus").map((ctr,index)=>{
        analogusAmount=analogusAmount+ctr.Amount
    })

    setTribunal(TribunalAmount.toFixed(2))
    setHighCourtAmount(HicourtAmount.toFixed(2))
    setSupremeAmount(SupremeAmount.toFixed(2))
    setAdrAmount(ADRAmount.toFixed(2))
    setStayAmount(StayAmount.toFixed(2))
    setTotalCourtAmount(totalCourtAmount.toFixed(2))
    setAppealAmount(appealAmount.toFixed(2))
    setPending731_Amount(pending731Amount)
    setPending732_Amount(pending732Amount)
    setAnalogusAmount(analogusAmount.toFixed(2))
    setJudgementAmount(judgementAmount.toFixed(2))



}

    const selectRow={
        mode:'radio',
        onSelect:(row,isSelect,rowIndex)=>{



                RestClient.PostRequest(AppUrl.getData, {"sql": "Select Detailes from Mamla_Detailes where Mamla_No='" + row['Mamla_No'] + "' "}).then(result => {
                    if (result == "") {
                        cogoToast.error("No Data found")
                    } else {

                        setDataDetailes(result[0]["Detailes"])
                        handleShow()
                    }

                }).catch(error => {
                    cogoToast.error(error)
                });


        }
    }

    const getAllInternalMamlaArray=async(event)=>{
        setColumnAllMamla(Object.keys(state.internal_mamla[0]).map((k) => {return {dataField: k, text: k, sort: true}}))
        let dataType=event.target.value;

         setDataAllMamla([]);

        setDataAllMamla(state.internal_mamla.filter(x=>x.Status==dataType));
        cogoToast.success("Data found "+state.internal_mamla.filter(x=>x.Status==dataType).length);


    }
    const getAllMamlaArray=async(event)=>{
        setColumnAllMamla(Object.keys(state.all_mamla[0]).map((k) => {return {dataField: k, text: k, sort: true}}))
        let dataType=event.target.value;

        setDataAllMamla([]);

        setDataAllMamla(state.all_mamla.filter(x=>x.Court==dataType));
        cogoToast.success("Data found "+state.all_mamla.filter(x=>x.Court==dataType).length);

    }
    const getTotalMamlaArray=async(event)=>{
        setColumnAllMamla(Object.keys(state.all_mamla[0]).map((k) => {return {dataField: k, text: k, sort: true}}))


        setDataAllMamla([]);

        setDataAllMamla(state.all_mamla);
        cogoToast.success("Data found "+state.all_mamla.length);

    }
    const getAllMamlaStatus=async(event)=>{
        setColumnAllMamla(Object.keys(state.all_mamla[0]).map((k) => {return {dataField: k, text: k, sort: true}}))
         setDataAllMamla([]);

     setDataAllMamla(state.all_mamla.filter(x=>x.Status==event.target.value));

       await cogoToast.success("Data found "+state.all_mamla.filter(x=>x.Status==event.target.value).length);

    }



    return(
        <Fragment>
            <Container className="animated zoomIn text-center">
                <Row>

                        <Row>
                            <div className="purchaseSmallDiv" >
                                <Card.Header className="text-danger">Upcoming Hearing</Card.Header>

                                <BootstrapTable selectRow={selectRow}  keyField='Mamla_No' data={Data} columns={Column}  > </BootstrapTable>

                            </div>
                        </Row>


                        <Row>
                            <Col lg={2}>
                                <Card className="m-1 w-100" style={{ width: '18rem' }}>
                                    <Card.Header>Pending 73(1)</Card.Header>
                                    <Card.Body>
                                        <Button className="w-100" value="0" onClick={(event)=> getAllInternalMamlaArray(event)}>{Data731}</Button>
                                        <Button className="w-100 bg-success mt-1" value="0">{Pending731_Amount}</Button>
                                         </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={2}>
                                <Card className="m-1 w-100" style={{ width: '18rem' }}>
                                    <Card.Header>Pending 73(2)</Card.Header>
                                    <Card.Body>
                                        <Button className="w-100" value="1" onClick={(event)=> getAllInternalMamlaArray(event)}>{Data732}</Button>

                                        <Button className="w-100 bg-success mt-1" value="0">{Pending732_Amount}</Button>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={2}>
                                <Card className="m-1 w-100" style={{ width: '18rem' }}>
                                    <Card.Header>Appeal</Card.Header>
                                    <Card.Body>
                                        <Button className="w-100" value="Appeal" onClick={(event)=> getAllMamlaArray(event)}>{DataAppeal}</Button>
                                        <Button className="w-100 bg-success mt-1" value="1">{AppealAmount}</Button>
                                          </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={2}>
                                <Card className="m-1 w-100" style={{ width: '18rem' }}>
                                    <Card.Header>Tribunal</Card.Header>
                                    <Card.Body>
                                        <Button className="w-100" value="Tribunal" onClick={(event)=> getAllMamlaArray(event)}>{DataTribunal}</Button>
                                        <Button className="w-100 bg-success mt-1" value="1">{TribinalAmount}</Button>
                                         </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={2}>
                                <Card className="m-1 w-100" style={{ width: '18rem' }}>
                                    <Card.Header>High Court</Card.Header>
                                    <Card.Body>
                                        <Button className="w-100" value="High Court" onClick={(event)=> getAllMamlaArray(event)}>{DataHighCourt}</Button>
                                        <Button className="w-100 bg-success mt-1" value="1">{HighCourtAmount}</Button>
                                        </Card.Body>
                                </Card>
                            </Col>

                            <Col lg={2}>
                                <Card className="m-1 w-100" style={{ width: '18rem' }}>
                                    <Card.Header >Supreme</Card.Header>
                                    <Card.Body>
                                        <Button className="w-100" value="Supreme Court" onClick={(event)=> getAllMamlaArray(event)}>{DataSupremeCourt}</Button>
                                        <Button className="w-100 bg-success mt-1" value="1">{SupremeAmount}</Button>
                                    </Card.Body>
                                </Card>
                            </Col>


                        </Row>
                        <Row>
                            <Col lg={2}>
                                <Card className="m-1 w-100" style={{ width: '18rem' }}>
                                    <Card.Header >ADR</Card.Header>
                                    <Card.Body>
                                        <Button className="w-100" value="ADR" onClick={(event)=> getAllMamlaStatus(event)}>{DataAdr}</Button>
                                        <Button className="w-100 bg-success mt-1" value="1">{AdrAmount}</Button>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={2}>
                                <Card className="m-1 w-100" style={{ width: '18rem' }}>
                                    <Card.Header>Cause List</Card.Header>
                                    <Card.Body>
                                        <Button className="w-100" value="Cause List" onClick={(event)=> getAllMamlaStatus(event)}>{DataCauseList}</Button>
                                        <Button className="w-100 bg-success mt-1" value="1" onClick={getCalculation}>0</Button>

                                    </Card.Body>
                                </Card>
                            </Col>
                        <Col lg={2}>
                            <Card className="m-1 w-100" style={{ width: '18rem' }}>
                                <Card.Header>Stay Case</Card.Header>
                                <Card.Body>
                                    <Button className="w-100" value="Stay" onClick={(event)=> getAllMamlaStatus(event)}>{DataStay}</Button>
                                    <Button className="w-100 bg-success mt-1" value="1">{StayAmount}</Button>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col lg={2}>
                            <Card className="m-1 w-100" style={{ width: '18rem' }}>
                                <Card.Header>Judgement</Card.Header>
                                <Card.Body>
                                    <Button className="w-100" value="Judgement" onClick={(event)=> getAllMamlaStatus(event)}>{DataJudgement}</Button>
                                    <Button className="w-100 bg-success mt-1" value="1">{JudgementAmount}</Button>
                                </Card.Body>
                            </Card>
                        </Col>
                            <Col lg={2}>
                                <Card className="m-1 w-100" style={{ width: '18rem' }}>
                                    <Card.Header>Analogus</Card.Header>
                                    <Card.Body>
                                        <Button className="w-100" value="Analogus" onClick={(event)=> getAllMamlaStatus(event)}>{DataJudgement}</Button>
                                        <Button className="w-100 bg-success mt-1" value="1">{AnalogusAmount}</Button>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={2}>
                                <Card className="m-1 w-100" style={{ width: '18rem' }}>
                                    <Card.Header>Pending PWC</Card.Header>
                                    <Card.Body>
                                        <Button className="w-100" value="PWC" onClick={(event)=> getAllMamlaStatus(event)}>{DataJudgement}</Button>
                                        <Button className="w-100 bg-success mt-1" value="1" onClick={getCalculation}>0</Button>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={2}>
                                <Card className="m-1 w-100" style={{ width: '18rem' }}>
                                    <Card.Header>Total Court Case</Card.Header>
                                    <Card.Body>
                                        <Button className="w-100" value="" onClick={(event)=> getTotalMamlaArray(event)}>{TotalMamlaLength}</Button>
                                        <Button className="w-100 bg-success mt-1" value="1">{TotalCourtAmount}</Button>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={2}>
                            <Card className="m-1 w-100" style={{ width: '18rem' }}>
                                <Card.Header>Flaging Case</Card.Header>
                                <Card.Body>
                                    <Button className="w-100" value="" onClick={(event)=> getTotalMamlaArray(event)}>{TotalMamlaLength}</Button>
                                    <Button className="w-100 bg-success mt-1" value="1" onClick={getCalculation}>0</Button>

                                </Card.Body>
                            </Card>
                        </Col>
                            <Col lg={2}>
                                <Card className="m-1 w-100" style={{ width: '18rem' }}>
                                    <Card.Header>Interest file</Card.Header>
                                    <Card.Body>
                                        <Button className="w-100" value="" onClick={(event)=> getTotalMamlaArray(event)}>{TotalMamlaLength}</Button>
                                        <Button className="w-100 bg-success mt-1" value="1" onClick={getCalculation}>0</Button>

                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col lg={2}>
                                <Card className="m-1 w-100" style={{ width: '18rem' }}>
                                    <Card.Header>Export/Print</Card.Header>
                                    <Card.Body>
                                        <ReactHTMLTableToExcel
                                            id="test-table-xls-button"
                                            className="download-table-xls-button"
                                            table="DashboardTable"
                                            filename="Order Report"
                                            sheet="tablexls"
                                            buttonText="Download Excel"/>
                                        <ReactToPrint   trigger={()=><Button className="w-100 mt-1 " variant="danger">Print</Button>} content={()=>ref.current}  />

                                    </Card.Body>
                                </Card>
                            </Col>

                    </Row>





                </Row>
                <Row>
                    <div className="purchaseSmallDiv ">

                        <BootstrapTable selectRow={selectRow} id="DashboardTable" ref={ref} keyField='Mamla_No' data={DataAllMamla} columns={ColumnAllMamla}  > </BootstrapTable>

                    </div>

                </Row>

            </Container>
            <Modal animation={false} className="animated zoomIn" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>মামলা উদ্ভবের কারণ</Modal.Title>



                </Modal.Header>
                <Modal.Body>

                    <label>{DataDetailes}</label>
                    <hr/>
                    <label>Flaging Date</label>
                    <DatePicker selected={ScnDate} onChange={(date) => setScnDate(date)} dateFormat='dd-MM-yyyy' seletable  isClearable showYearDropdown scrollableMonthYearDropdown  />
                    <label>Flaging Comments</label>
                    <textarea className="w-100" id="txtComments"/>
                </Modal.Body>


                <Modal.Footer>
                    <Button className="ml-4">Stay All</Button>
                    <Button variant="success" onClick={handleClose}>
                        Details History
                    </Button>
                    <Button variant="danger" onClick={handleClose}>
                        Action History
                    </Button>
                    <Button variant="secondary" >
                        Save Flag
                    </Button>

                </Modal.Footer>
            </Modal>
        </Fragment>
    );
}
