import {Fragment, useState} from "react";
import {Button, Col, Modal, Row} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import RestClient from "../../RestAPI/RestClient";
import AppUrl from "../../RestAPI/AppUrl";
import cogoToast from "cogo-toast";
import LoadingText from "../Loading/LoadingText";
import {useSelector} from "react-redux";
import appUtility from "../../RestAPI/AppUtility";
import AppUtility from "../../RestAPI/AppUtility";


export default function Efd(){
    let state=useSelector((state)=> state);
    const [DivisionEfd, setDivisionEfd]=useState([]);
    const [CircleEfd, setCircleEfd]=useState([]);
    const [DataCoEffeciant, setDataCoEffeciant]=useState([]);
    const [ColumnCoEfficient, setColumnCoEfficient]=useState([{}]);
    const [DataLength, setDatalength]=useState(0)
    const[DataStatus, setDataStatus]=useState(false)

    const[Bin, setBin]=useState()
    const[Name, setName]=useState()
    const[Mobile, setMobile]=useState()
    const [showSMSInfo, setShowSMSInfo] = useState(false);
    const handleCloseSMSInfo = () => setShowSMSInfo(false);
    const handleShowSMSInfo = () => setShowSMSInfo(true);
    const [showSMS, setShowSMS] = useState(false);
    const handleCloseSMS = () => setShowSMS(false);
    const handleShowSMS = () => setShowSMS(true);
    const selectRow={
        mode:'radio',
        onSelect:(row,isSelect,rowIndex)=>{
            setBin(row['BIN']);
            setName(row['Name']);
            setMobile(row['Mobile']);
                handleShowSMS();
        }
    }

    const getBIN=()=>{
        setDataStatus(true);
        setDatalength(0);
        const CommiEFD=document.getElementById("cmbEFDBinCommi");
        const CommiEFDValue=CommiEFD.options[CommiEFD.selectedIndex].text;
        const DivisionEFD=document.getElementById("cmbEFDDivision");
        const DivisionEFDValue=DivisionEFD.options[DivisionEFD.selectedIndex].text;
        const CircleEFD=document.getElementById("cmbEFDBinCircle");
        const CircleEFDValue=CircleEFD.options[CircleEFD.selectedIndex].text;
        const BinCategoryEFD=document.getElementById("cmbEFDBinCategory");
        const CategoryEFDValue=BinCategoryEFD.options[BinCategoryEFD.selectedIndex].text;

        const ItemsEfd=document.getElementById("cmbItemsEfd");
        const ItemsEfdValue=ItemsEfd.options[ItemsEfd.selectedIndex].text;
        const NameEfd=document.getElementById("txtSearchNameEfd").value;
        const BinEfd=document.getElementById("txtSearchBinEfd").value;
        const EFDDataLimit=document.getElementById("cmbEFDDataLimit");
        const EFDDataLimitValue=EFDDataLimit.options[EFDDataLimit.selectedIndex].text;

        let getCommiEFD=" ";
        let getDivisionEFD=" ";
        let getCircleEFD=" ";
        let getCategoryEFD=" ";
        let getItemsEfd=" ";
        let getNameEfd=" ";
        let getBinEfd=" ";
        let getLimit=" ";

        if(state.accessCom=="NBR")
        {if(CommiEFD.selectedIndex!=0){getCommiEFD="And Commissionerate Like '%"+CommiEFDValue+"%'"}}
        else{getCommiEFD="And Commissionerate Like '%"+state.accessCom+"%'"}

        if(state.accessDiv=="All Division")
        {if(DivisionEFD.selectedIndex!=0){getDivisionEFD="And Division Like '%"+DivisionEFDValue+"%'"}}
        else{getDivisionEFD="And Division Like '%"+state.accessDiv+"%'"}

        if(state.accessCir=="All Circle")
        {if(CircleEFD.selectedIndex!=0){getCircleEFD="And Circle Like '%"+CircleEFDValue+"%'"}}
        else {getCircleEFD="And Circle Like '%"+state.accessCir+"%'"}




        if(BinCategoryEFD.selectedIndex!=0){getCategoryEFD="And Economic_Activity Like '%"+CategoryEFDValue+"%'"}
        if(ItemsEfd.selectedIndex!=0){getItemsEfd="And Category= '"+ItemsEfdValue+"'"}
        if(NameEfd!=" "){getNameEfd="And Name Like '%"+NameEfd+"%'"}
        if(BinEfd!=" "){getBinEfd="And BIN Like '%"+BinEfd+"%'"}
        if(EFDDataLimit.selectedIndex!=0){getLimit= "LIMIT " +EFDDataLimitValue}


        let jsonObject={"sql":" Select BIN,Name,Mobile,Division,Circle,Address,Economic_Activity,Manufacturing_Area, Service_Area,Category,Divisional_Officer,DO_Mobile,Circle_Officer,CO_Mobile from Bin where 1=1 and EFD='Yes' "+getCommiEFD+" "+getDivisionEFD+" "+getCircleEFD+" "+getCategoryEFD+" "+getItemsEfd+" "+getNameEfd+" "+getBinEfd+" "+getLimit+" "}

        setColumnCoEfficient([{}]);setDataCoEffeciant([])
        RestClient.PostRequest(AppUrl.getData,jsonObject).then(async result=>{
            setDatalength(result.length);
            setDataStatus(false);
            if(result==""){cogoToast.error("Data not found")}else {
                await setColumnCoEfficient(
                    Object.keys(result[0]).map((k) => {
                        return {dataField: k, text: k, sort: true}
                    })
                )
                await setDataCoEffeciant(result);
                setDataStatus(false);

            }
        }).catch(error=>{});
    }


    const saveSMS=(sms)=>{


        RestClient.PostRequest(AppUrl.saveData, {"sql": "Insert into efd_sms (Id, BIN,SMS,User)Values(NULL, '"+Bin+"','"+sms+"','"+state.name+"') "}).then(result => {
            if (result == 1) {
                cogoToast.success("Revenue Data saved")

            } else {
                cogoToast.error("Data not saved")
            }
        }).catch(error => {
            cogoToast.error(error)
        });

    }

    const saveSMSInfo=(sms)=>{



        RestClient.PostRequest(AppUrl.saveData, {"sql": "Insert into infogenex_sms (Id, BIN,SMS,sms_date,Entry_User)Values(NULL, '"+Bin+"','"+sms+"','"+AppUtility.getDate(new Date())+"', '"+state.name+"') "}).then(result => {
            if (result == 1) {
                cogoToast.success("Revenue Data saved")

            } else {
                cogoToast.error("Data not saved")
            }
        }).catch(error => {
            cogoToast.error(error)
        });

    }
    return(
        <Fragment>
            <div >
                <Row className="mt-2">

                    <Col lg={3}>
                        <div className="">
                            <Row className="mt-2">
                                <Col lg={3} xs={4}><label className="text-danger fw-bold">NBR</label></Col>
                                <Col lg={9}  xs={8}>
                                    <select className="float-start w-100 customfontsize" id="cmbEFDBinCommi"
                                            onChange={()=>{
                                                setDivisionEfd([]);
                                                setCircleEfd([]);
                                                const e = document.getElementById("cmbEFDBinCommi");
                                                const value = e.options[e.selectedIndex].value;
                                                const dt=state.division.filter(x => x.Commissionerate_Id==value);
                                                setDivisionEfd(dt)


                                            }}
                                    >




                                        <option >NBR</option>



                                        {


                                            state.commi.map((ctr,index)=>{
                                                return (
                                                    <option key={index} value={ctr.Id}>{ctr.Name}</option>
                                                )
                                            })

                                        }
                                    </select>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col lg={3} xs={4}><label className="text-danger fw-bold">Division</label></Col>
                                <Col lg={9} xs={8}>
                                    <select className="float-start w-100 customfontsize" id="cmbEFDDivision"
                                            onChange={()=>{
                                                setCircleEfd([])
                                                const e = document.getElementById("cmbEFDDivision");
                                                const value = e.options[e.selectedIndex].value;
                                                const dt=state.circle.filter(x => x.Division_Id==value);
                                                setCircleEfd(dt)


                                            }}
                                    >
                                        <option>All Division</option>

                                        {


                                            DivisionEfd.map((ctr,index)=>{
                                                return (
                                                    <option key={index} value={ctr.Id}>{ctr.Name}</option>
                                                )
                                            })

                                        }


                                    </select>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col lg={3} xs={4}><label className="text-danger fw-bold">Circle</label></Col>
                                <Col lg={9} xs={8}>
                                    <select className="float-start w-100 customfontsize" id="cmbEFDBinCircle" >
                                        <option >All Circle</option>

                                        {


                                            CircleEfd.map((ctr,index)=>{
                                                return (
                                                    <option key={index} value={ctr.Id}>{ctr.Name}</option>
                                                )
                                            })

                                        }


                                    </select>
                                </Col>
                            </Row>

                            <Row className="mt-2">
                                <Col lg={3} xs={4}><label className="text-danger fw-bold">Category</label></Col>
                                <Col lg={9} xs={8}>
                                    <select className="float-start w-100 customfontsize" id="cmbEFDBinCategory"  >
                                        <option >All BIN</option>
                                        <option >Manufacturing</option>
                                        <option >Services</option>
                                        <option >Other</option>
                                        <option >Imports</option>
                                        <option >Wholesale</option>
                                    </select>
                                </Col>

                            </Row>
                            <Row className="mt-2">
                                <Col lg={3} xs={4}><label className="text-danger fw-bold">Items</label></Col>
                                <Col lg={9} xs={8}>
                                    <select className="float-start w-100 customfontsize" id="cmbItemsEfd"  >
                                        <option value="0">All Items</option>
                                        {
                                            state.category.map((ctr,index)=>{
                                                return (
                                                    <option key={index} value={ctr.Id}>{ctr.Name}</option>
                                                )
                                            })

                                        }

                                    </select>
                                </Col>

                            </Row>
                            <Row className="mt-2">
                                <Col lg={3} xs={4}><label className="text-danger fw-bold">BIN</label></Col>
                                <Col lg={9} xs={8}>
                                    <input type="text" className="w-100" id="txtSearchBinEfd"/>
                                </Col>

                            </Row>
                            <Row className="mt-2">
                                <Col lg={3} xs={4}><label className="text-danger fw-bold">Name</label></Col>
                                <Col lg={9} xs={8}>
                                    <input type="text" className="w-100" id="txtSearchNameEfd"/>
                                </Col>

                            </Row>
                            <Row className="mt-2">
                                <Col lg={3}xs={4}><label className="text-danger fw-bold">Data Limit</label></Col>
                                <Col lg={9}xs={8}>
                                    <select defaultValue={100} className="float-start w-100 customfontsize" id="cmbEFDDataLimit"  >
                                        <option >All</option>
                                        <option >50</option>
                                        <option >100</option>
                                        <option >200</option>
                                        <option >500</option>
                                        <option >1000</option>
                                        <option >2000</option>
                                        <option >3000</option>
                                        <option >5000</option>
                                        <option >10000</option>
                                        <option >20000</option>
                                        <option >50000</option>


                                    </select>
                                </Col>

                            </Row>
                            <Row className="mt-2">
                                <Col lg={3}>

                                </Col>
                                <Col lg={9}>
                                    <button className="w-100" onClick={getBIN}>Search</button>
                                </Col>


                            </Row>


                        </div>
                    </Col>

                    <Col lg={9}>
                        <div className="overflow-scroll animated zoomIn purchaseSmallDiv">

                            {
                                DataStatus?<LoadingText/>
                                    :  <BootstrapTable selectRow={selectRow}  keyField='BIN'  data={DataCoEffeciant} columns={ColumnCoEfficient}></BootstrapTable>

                            }
                        </div>
                        <label className="text-danger customfontsize">Data found: {DataLength}</label>

                    </Col>

                </Row>

            </div>
            <Modal show={showSMS} onHide={handleCloseSMS}>
                <Modal.Header closeButton>
                    <Modal.Title> <label className="text-primary">New SMS send</label> </Modal.Title>

                </Modal.Header>
                <Modal.Body>
                    <label>{Name}</label>
                    <hr/>
                    <label className="text-danger">Mobile No :</label>
                    <input type="text" defaultValue={Mobile} id="txtSmsNumber"/>

                    <select className="float-start w-100 customfontsize" id="cmbSms" onClick={()=>{
                        const cmbSMS=document.getElementById("cmbSms");
                        let txt=document.getElementById("txtSms");
                        txt.innerText=cmbSMS.options[cmbSMS.selectedIndex].text;
                    }} >
                        <option >সম্মানিত ব্যবসায়ী,{Name} আপনি আজ এখন পর্যন্ত EFD মেশিনে কোন ভ্যাট  চালান ইস্যু করেননি।দয়া করে  ভ্যাট  চালান ইস্যু করুন, অন্যথায় যেকোন মহুর্তে জরিমানা হতে পারে।</option>
                        <option >সম্মানিত ব্যবসায়ী,{Name} আপনি  গত এক সপ্তাহে EFD মেশিনে কোন ভ্যাট  চালান ইস্যু করেননি।দয়া করে  ভ্যাট  চালান ইস্যু করুন, অন্যথায় যেকোন মহুর্তে জরিমানা হতে পারে।</option>

                    </select>
                    <hr/>

                    <textarea id="txtSms" placeholder="Please write your sms here. Maximum 120 letters" className="w-100 h-50"></textarea>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-secondary" onClick={()=>{handleShowSMSInfo();}}>
                        SMS To InfoGenesys
                    </Button>
                    <Button variant="outline-primary">
                        SMS History
                    </Button>
                    <Button className="float-end" variant="success" onClick={()=>{
                        const sms=document.getElementById("txtSms").value;
                        const smsNumber=document.getElementById("txtSmsNumber").value;
                        if(smsNumber==""){cogoToast.error("Please insert send Number")}else{
                            if(sms==""){cogoToast.error("Please write sms in the box")}else{
                                appUtility.sendSMS(sms,smsNumber);saveSMS(sms);
                            }
                        }

                    }
                    } >
                        Send sms
                    </Button>

                    <Button variant="danger" onClick={handleCloseSMS}>
                        Close
                    </Button>



                </Modal.Footer>
            </Modal>





            <Modal show={showSMSInfo} onHide={handleCloseSMSInfo}>
                <Modal.Header closeButton>
                    <Modal.Title> <label className="text-primary">SMS send to info genex</label> </Modal.Title>

                </Modal.Header>
                <Modal.Body>
                    <label>{Name}</label>
                    <hr/>
                    <label className="text-danger">Mobile No :</label>


                    <select className="float-start w-100 customfontsize" id="cmbSmsInfo" onClick={()=>{
                        const SMSInfo=document.getElementById("cmbSmsInfo");
                        const txtInfo=document.getElementById("txtSmsInfo");
                        txtInfo.innerText=SMSInfo.options[SMSInfo.selectedIndex].text;
                    }} >
                        <option >Machine is Stopped Because of Fault</option>

                    </select>
                    <hr/>

                    <textarea id="txtSmsInfo" placeholder="Please write your sms here. Maximum 120 letters" className="w-100 h-50"></textarea>
                </Modal.Body>
                <Modal.Footer>

                    <Button className="float-end" variant="success" onClick={()=>{
                        const smsInfo=document.getElementById("txtSmsInfo").value;

                        saveSMSInfo(smsInfo)

                    }
                    } >
                        Save sms
                    </Button>

                    <Button variant="danger" onClick={handleCloseSMSInfo}>
                        Close
                    </Button>



                </Modal.Footer>
            </Modal>
        </Fragment>

    )
}
