import {Fragment, useState} from "react";
import {Container, Nav, Navbar, NavDropdown} from "react-bootstrap";
import {Link, NavLink, Route} from "react-router-dom";
import {useSelector} from "react-redux";
import { Offline, Online } from "react-detect-offline";
export default function TopNavigation(){

    let state=useSelector((state)=> state);


    const isAdmin = () => state.access === 'admin';
    const isDivision = () => state.access === 'Division';
    const isCircle = () => state.access === 'Circle';
    const isGL = () => state.access === 'GL';
    const isET = () => state.access === 'ET';
    const isAP = () => state.access === 'AP';
    const isLaw = () => state.access === 'Law';
    const isEfd = () => state.access === 'EFD';



    return(

        <Fragment  fluid="true">
            <Navbar collapseOnSelect expand="lg" bg="dark" fixed="top"  variant="dark">
                <Container>
                    <Navbar.Brand href="#/home">NBR</Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto ">
                            <Nav.Link  > <NavLink className="text-decoration-none fs-4 text-white"  to="/home">Home</NavLink> </Nav.Link>

                            {isAdmin() &&(
                                <>


                                   <Nav.Link ><NavLink className="text-decoration-none fs-4 text-white"  to="/law">Law</NavLink></Nav.Link>
                                    <Nav.Link ><NavLink className="text-decoration-none fs-4 text-white"  to="/audit">Audit</NavLink></Nav.Link>
                                    <Nav.Link ><NavLink className="text-decoration-none fs-4 text-white" to="/gl">GL</NavLink></Nav.Link>
                                    <Nav.Link ><NavLink className="text-decoration-none fs-4 text-white" to="/hr">HR</NavLink></Nav.Link>
                                    <Nav.Link ><NavLink className="text-decoration-none fs-4 text-white" to="/setting">Setting</NavLink></Nav.Link>
                                    <Nav.Link ><NavLink className="text-decoration-none fs-4 text-white" to="/et">Nothi</NavLink></Nav.Link>
                                    <Nav.Link ><NavLink className="text-decoration-none fs-4 text-white" to="/up">Unit Profile</NavLink></Nav.Link>
                                    <Nav.Link ><NavLink className="text-decoration-none fs-4 text-white" to="/efd">EFD</NavLink></Nav.Link>



                                </> )}
                            {isDivision() &&(
                                <>
                                    <Nav.Link ><NavLink className="text-decoration-none fs-4 text-white" to="/up">Unit Profile</NavLink></Nav.Link>

                                </> )}
                            {isCircle() &&(
                                <>
                                    <Nav.Link ><NavLink className="text-decoration-none fs-4 text-white" to="/up">Unit Profile</NavLink></Nav.Link>

                                </> )}
                            {isGL() &&(
                                <>
                                    <Nav.Link ><NavLink to="/gl">GL</NavLink></Nav.Link>
                                </> )}
                            {isET() &&(
                                <>
                                    <Nav.Link ><NavLink className="text-decoration-none fs-4 text-white" to="/et">ET</NavLink></Nav.Link>
                                </> )}
                            {isLaw() &&(
                                <>
                                    <Nav.Link ><NavLink to="/law">Law</NavLink></Nav.Link>
                                </> )}
                            {isAP() &&(
                                <>
                                    <Nav.Link ><NavLink to="/hr">HR</NavLink></Nav.Link>
                                    <Nav.Link ><NavLink to="/setting">Setting</NavLink></Nav.Link>
                                </> )}

                            {isEfd() &&(
                                <>
                                    <Nav.Link ><NavLink className="text-decoration-none fs-4 text-white" to="/efd">EFD</NavLink></Nav.Link>
                                </> )}

                            <Nav.Link ><NavLink className="text-decoration-none fs-4 text-success" to="/profile">Profile</NavLink></Nav.Link>
                            <Nav.Link ><NavLink className="text-decoration-none fs-4 text-danger" to="/">Logout</NavLink></Nav.Link>


                        </Nav>

                    </Navbar.Collapse>

                </Container>


            </Navbar>

        </Fragment>
    );
}
